import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getLoggedInUser = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/user`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const getUsers = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/users`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const getUserByEmail = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/user`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createUser = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/user`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateUser = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/user/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteUser = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/user/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
