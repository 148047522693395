<template>
  <v-card>
    <v-card-title class="headline">
      Associate Regions
    </v-card-title>
    <v-data-table
      :search="searchRegion"
      :headers="headersInventory"
      :items="regions"
      :loading="isLoading || isLoadingFromInventory"
    >
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Collateral Regions</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="searchRegion"
            append-icon="mdi-magnify"
            label="Search by Store Type, Store Name, Store Number"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            tile
            color="primary"
            class="mx-4"
            :disabled="isLoading"
            @click="selectAll">
            Select All
          </v-btn>
          <v-btn
            color="light-blue darken-2"
            outlined
            :disabled="isLoading"
            @click="deselectAll">
            Deselect All
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-checkbox v-model="item.set"></v-checkbox>
      </template>
      <template v-slot:[`item.accountTypeId`]="{ item }">
        {{ printAccountType(item) }}
      </template>
      <template v-slot:[`item.nsdRegionId`]="{ item }">
        {{ printNSDRegion(item) }}
      </template>
      <template v-slot:[`item.seniorRegionalSalesManagerMarketId`]="{ item }">
        {{ printSeniorRegionalSalesManagerMarket(item) }}
      </template>
      <template v-slot:[`item.storeTypeId`]="{ item }">
        {{ printStoreType(item) }}
      </template>

    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-2"
        text
        :disabled="isLoading || isLoadingFromInventory"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      <v-btn
        color="light-blue darken-2"
        :disabled="isLoading || isLoadingFromInventory"
        text
        @click="$emit('update', $event, regions, regionsSelected.orderableByAllStores)">
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { getStoreMatrixRegions } from 'API/region';
import { getRetailers } from 'API/retailer';
import { getAccountTypes } from 'API/account-type';
import { getNSDRegions } from 'API/nsd-region';
import { getRegionalMarkets } from 'API/regional-market';
import { getStoreTypes } from 'API/store-type';

export default {
  name: 'AssociateRegionForm',
  props: {
    regionsSelected: {
      type: Object,
      default: null,
    },
    isLoadingFromInventory: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    headersInventory: [
      { text: 'RETAILER', value: 'retailerId' },
      { text: 'STORE TYPE', value: 'storeTypeId' },
      { text: 'ACCOUNT TYPE', value: 'accountTypeId' },
      { text: 'STORE #', value: 'storeNo' },
      { text: 'STORE NAME', value: 'storeName' },
      { text: 'NSD REGION', value: 'nsdRegionId' },
      { text: 'SENIOR REGIONAL SALES MANAGER MARKET', value: 'seniorRegionalSalesManagerMarketId' },
      { text: 'Region', value: 'retailLabRegion.name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    searchRegion: '',
    regions: [],
    store: null,
    toAdd: [],
    toRemove: [],
    error: {
      status: false,
      message: '',
    },
  }),
  methods: {
    printRetailer(region) {
      return this.retailers.find((retailer) => retailer.id === region?.retailerId)?.name || '';
    },
    printAccountType(region) {
      return this.accountTypes.find((accountType) => accountType.id === region.accountTypeId)?.name || '';
    },
    printNSDRegion(region) {
      return this.nsdRegions.find((nsdRegion) => nsdRegion.id === region.nsdRegionId)?.name || '';
    },
    printSeniorRegionalSalesManagerMarket(region) {
      return this.regionalMarkets.find((regionalMarket) => regionalMarket.id === region.seniorRegionalSalesManagerMarketId)?.name || '';
    },
    printStoreType(region) {
      return this.storeTypes.find((storeType) => storeType.id === region.storeTypeId)?.name || '';
    },
    selectAll() {
      this.regions = this.regions.map((region) => {
        Object.assign(region, { set: true });
        return region;
      });
      this.regionsSelected.orderableByAllStores = true;
    },
    deselectAll() {
      this.regions = this.regions.map((region) => {
        Object.assign(region, { set: false });
        return region;
      });
      this.regionsSelected.orderableByAllStores = false;
    },
  },
  async created() {
    try {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const [
        regionsResponse,
        retailersResponse,
        accountTypesResponse,
        nsdRegionsResponse,
        regionalMarketsResponse,
        storeTypesResponse,
      ] = await Promise.all([
        getStoreMatrixRegions(jwt),
        getRetailers(jwt),
        getAccountTypes(jwt),
        getNSDRegions(jwt),
        getRegionalMarkets(jwt),
        getStoreTypes(jwt),
      ]);
      this.regions = regionsResponse.data;
      this.retailers = retailersResponse.data;
      this.accountTypes = accountTypesResponse.data;
      this.nsdRegions = nsdRegionsResponse.data;
      this.regionalMarkets = regionalMarketsResponse.data;
      this.storeTypes = storeTypesResponse.data;
      if (!this.regionsSelected.Regions.length && !this.regionsSelected.orderableByAllStores) {
        this.regions.forEach((region) => {
          Object.assign(region, { set: false });
        });
      } else {
        this.regions.forEach((region) => {
          const checkRegion = this.regionsSelected
            .Regions.some((regionSelected) => (regionSelected.id === region.id));
          if (checkRegion || this.regionsSelected.orderableByAllStores) {
            Object.assign(region, { set: true });
          }
        });
      }
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
