<template>
  <div id="modalVerify">
    <v-card>
      <v-form
        v-for="(orderItem, id) in order"
        :key="id"
        ref="form"
        v-model="orderItem.valid"
        :disabled="true">
        <v-card-title>Store {{ id }}</v-card-title>
        <v-container>
          <v-row :justify="'space-between'">
            <v-col md="6" cols="8" class="d-md-flex">
              <v-card-subtitle>
                Shipping To: &nbsp;
              </v-card-subtitle>
              <v-select
                v-model="orderItem.shippingBy"
                :items="users"
                item-text="fullName"
                item-value="id"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" cols="8" class="d-md-flex">
              <v-card-subtitle>
                Shipping On: &nbsp;
              </v-card-subtitle>
              <v-text-field
                v-model="orderItem.shippingOn"
                prepend-icon="mdi-calendar"
                :disabled="true"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="orderItem.Inventories"
          sort-by="id"
          hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat >
              <v-toolbar-title>Items</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.OrdersInventory.quantity`]="{ item }">
            <v-text-field
              class="input-quantity"
              type="number"
              v-model="item.OrdersInventory.quantity"
              required
            ></v-text-field>
          </template>
        </v-data-table>
        <v-row>
          <v-col>
            <v-card-subtitle>
              Comments: &nbsp;
            </v-card-subtitle>
            <v-textarea
              solo
              v-model="orderItem.comments"
              class="mx-4"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('confirmSubmit')"
          tile
          color="success"
          class="mx-4">
          <v-icon left>
            mdi-check-circle
          </v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'VerifyBatchOrder',
  props: {
    batchOrder: Object,
    users: Array,
  },
  data: () => ({
    order: {},
    headers: [
      { text: 'Name', value: 'itemCodeDesc' },
      { text: 'SKU', value: 'itemCode' },
      { text: 'Quantity', value: 'OrdersInventory.quantity' },
    ],
  }),
  watch: {
    batchOrder(newVal) {
      this.order = newVal;
    },
  },
  mounted() {
    this.order = this.batchOrder;
  },
};
</script>
