var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":"fullName","hide-default-footer":"","loading":_vm.isLoading},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Collateral Users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Name, Email, Created At and Type","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mx-4",attrs:{"icon":"","elevation":"1","aria-label":"Create User"},on:{"click":function($event){_vm.isCreating = true}}},[_c('v-icon',[_vm._v("mdi-account-plus")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","aria-label":"Export"},on:{"click":_vm.clickExport}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-export")])],1)]}}])},[_c('span',[_vm._v("Export Users")])])],1)]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"light-blue darken-2"},on:{"click":function($event){_vm.selectedUserId = item.id}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"red darken-2"},on:{"click":function($event){_vm.userToDelete = item}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getType(item.type)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toDateString()))])]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-text-field',{attrs:{"value":_vm.itemsPerPage,"label":"Items per page","type":"number","min":"1","max":"200"},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1}}})],1),_c('div'),_c('v-dialog',{attrs:{"max-width":"60%","persistent":_vm.isLoading},model:{value:(_vm.isDeleting),callback:function ($$v) {_vm.isDeleting=$$v},expression:"isDeleting"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this user?")]),_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Type in DELETE"},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"light-blue darken-2","text":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.userToDelete = null}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-2","disabled":_vm.isLoading || _vm.confirmDelete !== 'DELETE',"text":""},on:{"click":_vm.confirmDeleteItem}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"60%","persistent":_vm.isLoading},model:{value:(_vm.isUserDialogOpened),callback:function ($$v) {_vm.isUserDialogOpened=$$v},expression:"isUserDialogOpened"}},[(_vm.isUserDialogOpened)?_c('user-form',{key:_vm.selectedUserId || 0,attrs:{"user":_vm.selectedUser},on:{"close":function($event){_vm.isUserDialogOpened = false},"created":_vm.onUserCreated,"updated":_vm.onUserUpdated,"error":_vm.onUserCreateOrUpdateError}}):_vm._e()],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.type},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.status = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar.status),callback:function ($$v) {_vm.$set(_vm.snackbar, "status", $$v)},expression:"snackbar.status"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }