import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getMalls = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/malls`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const createMall = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/malls`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
