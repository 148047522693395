<template>
  <v-card min-height="300">
    <table-header
      :name="name"
      :filters="filters"
      :fiscalYears="fiscalYears"
      :availableMonths="availableMonths"
      :isLoading="isLoading"
    />
    <v-progress-circular
      v-if="isLoading"
      :size="60"
      :width="8"
      class="d-block mx-auto mt-16"
      indeterminate
      color="primary"
    />
    <data-table
      :isLoading="isLoading"
      :canEdit="canEditDataTable"
      :headers="headers"
      :salesPlans="salesPlans"
      :formatPrice="formatPrice"
      @selectedItem="setSelectedItem"
    />

    <v-dialog v-if="!isLoading" v-model="salesPlanDialogVisible" max-width="800">
      <sales-plan-form
        :key="selectedItem ? selectedItem.id : 0"
        :sales-plan="selectedItem"
        :region-id="regionId"
        @cancel="salesPlanDialogVisible = false"
        @saved="salesPlanDialogVisible = false; loadSalesPlans();"
      />
    </v-dialog>
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { getSalesPlans } from 'API/sales-plans';
import { getUserRetailLabRegions } from 'API/user-retailLabRegions';
import SalesPlanForm from '../SalesPlansForm/index.vue';
import TableHeader from './TableHeader.vue';
import DataTable from './DataTable.vue';
import { availableMonths, formatPrice, fiscalYears } from '~/consts';

export default {
  components: {
    SalesPlanForm,
    TableHeader,
    DataTable,
  },
  props: {
    regionId: Number,
    region: Object,
    name: String,
  },
  data() {
    return {
      salesPlans: [],
      userRetailLabRegions: [],
      isLoading: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'FY', align: 'start', value: 'fy' },
        { text: 'FS', align: 'start', value: 'fs' },
        { text: 'Cal Year', align: 'start', value: 'year' },
        { text: 'Month', align: 'start', value: 'monthText' },
        { text: 'TY Sales Goal', align: 'start', value: 'salesGoal' },
        { text: 'LY Sales Goal', align: 'start', value: 'lySalesGoal' },
        {
          text: 'Plan Adjustments (weekly)',
          align: 'start',
          value: 'goalAdjustments',
          sortable: false,
        },
        {
          text: 'TY Events (Goal/Actual)',
          align: 'start',
          value: 'eventDetails',
          sortable: false,
        },
        {
          text: 'LY Events (Goal/Actual)',
          align: 'start',
          value: 'lyEvents',
          sortable: false,
        },
        {
          text: 'Roadmap Adjustments(weekly)',
          align: 'start',
          value: 'roadmapAdjustments',
          sortable: false,
        },
        { text: 'Notes', value: 'notes', sortable: false },
      ],
      error: {
        status: false,
        message: '',
      },
      filters: {
        year: null,
        month: null,
      },
      fiscalYears,
      availableMonths,
    };
  },
  async created() {
    const { year = null, month = null } = this?.$route?.query;
    this.filters = { year, month };
  },
  async mounted() {
    const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();

    const {
      data: userRetailLabRegions,
    } = await getUserRetailLabRegions(jwt, this.$auth.user.id);

    this.userRetailLabRegions = userRetailLabRegions;
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.loadWithFilters();
      },
    },
  },
  computed: {
    selectedItemId: {
      get() {
        const { action, salesPlanId } = this.$route.query || {};
        if (action === 'edit' && salesPlanId) {
          return +salesPlanId;
        }
        return null;
      },
      set(salesPlanId) {
        if (salesPlanId) {
          this.$router.replace({ query: { ...this.$route.query, action: 'edit', salesPlanId } });
        }
      },
    },
    selectedItem() {
      return this.salesPlans.find((item) => item.id === this.selectedItemId);
    },
    salesPlanDialogVisible: {
      get() {
        return !!this.selectedItem;
      },
      set(newValue) {
        if (!newValue) {
          this.$router.replace({
            query: { ...this.$route.query, action: undefined, salesPlanId: undefined },
          });
        }
      },
    },
    canEditDataTable() {
      return this.userRetailLabRegions
        .map(({ id }) => id)
        .includes(this.region.retailLabRegionId);
    },
  },
  methods: {
    formatPrice,
    setSelectedItem(salesPlanId) {
      this.selectedItemId = salesPlanId;
    },
    loadWithFilters() {
      const origQuery = this?.$route?.query;
      const newQueryParams = Object.keys(this.filters)
        .filter((key) => (!origQuery[key] || origQuery[key] !== this.filters[key])
          && !!this.filters[key])
        .map((key) => ({ [key]: this.filters[key] }))
        .reduce((a, b) => ({ ...a, ...b }), {});
      if (Object.keys(newQueryParams).length) {
        const query = { ...this.$route.query, ...newQueryParams };
        this.$router.replace({ query });
      }
      this.loadSalesPlans();
    },
    async loadSalesPlans() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const query = {
        regionId: this.regionId,
        ...this.filters,
      };
      const { data } = await getSalesPlans(jwt, query);
      this.salesPlans = data.filter(
        (d) => (this.filters.year ? d.year === parseInt(this.filters.year, 10) : true),
      ).map(({ month = 0, ...obj }) => {
        const { value = '' } = availableMonths.find(({ key = 0 }) => key === month) || {};
        const lyPlan = data.find(
          ({ year, month: planMonth }) => year === obj.year - 1 && month === planMonth,
        );
        const fsVal = month === 1
          ? `FA${(obj?.year - 1)?.toString().slice(-2)}`
          : `SP${(obj?.year - 1)?.toString()?.slice(-2)}`;
        return {
          ...obj,
          fs: month >= 8
            ? `FA${obj?.year?.toString().slice(-2)}`
            : fsVal,
          fy: month >= 8
            ? obj?.year + 1
            : obj?.year,
          month,
          monthText: value,
          lySalesGoal: lyPlan?.salesGoal,
          lyEvents: lyPlan?.eventDetails || [],
        };
      });
      this.isLoading = false;
    },
  },
};
</script>
