<template>
  <div>
    <v-row>
      <h4>Overdue Manager Orders Export</h4>
      <hr/>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-date-picker
          v-model="dates"
          landscape
          range
        ></v-date-picker>
        <v-text-field
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn @click="exportOrders" :disabled="checkDisabled" outlined>Export</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import { getOrders } from 'API/exports';
import { getUsers } from 'API/user';
import { getStoreMatrixRegions } from 'API/region';
import { getBrands } from 'API/brands';
import { format } from 'date-fns';
// import { fetchUserInitialData } from '@/services/user-service';
import { generateFile } from '~/consts';
import LoadingSpinner from '~/components/LoadingSpinner';

export default {
  name: 'Exports',
  components: {
    LoadingSpinner,
  },
  data: () => ({
    dates: [],
    isLoading: false,
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    checkDisabled() {
      return this.dates.length !== 2 || this.isLoading;
    },
  },
  methods: {
    getPoDepartmentKey({ customerNumber, divisionNumber }) {
      return `customer:${customerNumber};division:${divisionNumber}`;
    },
    async exportOrders() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        const [
          { data: { orders = [] } },
          { data: users = [] },
          { data: regions = [] },
          { data: brands = [] },
        ] = await Promise.all([
          getOrders(jwt, {
            dateStart: this.dates[0],
            dateEnd: this.dates[1],
            justUsers: true,
          }),
          getUsers(jwt),
          getStoreMatrixRegions(jwt),
          getBrands(jwt),
        ]);

        const orderStoreIds = orders.map(({ regionId }) => regionId);
        const data = regions
          .filter(({ id }) => !orderStoreIds.includes(id))
          .map(({
            storeName = '',
            retailer: { name: retailerName = '' } = {},
            storeNo: storeNumber = '',
            retailLabRegion: { name: regionName = '', id: retailLabRegionId = '' } = {},
            brandIds = [],
          }) => ({
            retailerName,
            storeName,
            storeNumber,
            regionName,
            brands: brandIds
              ?.map((id) => brands.find((brand) => brand.id === id)?.name)
              .filter((name) => name)
              .join(' | ') || '',
            users: users
              .filter((user) => user.retailLabRegions
                .find((retailLabRegion) => retailLabRegion.id === retailLabRegionId))
              .map(({ fullName }) => fullName)
              .join(' | ') || '',
          }));
        console.log(data);

        const xlIdentifier = format(new Date(), 'yyyy-MM-dd').toUpperCase();
        const sheetName = `ICP_Missing_Orders_${format(new Date(), 'MMM').toUpperCase()}`;
        const fileName = `ICP_Missing_Orders_${xlIdentifier}.xlsx`;
        generateFile({ data, sheetName, fileName });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
      return true;
    },
  },
};
</script>
