import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getUserRegions = (token, userId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/regions`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const deleteUserRegion = (token, userId, regionId) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/regions/${regionId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const updateUserRegion = (token, userId, regionId, body) => axios.patch(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/regions/${regionId}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const saveUserRegions = (token, userId, regionIds) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/save_user_regions`, { regionIds }, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
