<template>
  <v-snackbar
    v-model="status"
    :color="type"
    >
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="display = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    status: Boolean,
    message: String,
    type: String,
  },
};
</script>
