<template>
  <v-card class="px-10 py-5">
    <v-card-title class="headline">
      {{ date ? 'Edit ' : 'Create ' }} Date
    </v-card-title>
    <v-progress-circular
      v-if="isLoading"
      :size="60"
      :width="8"
      class="d-block mx-auto mt-16"
      indeterminate
      color="primary"
    />
    <v-form
      v-if="!isLoading"
      ref="form"
      v-model="formDate.valid"
    >
    <v-row>
      <v-col class="col-12 col-lg-6 d-flex justify-center justify-lg-start">
        <v-date-picker
          v-model="formDate.date"
          label="Date"
          :rules="formDate.dateRules"
          required
        ></v-date-picker>
      </v-col>
      <v-col class="col-12 col-lg-6 d-flex justify-center justify-lg-end">
        <v-time-picker
          v-model="formDate.time"
          :rules="formDate.timeRules"
          ampm-in-title
          format="ampm"
        ></v-time-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 col-lg-6 d-flex justify-center justify-lg-start">
        <v-select
          v-model="formDate.month"
          :rules="formDate.monthRules"
          :items="availableMonths"
          item-value="key"
          item-text="value"
          required
          placeholder="Select Collateral Month"
        />
      </v-col>
      <v-col class="col-12 col-lg-6 d-flex justify-center justify-lg-end">
        <v-select
          v-model="formDate.year"
          :rules="formDate.yearRules"
          :items="availableYears"
          required
          placeholder="Select Collateral Year"
        />
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-2" text :disabled="isLoading" @click="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        color="light-blue darken-2"
        :disabled="isLoading"
        text
        @click="doCreateOrUpdateDate"
      >
        Save
      </v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</template>
<style>
  .v-time-picker-title{
    padding-top: 18px;
  }
</style>
<script>
import {
  createDate,
  updateDate,
} from 'API/emailNotificationSchedule';
import { format, getYear } from 'date-fns';
import { availableMonths } from '~/consts';

export default {
  props: {
    date: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    formDate: {
      valid: true,
      date: null,
      dateRules: [
        (v) => !!v || 'Date is required',
      ],
      time: null,
      timeRules: [
        (v) => !!v || 'Time is required',
      ],
      month: null,
      monthRules: [
        (v) => !!v || v === 0 || 'Month is required',
      ],
      year: null,
      yearRules: [
        (v) => !!v || 'Year is required',
      ],
    },
    error: {
      status: false,
      message: '',
    },
    availableYears: [
      ...Array(getYear(new Date()) - 2018 + 3).fill().map((_, i) => getYear(new Date()) - i + 3),
    ],
    availableMonths: availableMonths.map(({ key, value }) => ({
      key: key - 1,
      value,
    })),
  }),
  methods: {
    async doCreateOrUpdateDate() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const formatDate = format(new Date(`${this.formDate.date}T05:00:00.000Z`), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      const body = {
        date: formatDate,
        time: this.formDate.time,
        month: this.formDate.month,
        year: this.formDate.year,
      };

      try {
        const { data } = this.date
          ? await updateDate(jwt, this.date.id, body)
          : await createDate(jwt, body);
        this.$emit(this.date ? 'updated' : 'created', { ...data });
        this.$emit('close');
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      [this.formDate.date] = this.date.date.split('T');
      this.formDate.time = this.date.time;
      this.formDate.month = this.date.month.value;
      this.formDate.year = this.date.year;
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
