<template>
  <div>
    <v-data-table
      :search="search"
      :headers="[...headers, { text: 'Batch Order', value: 'BatchOrderId'}]"
      :items="orders"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      sort-by="deadlineDate"
      hide-default-footer
      class="elevation-1"
      :loading="isLoading"
      group-by="BatchOrderId">
      <template v-slot:[`group.header`]="item">
        <th
          :colspan="item.headers.length"
          :set="batchOrderSelected = batchOrders.find(order => +order.id === +item.group)">
          <v-icon @click="item.toggle">
            {{ item.isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          Batch Order created at: {{ new Date(batchOrderSelected.createdAt).toDateString() }}
        </th>
      </template>
      <template v-slot:[`item.Store`]="{ item }">
        {{ printCustomerName(item) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColor(item.status)"
          dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>{{ tab }} Orders</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Order Id, User/Email or Date"
              single-line
              hide-details
          ></v-text-field>
          <v-divider
            v-if="tab === 'All'"
            class="mx-4"
            inset
            vertical></v-divider>
          <router-link to="/batch-order/new">
            <v-btn
              text
              small
              color="light-blue darken-2"
              class="text-decoration-underline">
                New Batch Order
            </v-btn>
          </router-link>
        </v-toolbar>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="'/orders/' + item.id">
          <v-btn
            text
            small
            color="light-blue darken-2"
            class="text-decoration-underline"
            >
              {{ item.id }}
          </v-btn>
        </router-link>
      </template>
      <template v-slot:[`item.submittedOn`]="{ item }">
        <span>{{ new Date(item.submittedOn).toDateString() }}</span>
      </template>
      <template v-slot:[`item.modifiedOn`]="{ item }">
        <span>{{ item.modifiedOn ? new Date(item.modifiedOn).toDateString() : '-' }}</span>
      </template>
      <template v-slot:[`item.deadlineDate`]="{ item }">
        <span>{{ new Date(item.deadlineDate).toDateString() }}</span>
      </template>
    </v-data-table>
    <div class="mt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
          ></v-pagination>
      <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="1"
          max="200"
          @input="itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1"
          ></v-text-field>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BatchOrdersTable',
  props: {
    headers: Array,
    customers: Array,
    batchOrders: Array,
    isLoading: Boolean,
    tab: String,
  },
  data: () => ({
    search: '',
    itemsPerPage: 50,
    page: 1,
    pageCount: 0,
    orders: [],
  }),
  methods: {
    printCustomerName(order) {
      return order.Store?.customer?.name;
    },
    getColor(status) {
      return status === 'Pending' ? 'red' : 'green';
    },
    collapseAll() {
      this.$refs.expandableTable.collapseAll();
    },
    expandAll() {
      this.$refs.expandableTable.expandAll();
    },
  },
  watch: {
    batchOrders(newVal) {
      this.orders = newVal.reduce((arr, value) => [...arr, ...value.orders], []);
    },
  },
  async mounted() {
    this.orders = this.batchOrders.reduce((arr, value) => [...arr, ...value.orders], []);
  },
};
</script>
