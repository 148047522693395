<template>
  <v-card>
    <v-card-title>
      {{ salesPlan ? 'Edit sales plan' : 'Add sales plan' }}
    </v-card-title>
    <v-progress-circular
      v-if="isLoading"
      :size="60"
      :width="8"
      class="d-block mx-auto mt-16"
      indeterminate
      color="primary"
    />
    <v-form
      v-else
      v-model="newSalesPlanForm.valid"
    >
      <v-container>
        <v-row :justify="'space-between'">
          <v-col md="12" cols="12" class="d-md-flex">
            <v-card-subtitle>
              Year:
            </v-card-subtitle>
            <v-select
              v-model="newSalesPlanForm.year"
              :items="availableYears"
              required
              :disabled="salesPlan && !!salesPlan.id"
            />
          </v-col>
        </v-row>
        <v-row :justify="'space-between'">
          <v-col md="12" cols="12" class="d-md-flex">
            <v-card-subtitle>
              Month:
            </v-card-subtitle>
            <v-select
              v-model="newSalesPlanForm.month"
              :items="availableMonths"
              item-text="value"
              item-value="key"
              label="Select Month"
              required
              :disabled="salesPlan && !!salesPlan.id"
            />
          </v-col>
        </v-row>
        <v-row :justify="'space-between'">
          <v-col md="12" cols="12" class="d-md-flex">
            <v-card-subtitle>
              Month Sales Goal:
            </v-card-subtitle>
            <v-text-field
              prefix="$"
              type="number"
              v-model.number="newSalesPlanForm.newSalesGoal"
              required
              :disabled="salesPlan && !!salesPlan.id"
            />
          </v-col>
        </v-row>
        <v-expansion-panels v-model="panel" focusable multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>Plan Adjustments</v-expansion-panel-header>
            <v-expansion-panel-content>
              <plan-adjustment
                :goalAdjustments="newSalesPlanForm.goalAdjustments"
                v-on:updatePlans="updatePlans"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Event Details</v-expansion-panel-header>
            <v-expansion-panel-content>
              <event-details
                :eventDetails="newSalesPlanForm.eventDetails"
                v-on:updateEvents="updateEvents"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Roadmap Adjustments</v-expansion-panel-header>
            <v-expansion-panel-content>
              <roadmap-adjustments
                :roadmapAdjustments="newSalesPlanForm.roadmapAdjustments"
                v-on:updateRoadmap="updateRoadmap"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Notes</v-expansion-panel-header>
            <v-expansion-panel-content>
              <notes
                :notes="newSalesPlanForm.notes"
                v-on:updateNotes="updateNotes"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-2"
        text
        :disabled="isLoading"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      <v-btn
        color="light-blue darken-2"
        text
        :disabled="isLoading"
        @click="saveSalesPlan">
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { refreshData } from 'API/powerbi';
import { getSalesPlans, createSalesPlan, updateSalesPlan } from 'API/sales-plans';
import { availableMonths } from '~/consts';
import PlanAdjustment from './PlanAdjustment.vue';
import EventDetails from './EventDetails.vue';
import RoadmapAdjustments from './RoadmapAdjustments.vue';
import Notes from './Notes.vue';

export default {
  components: {
    PlanAdjustment,
    EventDetails,
    RoadmapAdjustments,
    Notes,
  },
  props: {
    regionId: Number,
    salesPlan: Object,
  },
  data() {
    return {
      availableRoles: ['sales_admin', 'field_sales'],
      isLoading: false,
      availableYears: Array.from({ length: 10 }, (v, i) => i + 2015).reverse(),
      availableMonths,
      panel: [],
      newSalesPlanForm: {
        valid: false,
        year: 2021,
        month: 7,
        eventDetails: [],
        goalAdjustments: [],
        roadmapAdjustments: [],
        notes: [],
      },
      picker: null,
      menu: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    async saveSalesPlan() {
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      this.isLoading = true;
      if (this.salesPlan) {
        await updateSalesPlan(
          jwt,
          this.salesPlan.id,
          { regionId: this.regionId, ...this.newSalesPlanForm },
        );
      } else {
        await createSalesPlan(jwt, { regionId: this.regionId, ...this.newSalesPlanForm });
      }

      await refreshData();

      this.isLoading = false;
      this.newSalesPlanForm = {
        valid: false,
        year: 2021,
        month: 7,
      };
      this.$emit('saved');
    },
    async getSalesPlans(jwt) {
      const { data = [] } = await getSalesPlans(jwt);
      return data;
    },
    updatePlans(entries) {
      this.newSalesPlanForm.goalAdjustments = entries;
    },
    updateEvents(entries) {
      this.newSalesPlanForm.eventDetails = entries;
    },
    updateRoadmap(entries) {
      this.newSalesPlanForm.roadmapAdjustments = entries;
    },
    updateNotes(entries) {
      this.newSalesPlanForm.notes = entries;
    },
  },
  created() {
    if (this.salesPlan) {
      this.newSalesPlanForm = { ...this.salesPlan };
      this.newSalesPlanForm.newSalesGoal = this.salesPlan.salesGoal;
      delete this.newSalesPlanForm.id;
      delete this.newSalesPlanForm.updatedAt;
    }
  },
};
</script>
