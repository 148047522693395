<template>
  <v-data-table
    :headers="headers"
    :items="entries"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn @click.native="addNewRow" color="primary" plain x-small>New Entry</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.week="props">
        <v-edit-dialog
          :return-value.sync="props.item.week"
          @close="save"
        >
          <span v-if="!!props.item.week">{{ props.item.week }}</span>
          <small class="grey--text" v-else>Week Number</small>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Week Number
            </div>
            <v-text-field
              v-model="props.item.week"
              label="Edit"
              single-line
              type="number"
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
    </template>
    <template v-slot:item.roadmap="props">
        <v-edit-dialog
          :return-value.sync="props.item.roadmap"
          @close="save"
        >
          <span v-if="!!props.item.roadmap">{{ formatPrice(props.item.roadmap) }}</span>
          <small class="grey--text" v-else>Roadmap adjustment Value</small>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Roadmap
            </div>
            <v-text-field
              v-model="props.item.roadmap"
              label="Edit"
              single-line
              type="number"
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
    </template>
  </v-data-table>
</template>

<script>
import { formatPrice } from '~/consts';

export default {
  props: {
    roadmapAdjustments: Array,
  },
  data() {
    return {
      date: new Date(),
      headers: [
        { text: 'Week#', value: 'week', sortable: false },
        { text: 'Roadmap Adjustments', value: 'roadmap', sortable: false },
      ],
      entries: [],
    };
  },
  mounted() {
    if (this.roadmapAdjustments && this.roadmapAdjustments.length) {
      this.entries = this.roadmapAdjustments;
    }
  },
  methods: {
    formatPrice,
    addNewRow() {
      this.entries = [].concat(
        this.entries,
        [{
          week: null,
          roadmap: null,
        }],
      );
    },
    save() {
      const entries = this.entries.filter(({
        week = null,
        roadmap = null,
      }) => !!week && !!roadmap);
      if (entries.length) {
        this.$emit('updateRoadmap', entries);
      }
    },
  },
};
</script>
