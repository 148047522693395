<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orders[tab.toLowerCase()]"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :custom-sort="customSort"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-1"
      :loading="isLoading"
      >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getColor(item.status)"
          dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>{{ tab }} Orders</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Order Id, User/Email or Date"
              single-line
              hide-details
          ></v-text-field>
          <v-divider
            v-if="tab === 'All'"
            class="mx-4"
            inset
            vertical></v-divider>
          <router-link to="/order/new">
            <v-btn
              text
              small
              color="light-blue darken-2"
              class="text-decoration-underline">
                New Order
            </v-btn>
          </router-link>
        </v-toolbar>
      </template>
      <template v-slot:[`item.Region.storeName`]="{ item }">
        <v-chip>{{ item.Region.storeNo }}</v-chip>
        {{ item.Region.retailer.name }} - {{ item.Region.storeName }}
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <router-link :to="'/orders/' + item.id">
          <v-btn
            text
            small
            color="light-blue darken-2"
            class="text-decoration-underline"
            >
              {{ item.id }}
          </v-btn>
        </router-link>
      </template>
      <template v-slot:[`item.submittedOn`]="{ item }">
        <span>{{ new Date(item.submittedOn).toDateString() }}</span>
      </template>
      <template v-slot:[`item.modifiedOn`]="{ item }">
        <span>{{ item.modifiedOn ? new Date(item.modifiedOn).toDateString() : '-' }}</span>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                outlined
                x-small
                color="red darken-2"
                class="mr-2"
                @click="deleteOrderClicked(item.id)"
                v-bind="attrs"
                v-on="on"
                aria-label="Delete Order">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Order</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="mt-2">
      <v-pagination
          :value="currentPage"
          @input="onSelectPage"
          :length="pageCount"
          ></v-pagination>
      <v-text-field
          :value="pageSize"
          label="Items per page"
          type="number"
          min="1"
          max="200"
          @input="onChangeItemsPerPage"
          ></v-text-field>
    </div>
      <confirm-dialog
        :persistent="isLoading"
        title="Are you sure you want to delete this order?"
        v-model="confirmDelete"
        @cancel="confirmDelete = false"
        @confirm="deleteOrder"
      />
     <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.type"
      centered>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import ConfirmDialog from 'SharedComponents/ConfirmDialog';
import {
  deleteOrder,
} from 'API/order';

export default {
  name: 'OrdersTable',
  components: { ConfirmDialog },
  props: {
    headers: Array,
    orders: Object,
    isLoading: Boolean,
    tab: String,
    pageSize: Number,
    pageCount: Number,
    currentPage: Number,
    onSelectPage: Function,
    onChangeItem: Function,
    searchText: String,
  },
  data: () => ({
    search: '',
    confirmDelete: false,
    selectedOrderId: null,
    sortBy: 'submittedOn',
    sortDesc: true,
    snackbar: {
      status: false,
      message: '',
      type: 'success',
    },
  }),
  watch: {
    search(newVal) {
      this.onChangeItem('searchText', newVal);
    },
    sortBy(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onChangeItem('sortBy', newVal);
      }
    },
    sortDesc(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onChangeItem('sortDesc', newVal);
      }
    },
  },
  methods: {
    getColor(status) {
      return status === 'Pending' ? 'red' : 'green';
    },
    async deleteOrder() {
      if (!this.selectedOrderId) {
        return;
      }
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        await deleteOrder(jwt, this.selectedOrderId);
        this.snackbar = {
          status: true,
          type: 'success',
          message: 'Order Delete',
        };
        this.$router.go();
      } catch (error) {
        this.snackbar = {
          status: true,
          type: 'error',
          message: error,
        };
      } finally {
        this.isLoading = false;
        this.confirmDelete = false;
      }
    },
    deleteOrderClicked(selectedOrderId) {
      this.selectedOrderId = selectedOrderId;
      this.confirmDelete = true;
    },
    onChangeItemsPerPage($event) {
      this.onChangeItem('pageSize', parseInt($event, 10) > 0 ? parseInt($event, 10) : 1);
    },
    customSort(items) {
      return items;
    },
  },
};
</script>
