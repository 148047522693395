import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getOrders = (token, params = {}) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/orders`, {
  params,
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const getPaginatedOrders = (token, params = {}) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/paginatedOrders`, {
  params,
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const getOrder = (token, id) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/order/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createOrder = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/order`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateOrder = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/order/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createBatchOrder = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/batchOrder`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createBatchOrderFile = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/batchOrderFile`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
    'content-type': 'multipart/form-data',
  },
});
export const deleteOrder = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/orders/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
