import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import { Auth0Plugin } from './components/Auth';
import App from './App.vue';
import router from './router';
import store from './store';

const {
  VUE_APP_AUTH0_DOMAIN,
  VUE_APP_AUTH0_CLIENT_ID,
  VUE_APP_AUTH0_AUDIENCE,
} = process.env || {};

Vue.use(vuetify);
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: VUE_APP_AUTH0_DOMAIN,
  clientId: VUE_APP_AUTH0_CLIENT_ID,
  audience: VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: ({ targetUrl } = {}) => {
    router.push(targetUrl || window.location.pathname);
  },
});

Vue.config.productionTip = false;
Vue.use(vueDebounce, {
  listenTo: 'input',
  defaultTime: '1000ms',
  trim: true,
});

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
