var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('v-breadcrumbs',{attrs:{"divider":">","items":_vm.itemsBreadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",class:item.disabled ? 'text text--disabled' : '',attrs:{"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-form',{ref:"form",model:{value:(_vm.orderFormValues.valid),callback:function ($$v) {_vm.$set(_vm.orderFormValues, "valid", $$v)},expression:"orderFormValues.valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-md-flex",attrs:{"md":"6","cols":"8"}},[_c('v-card-subtitle',[_vm._v(" Shipping On:   ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.orderFormRules.shippingOnRules,"prepend-icon":"mdi-calendar","required":"","readonly":""},model:{value:(_vm.orderFormValues.shippingOn),callback:function ($$v) {_vm.$set(_vm.orderFormValues, "shippingOn", $$v)},expression:"orderFormValues.shippingOn"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{on:{"change":function($event){_vm.datePicker = false}},model:{value:(_vm.orderFormValues.shippingOn),callback:function ($$v) {_vm.$set(_vm.orderFormValues, "shippingOn", $$v)},expression:"orderFormValues.shippingOn"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orderFormValues.Inventories,"sort-by":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Items")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","aria-label":"Add Items"},on:{"click":function($event){_vm.openModal = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Items ")],1)],1)]},proxy:true},{key:"item.OrdersInventory.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"input-quantity",attrs:{"type":"number","required":""},model:{value:(item.OrdersInventory.quantity),callback:function ($$v) {_vm.$set(item.OrdersInventory, "quantity", $$v)},expression:"item.OrdersInventory.quantity"}})]}}],null,true)}),_c('v-row',[_c('v-col',[_c('v-card-subtitle',[_vm._v(" Comments:   ")]),_c('v-textarea',{staticClass:"mx-4",attrs:{"solo":""},model:{value:(_vm.orderFormValues.comments),callback:function ($$v) {_vm.$set(_vm.orderFormValues, "comments", $$v)},expression:"orderFormValues.comments"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-4",attrs:{"tile":"","color":"error"},on:{"click":function($event){return _vm.$emit('cancel', 'cancel')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-close-circle ")]),_vm._v(" Cancel ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"disabled":_vm.isLoading || !_vm.orderFormValues.valid || !_vm.orderFormValues.Inventories.length,"tile":"","color":"success"},on:{"click":_vm.saveChanges}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-circle ")]),_vm._v(" Add Order ")],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":_vm.isLoading},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Add Items ")]),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headersInventory,"items":_vm.inventory,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Inventory")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Name or SKU","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.add",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"input-quantity",attrs:{"type":"number","rules":[function (v) { return v ? v > 0 : true; }]},model:{value:(_vm.formAddItem.quantity[("" + (item.id))]),callback:function ($$v) {_vm.$set(_vm.formAddItem.quantity, ("" + (item.id)), $$v)},expression:"formAddItem.quantity[`${item.id}`]"}})]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.openModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.isLoading,"text":""},on:{"click":_vm.addItem}},[_vm._v(" Confirm ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":"error","centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.error.status = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.error.status),callback:function ($$v) {_vm.$set(_vm.error, "status", $$v)},expression:"error.status"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }