var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers.concat( [{ text: 'Batch Order', value: 'BatchOrderId'}]),"items":_vm.orders,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":"deadlineDate","hide-default-footer":"","loading":_vm.isLoading,"group-by":"BatchOrderId"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"group.header",fn:function(item){return [_c('th',{attrs:{"colspan":item.headers.length,"set":_vm.batchOrderSelected = _vm.batchOrders.find(function (order) { return +order.id === +item.group; })}},[_c('v-icon',{on:{"click":item.toggle}},[_vm._v(" "+_vm._s(item.isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" Batch Order created at: "+_vm._s(new Date(_vm.batchOrderSelected.createdAt).toDateString())+" ")],1)]}},{key:"item.Store",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.printCustomerName(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.tab)+" Orders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Order Id, User/Email or Date","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.tab === 'All')?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('router-link',{attrs:{"to":"/batch-order/new"}},[_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":"","small":"","color":"light-blue darken-2"}},[_vm._v(" New Batch Order ")])],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/orders/' + item.id}},[_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":"","small":"","color":"light-blue darken-2"}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"item.submittedOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.submittedOn).toDateString()))])]}},{key:"item.modifiedOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.modifiedOn ? new Date(item.modifiedOn).toDateString() : '-'))])]}},{key:"item.deadlineDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.deadlineDate).toDateString()))])]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-text-field',{attrs:{"value":_vm.itemsPerPage,"label":"Items per page","type":"number","min":"1","max":"200"},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }