import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getSlscrs = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/slscrs`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getSlscr = (token, slscrId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/slscrs/${slscrId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createSlscr = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/slscrs`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateSlscr = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/slscrs/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteSlscr = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/slscrs/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
