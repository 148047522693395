import { get, put } from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getInventoryList = (token) => get(`${VUE_APP_API_URL}/api/v1/admin/inventory-list`, {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateItem = (token, id, body) => put(`${VUE_APP_API_URL}/api/v1/admin/inventory/${id}`, body, {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
