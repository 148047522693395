import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getStates = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/states`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getState = (token, stateId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/states/${stateId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createState = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/states`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateState = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/states/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteState = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/states/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
