import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getRetailers = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/retailers`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getRetailer = (token, retailerId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/retailers/${retailerId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createRetailer = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/retailers`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateRetailer = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/retailers/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteRetailer = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/retailers/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
