import axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getRegions = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/regions`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getStoreMatrixRegions = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/store-matrix-regions`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getMalls = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/locations`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getRegion = (token, regionId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/regions/${regionId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createRegion = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/regions`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateRegion = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/regions/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateRegions = (token, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/bulk_update/regions`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteRegion = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/regions/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const postInventoryRegion = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/inventory-region`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
