import { get } from 'axios';

const { VUE_APP_API_URL } = process.env || {};

export const searchAddress = (token, searchPhrase) => get(
  `${VUE_APP_API_URL}/api/v1/admin/addresses?searchPhrase=${searchPhrase}`,
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
);
