import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getUserRetailLabRegions = (token, userId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/retail-lab/user-regions`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const saveUserRetailLabRegions = (token, userId, retailLabRegionIds) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/user/retail-lab-regions`, { retailLabRegionIds, userId }, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
