<template>
  <div>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="users"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      sort-by="fullName"
      hide-default-footer
      class="elevation-1"
      :loading="isLoading"
      >
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Collateral Users</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Name, Email, Created At and Type"
              single-line
              hide-details
          />
          <v-btn
            icon elevation="1"
            @click="isCreating = true"
            aria-label="Create User"
            class="mx-4"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="clickExport"
                aria-label="Export"
                v-on="on"
                v-bind="attrs"
                class="mr-2">
                <v-icon>mdi-export</v-icon>
              </v-btn>
            </template>
            <span>Export Users</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn @click="selectedUserId = item.id"
          outlined
          small
          color="light-blue darken-2"
          >
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn @click="userToDelete = item"
          outlined
          small
          color="red darken-2"
          >
          <v-icon left>mdi-delete</v-icon> Delete
        </v-btn>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ getType(item.type) }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ new Date(item.createdAt).toDateString() }}</span>
      </template>
    </v-data-table>
    <div class="mt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
          ></v-pagination>
      <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="1"
          max="200"
          @input="itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1"
          />
    </div>
    <div>
    </div>
    <v-dialog v-model="isDeleting" max-width="60%" :persistent="isLoading">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this user?</v-card-title>
        <v-col>
          <v-text-field
            placeholder="Type in DELETE"
            v-model="confirmDelete"
          />
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-2"
            text
            :disabled="isLoading"
            @click="userToDelete = null"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-2"
            :disabled="isLoading || confirmDelete !== 'DELETE'"
            text
            @click="confirmDeleteItem">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isUserDialogOpened" max-width="60%" :persistent="isLoading">
      <user-form
        v-if="isUserDialogOpened"
        :key="selectedUserId || 0"
        :user="selectedUser"
        @close="isUserDialogOpened = false"
        @created="onUserCreated"
        @updated="onUserUpdated"
        @error="onUserCreateOrUpdateError"
      />
    </v-dialog>

    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.type"
      >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { getUsers, deleteUser } from 'API/user';
import { getUserTypes } from 'API/user-types';
import UserForm from '../../components/Users/UserForm.vue';
import { generateFile } from '~/consts';

export default {
  name: 'Users',
  components: { UserForm },
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', align: 'start', value: 'fullName' },
      { text: 'Email', value: 'email' },
      { text: 'Last Logged On', value: 'lastLoggedOn' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Title', value: 'UserType.role' },
      { text: 'Type', value: 'type' },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false },
    ],
    snackbar: {
      status: false,
      message: '',
      type: 'error',
    },
    users: [],
    itemsPerPage: 50,
    page: 1,
    pageCount: 0,
    isLoading: true,
    userToDelete: null,
    confirmDelete: '',
    userTypes: [],
  }),
  computed: {
    selectedUser() {
      return this.users.find((user) => user.id === this.selectedUserId);
    },
    isCreating: {
      get() {
        return this.$route.query.action === 'create';
      },
      set(value) {
        if (value) {
          this.$router.replace({ query: { action: 'create' } });
        }
      },
    },
    selectedUserId: {
      get() {
        const { userId } = this.$route.query;
        return userId ? +userId : null;
      },
      set(userId) {
        if (userId) {
          this.$router.replace({ query: { action: 'edit', userId } });
        }
      },
    },
    isUserDialogOpened: {
      get() {
        return !!this.selectedUserId || this.isCreating;
      },
      set(value) {
        if (!value) {
          this.$router.replace({ query: {} });
        }
      },
    },
    isDeleting: {
      get() {
        return !!this.userToDelete;
      },
      set(value) {
        if (!value) {
          this.userToDelete = null;
        }
      },
    },
  },
  methods: {
    async confirmDeleteItem() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        const res = await deleteUser(jwt, this.userToDelete.id);
        if (res.statusText === 'OK') {
          this.users = this.users.filter((user) => user.id !== this.userToDelete.id);
          this.userToDelete = null;
          this.snackbar = {
            status: true,
            type: 'success',
            message: 'User deleted',
          };
        }
      } catch (error) {
        this.snackbar = {
          status: true,
          type: 'error',
          message: error,
        };
      } finally {
        this.isLoading = false;
        this.userToDelete = null;
        this.confirmDelete = '';
      }
    },
    onUserUpdated(userData) {
      this.users.forEach((user) => {
        if (user.id === userData.id) Object.assign(user, userData);
      });
      this.snackbar = {
        status: true,
        type: 'success',
        message: 'User updated',
      };
    },
    onUserCreated(user) {
      this.users.push(user);
      this.snackbar = {
        status: true,
        type: 'success',
        message: 'User created',
      };
    },
    onUserCreateOrUpdateError(error) {
      this.snackbar = {
        status: true,
        type: 'error',
        message: error,
      };
    },
    clickExport() {
      const user = this.users.map((value) => ({
        id: value.id,
        uuid: value.uuid,
        Name: value.fullName,
        Email: value.email,
        'Last Logged On': value.lastLoggedOn,
        'Created At': new Date(value.createdAt).toDateString(),
        'Updated At': new Date(value.updatedAt).toDateString(),
        'User Type': value.UserType.role,
      }));
      generateFile({
        data: user,
        sheetName: `USERS-${(new Date()).toISOString()}`,
        fileName: `USERS-${(new Date()).toISOString()}.xlsx`,
      });
    },
    getType(id) {
      return this.userTypes.find((type) => type.id === id)?.type || '--';
    },
  },
  async created() {
    try {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const [
        { data = [] },
        { data: userTypes = [] } = {},
      ] = await Promise.all([
        getUsers(jwt),
        getUserTypes(jwt),
      ]);
      this.users = data;
      this.userTypes = userTypes;
    } catch (error) {
      this.snackbar = {
        status: true,
        type: 'error',
        message: error,
      };
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
