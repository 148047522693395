<template>
  <v-card-title>
    Sales plans - {{ name }}
    <v-spacer/>
    <v-select
      :items="fiscalYears"
      label="Select Year"
      item-text="value"
      item-value="value"
      dense
      clearable
      v-model="filters.year"
      single-line
    ></v-select>
    <v-spacer/>
    <v-select
      :items="availableMonths"
      label="Select Month"
      item-text="value"
      item-value="key"
      dense
      clearable
      v-model="filters.month"
      single-line
    ></v-select>
  </v-card-title>
</template>

<script>
export default {
  props: {
    name: String,
    filters: Object,
    fiscalYears: Array,
    availableMonths: Array,
    isLoading: Boolean,
  },
};
</script>
