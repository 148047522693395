<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title class="headline">
        {{ getTitle() }}
      </v-card-title>

      <v-form ref="form" v-model="regionForm.valid">
        <v-container>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Retail Lab Door:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.retailLabRegionId"
                :items="retailLabRegions"
                :rules="regionForm.retailLabRegionIdRules"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="retailLabRegionIdCreateModalOpened = true"
                    aria-label="Add Region"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Region
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Door:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.doorId"
                :items="doors"
                :rules="regionForm.doorIdRules"
                item-text="status"
                item-value="id"
                required
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                SRSM Market:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.seniorRegionalSalesManagerMarketId"
                :items="regionalMarkets"
                :rules="regionForm.seniorRegionalSalesManagerMarketIdRules"
                item-text="name"
                item-value="id"
                required
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                NSD Region:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.nsdRegionId"
                :items="nsdRegions"
                :rules="regionForm.nsdRegionIdRules"
                item-text="name"
                item-value="id"
                required
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Account Type:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.accountTypeId"
                :items="accountTypes"
                :rules="regionForm.accountTypeIdRules"
                item-text="name"
                item-value="id"
                required
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          tile
          @click="cancel">
          Cancel
        </v-btn>
        <v-btn
          @click="submit"
          :disabled="isLoading || !regionForm.valid"
          tile
          color="success"
          class="mx-4"
        >
          <v-icon left>
            mdi-check-circle
          </v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="retailLabRegionIdCreateModalOpened" max-width="500" :persistent="isLoading">
      <retail-lab-region-form
        v-if="retailLabRegionIdCreateModalOpened"
        @close="retailLabRegionIdCreateModalOpened = false"
        @created="onRetailLabRegionCreated"
      />
    </v-dialog>
  </div>
</template>
<script>
import { updateRegions } from 'API/region';
import { getRegions } from 'API/retailLabRegion';
import { getDoors } from 'API/doors';
import { getRegionalMarkets } from 'API/regional-market';
import { getNSDRegions } from 'API/nsd-region';
import { getAccountTypes } from 'API/account-type';
import RetailLabRegionForm from '~/views/Admin/components/Regions/RetailLabRegionForm.vue';

export default {
  name: 'RegionById',
  components: {
    RetailLabRegionForm,
  },
  props: {
    regions: Array,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      regionForm: {
        valid: true,
        retailLabRegionId: null,
        retailLabRegionIdRules: [
          (v) => !!v || 'Retail Lab Door is required',
        ],
        doorId: null,
        doorIdRules: [
          (v) => !!v || 'Door is required',
        ],
        seniorRegionalSalesManagerMarketId: null,
        seniorRegionalSalesManagerMarketIdRules: [
          (v) => !!v || 'SRSM Market is required',
        ],
        nsdRegionId: null,
        nsdRegionIdRules: [
          (v) => !!v || 'NSD Region is required',
        ],
        accountTypeId: null,
        accountTypeIdRules: [
          (v) => !!v || 'Account Type is required',
        ],
      },
      retailLabRegions: [],
      doors: [],
      regionalMarkets: [],
      nsdRegions: [],
      accountTypes: [],
      retailLabRegionIdCreateModalOpened: false,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    onRetailLabRegionCreated(retailLabRegion) {
      this.retailLabRegions.push(retailLabRegion);
      this.regionForm.retailLabRegionId = retailLabRegion.id;
    },
    getTitle() {
      return `Editing ${this.regions.length} Door(s)`;
    },
    async submit() {
      const updatedRegionsData = {
        retailLabRegionId: this.regionForm.retailLabRegionId,
        doorId: this.regionForm.doorId,
        seniorRegionalSalesManagerMarketId: this.regionForm.seniorRegionalSalesManagerMarketId,
        nsdRegionId: this.regionForm.nsdRegionId,
        accountTypeId: this.regionForm.accountTypeId,
      };
      const updatedRegions = this.regions.map((region) => ({
        ...region,
        ...updatedRegionsData,
        retailLabRegion: this.retailLabRegions.find(
          ({ id }) => id === this.regionForm.retailLabRegionId,
        ),
      }));
      const regionsIds = updatedRegions.map((region) => region.id);
      await this.submitData(regionsIds, updatedRegionsData);
      this.$emit('confirm', updatedRegions);
    },
    async submitData(regionsIds, updatedRegionsData) {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        const data = {
          regionsIds,
          ...updatedRegionsData,
        };
        await updateRegions(jwt, data);
        this.isLoading = false;
      } catch (e) {
        this.error = e;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
    try {
      const [
        retailLabRegions,
        doorsResponse,
        regionalMarketsResponse,
        nsdRegionsResponse,
        accountTypesResponse,
      ] = await Promise.all([
        getRegions(jwt),
        getDoors(jwt),
        getRegionalMarkets(jwt),
        getNSDRegions(jwt),
        getAccountTypes(jwt),
      ]);

      this.retailLabRegions = retailLabRegions.data;
      this.doors = doorsResponse.data;
      this.regionalMarkets = regionalMarketsResponse.data;
      this.nsdRegions = nsdRegionsResponse.data;
      this.accountTypes = accountTypesResponse.data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
