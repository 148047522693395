<template>
  <v-toolbar flat v-if="!isLoading">
    <v-radio-group v-model="radioGroup" row>
      <v-radio
        v-for="brand in brands"
        :key="brand.name"
        :label="brand.name"
        :value="brand.id"
      ></v-radio>
      <v-radio key="dillards" label="Dillards" :value="dillardId"></v-radio>
      <v-btn
        @click="radioGroup = null"
        outlined
        tile
        color="primary"
        :disabled="!inventoriesFiltered || isLoading"
      >
        Clear
      </v-btn>
    </v-radio-group>
    <v-row class="ms-3">
      <v-col cols="12">
        <v-checkbox
          hide-details
          v-model="hasInventory"
          label="Inventory Available"
        ></v-checkbox>
        <v-checkbox
          hide-details
          v-model="hasStores"
          label="Associated to stores"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Filters',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    brands: {
      type: Array,
      default: () => [],
    },
    dillardId: {
      type: Number,
      default: 66,
    },
    inventoriesFiltered: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: null,
    },
    hasInventoryFilter: {
      type: Boolean,
      default: false,
    },
    hasStoresFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioGroup: this.value,
      hasInventory: this.hasInventoryFilter,
      hasStores: this.hasStoresFilter,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.radioGroup = newVal;
    },
    radioGroup(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('input', newVal);
    },
    hasInventoryFilter(newVal, oldVal) {
      if (newVal !== oldVal) this.hasInventory = newVal;
    },
    hasInventory(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('change-has-inventory', newVal);
    },
    hasStoresFilter(newVal, oldVal) {
      if (newVal !== oldVal) this.hasStores = newVal;
    },
    hasStores(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('change-has-stores', newVal);
    },
  },
};
</script>
