import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getNSDRegions = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/nsdRegions`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getNSDRegion = (token, nsdRegionId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/nsdRegions/${nsdRegionId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createNSDRegion = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/nsdRegions`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateNSDRegion = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/nsdRegions/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteNSDRegion = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/nsdRegions/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
