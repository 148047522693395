import Vue from 'vue';
import VueRouter from 'vue-router';
import AdminLayouts from '../views/Admin';
import AuthGuard from '../components/Auth/authGuard';

Vue.use(VueRouter);

const {
  Inventory,
  AdminLayout,
  CollateralAssignment,
  Exports,
  Users,
  Orders,
  OrderById,
  OrderCreate,
  BatchOrderCreate,
  Stores,
  StoreById,
  StoreCreate,
  Regions,
  RegionById,
  RegionCreate,
  EmailNotification,
  OverdueManagerOrdersExport,
} = AdminLayouts || {};

const routes = [
  {
    path: '/',
    redirect: () => ({ name: 'AdminHome' }),
    beforeEnter: () => AuthGuard,
  },
  {
    path: '/admin',
    name: 'AdminHome',
    beforeEnter: AuthGuard,
    component: AdminLayout,
    children: [
      {
        path: '/inventory',
        component: Inventory,
      },
      {
        path: '/users',
        component: Users,
      },
      {
        path: '/orders',
        component: Orders,
      },
      {
        path: '/orders/:id',
        component: OrderById,
      },
      {
        path: '/order/new',
        component: OrderCreate,
      },

      {
        path: '/batch-order/new',
        component: BatchOrderCreate,
      },
      {
        path: '/exports',
        component: Exports,
      },
      {
        path: '/overdue-export',
        component: OverdueManagerOrdersExport,
      },
      {
        path: '/stores',
        component: Stores,
      },
      {
        path: '/stores/new',
        component: StoreCreate,
      },
      {
        path: '/stores/:id',
        component: StoreById,
      },
      {
        path: '/regions',
        component: Regions,
      },
      {
        path: '/regions/new',
        component: RegionCreate,
      },
      {
        path: '/regions/:id',
        component: RegionById,
      },
      {
        path: '/collateral-assignment',
        component: CollateralAssignment,
      },
      {
        path: '/email-notification',
        component: EmailNotification,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;
