<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        <v-breadcrumbs
            divider=">"
            :items="itemsBreadcrumbs"
        >
          <template v-slot:item="{ item }">
            <router-link
                :to="item.to"
                class="text-decoration-none"
                :class="item.disabled ? 'text text--disabled' : ''">
              {{ item.text }}
            </router-link>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-form ref="form" v-model="storeFormValues.valid">
        <v-container>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                AR Division No:
              </v-card-subtitle>
              <v-text-field
                  class="input-quantity"
                  v-model.number="storeFormValues.arDivisionNo"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Customer:
              </v-card-subtitle>
              <v-select
                class="input-quantity"
                v-model="storeFormValues.customerId"
                :items="customers"
                :item-text="item => `${item.no} - ${item.name}`"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    outlined
                    color="indigo"
                    small
                    @click="customerCreateModalOpened = true"
                    aria-label="Add Customer">
                    <v-icon left>mdi-plus</v-icon>Add Customer
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Ship To Code:
              </v-card-subtitle>
              <v-text-field
                  v-model="storeFormValues.shipToCode"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Ship To Name:
              </v-card-subtitle>
              <v-text-field
                  v-model="storeFormValues.shipToName"
                  required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Ship To City:
              </v-card-subtitle>
              <v-select
                class="input-quantity"
                v-model="storeFormValues.shipToCityId"
                :items="cities"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    outlined
                    color="indigo"
                    small
                    @click="cityCreateModalOpened = true"
                    aria-label="Add City">
                    <v-icon left>mdi-plus</v-icon>Add City
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Ship To State:
              </v-card-subtitle>
              <v-select
                class="input-quantity"
                v-model="storeFormValues.shipToStateId"
                :items="states"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    outlined
                    color="indigo"
                    small
                    @click="stateCreateModalOpened = true"
                    aria-label="Add State">
                    <v-icon left>mdi-plus</v-icon>Add State
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                UDF Ship Address Type:
              </v-card-subtitle>
              <v-select
                class="input-quantity"
                v-model="storeFormValues.udfShipAddressTypeId"
                :items="shipAddressTypes"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    outlined
                    color="indigo"
                    small
                    @click="shipAddressTypeCreateModalOpened = true"
                    aria-label="Add Ship Address Type">
                    <v-icon left>mdi-plus</v-icon>Add Ship Address Type
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                UDF SLSCR:
              </v-card-subtitle>
              <v-select
                class="input-quantity"
                v-model="storeFormValues.udfSlscrId"
                :items="slscrs"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    outlined
                    color="indigo"
                    small
                    @click="slscrCreateModalOpened = true"
                    aria-label="Add Slscr">
                    <v-icon left>mdi-plus</v-icon>Add Slscr
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Max. Cost Allocation/Month (USD):
              </v-card-subtitle>
              <v-text-field
                  class="input-quantity"
                  v-model.number="storeFormValues.maxCostAlloc"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="submit"
            :disabled="isLoading || !storeFormValues.valid"
            tile
            color="success"
            class="mx-4">
          <v-icon left>
            mdi-check-circle
          </v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="customerCreateModalOpened" max-width="500" :persistent="isLoading">
      <customer-form
        v-if="customerCreateModalOpened"
        @close="customerCreateModalOpened = false"
        @created="onCustomerCreated"
      />
    </v-dialog>
    <v-dialog v-model="cityCreateModalOpened" max-width="500" :persistent="isLoading">
      <city-form
        v-if="cityCreateModalOpened"
        @close="cityCreateModalOpened = false"
        @created="onCityCreated"
      />
    </v-dialog>
    <v-dialog v-model="stateCreateModalOpened" max-width="500" :persistent="isLoading">
      <state-form
        v-if="stateCreateModalOpened"
        @close="stateCreateModalOpened = false"
        @created="onStateCreated"
      />
    </v-dialog>
    <v-dialog v-model="shipAddressTypeCreateModalOpened" max-width="500" :persistent="isLoading">
      <ship-address-type-form
        v-if="shipAddressTypeCreateModalOpened"
        @close="shipAddressTypeCreateModalOpened = false"
        @created="onShipAddressTypeCreated"
      />
    </v-dialog>
    <v-dialog v-model="slscrCreateModalOpened" max-width="500" :persistent="isLoading">
      <slscr-form
        v-if="slscrCreateModalOpened"
        @close="slscrCreateModalOpened = false"
        @created="onSlscrCreated"
      />
    </v-dialog>
  </div>
</template>
<script>
import { createStore } from 'API/store';
import { getCustomers } from 'API/customer';
import { getCities } from 'API/city';
import { getStates } from 'API/state';
import { getShipAddressTypes } from 'API/ship-address-type';
import { getSlscrs } from 'API/slscr';
import CityForm from '~/views/Admin/components/Stores/CityForm.vue';
import CustomerForm from '../../components/Stores/CustomerForm.vue';
import ShipAddressTypeForm from '~/views/Admin/components/Stores/ShipAddressTypeForm.vue';
import SlscrForm from '~/views/Admin/components/Stores/SlscrForm.vue';
import StateForm from '~/views/Admin/components/Stores/StateForm.vue';

export default {
  name: 'StoreCreate',
  components: {
    CustomerForm,
    CityForm,
    StateForm,
    ShipAddressTypeForm,
    SlscrForm,
  },
  data: () => ({
    isLoading: false,
    error: null,
    customers: [],
    cities: [],
    states: [],
    shipAddressTypes: [],
    slscrs: [],
    storeFormValues: {
      valid: true,
      arDivisionNo: '',
      customerId: null,
      shipToCode: '',
      shipToName: '',
      shipToCityId: null,
      shipToStateId: null,
      udfShipAddressTypeId: null,
      udfSlscrId: null,
      maxCostAlloc: 0,
    },
    itemsBreadcrumbs: [
      {
        text: 'Stores',
        to: '/stores',
        disabled: false,
      },
      {
        text: 'Create New Store',
        to: '/stores/new',
        disabled: true,
      },
    ],
  }),
  computed: {
    customerCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createCustomer';
      },
      set(opened) {
        this.$router.replace({ query: { ...this.$route.query, action: opened ? 'createCustomer' : undefined } });
      },
    },
    cityCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createCity';
      },
      set(opened) {
        this.$router.replace({ query: { ...this.$route.query, action: opened ? 'createCity' : undefined } });
      },
    },
    stateCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createState';
      },
      set(opened) {
        this.$router.replace({ query: { ...this.$route.query, action: opened ? 'createState' : undefined } });
      },
    },
    shipAddressTypeCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createShipAddressType';
      },
      set(opened) {
        this.$router.replace({ query: { ...this.$route.query, action: opened ? 'createShipAddressType' : undefined } });
      },
    },
    slscrCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createSlscr';
      },
      set(opened) {
        this.$router.replace({ query: { ...this.$route.query, action: opened ? 'createSlscr' : undefined } });
      },
    },
  },
  methods: {
    onCustomerCreated(customer) {
      this.customers.push(customer);
      this.storeFormValues.customerId = customer.id;
    },
    onCityCreated(city) {
      this.cities.push(city);
      this.storeFormValues.shipToCityId = city.id;
    },
    onStateCreated(state) {
      this.states.push(state);
      this.storeFormValues.shipToStateId = state.id;
    },
    onShipAddressTypeCreated(shipAddressType) {
      this.shipAddressTypes.push(shipAddressType);
      this.storeFormValues.udfShipAddressTypeId = shipAddressType.id;
    },
    onSlscrCreated(slscr) {
      this.slscrs.push(slscr);
      this.storeFormValues.udfSlscrId = slscr.id;
    },
    async submit() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const body = { ...this.storeFormValues };
      try {
        await createStore(jwt, body);
        this.isLoading = false;
        this.$router.push({ path: '/stores' });
      } catch (e) {
        this.error = e;
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
    try {
      const [
        customersResponse,
        citiesResponse,
        statesResponse,
        shipAddressTypesResponse,
        slscrsResponse,
      ] = await Promise.all([
        getCustomers(jwt),
        getCities(jwt),
        getStates(jwt),
        getShipAddressTypes(jwt),
        getSlscrs(jwt),
      ]);
      this.customers = customersResponse.data;
      this.cities = citiesResponse.data;
      this.states = statesResponse.data;
      this.shipAddressTypes = shipAddressTypesResponse.data;
      this.slscrs = slscrsResponse.data;
    } catch (e) {
      this.error = e;
    }
    this.isLoading = false;
  },
};
</script>
