<template>
    <v-navigation-drawer
      absolute
      right
      v-model="show"
      temporary
      width="75%"
      height="93.5vh"
      class="elevation-6">
    <v-card>
      <v-row :justify="'end'">
        <v-col sm="2">
          <v-card-actions class="justify-end pe-5 pt-5 pb-0">
            <v-btn
              color="error"
              tex
              @click.stop="show=false">
              Close
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
      <v-row :justify="'space-between'">
        <v-col class="pt-0">
          <v-card-title class="pt-0">
            <span>{{ itemText }}</span>
          </v-card-title>
        </v-col>
      </v-row>
      <v-expansion-panels focusable max-height="50px">
        <v-expansion-panel
          v-for="(region, i) in allocInventory" :key="i" :ref="'panel' + region.id">
          <v-expansion-panel-header class="pb-0">
            <v-row no-gutters>
              <v-col cols="6">
                <strong>{{ region.storeName }}</strong> - No. {{ region.storeNo }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-data-table
                :search="search"
                :headers="headersInventory"
                :items="region.Inventory"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
                disable-pagination
                fixed-header
                >
              <template v-slot:top>
                <v-toolbar flat >
                  <v-toolbar-title>Inventory</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                      ></v-divider>
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search by Name or SKU"
                      single-line
                      hide-details
                      ></v-text-field>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'InventorySlideOut',
  props: {
    itemText: String,
    brandId: Number,
    value: Boolean,
    selectedInventory: Array,
    usedInventoryQuantityPerRegion: Array,
  },
  data: () => ({
    sortBy: 'qtyAvailable',
    sortDesc: true,
    search: '',
    headersInventory: [
      { text: 'Name', value: 'itemCodeDesc' },
      { text: 'SKU', value: 'itemCode' },
      { text: 'Qty Allocated', value: 'qtyAllocated' },
    ],
    allocInventory: [],
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) window.scroll(0, 0);
        else this.$emit('close');
      },
    },
  },
  watch: {
    selectedInventory: {
      deep: true,
      handler() {
        this.setAllocatedInventory();
      },
    },
  },
  methods: {
    setAllocatedInventory() {
      this.allocInventory = this.usedInventoryQuantityPerRegion
        .map((region) => {
          const { Inventory } = region;
          return {
            ...region,
            Inventory: [...Inventory].map((item) => {
              const selectedItem = this.selectedInventory
                .find((completeItem) => completeItem.id === item.id);
              return {
                ...selectedItem,
                qtyAllocated: item.qty,
              };
            }),
          };
        });
    },
    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.show = false;
      }
    },
  },
  mounted() {
    this.setAllocatedInventory();
    window.addEventListener('keydown', this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
};
</script>

<style scoped>
  .v-navigation-drawer {
    transition-duration: .5s;
  }
</style>
