<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        <v-breadcrumbs
          divider=">"
          :items="itemsBreadcrumbs">
          <template v-slot:item="{ item }">
            <router-link
              :to="item.to"
              class="text-decoration-none"
              :class="item.disabled ? 'text text--disabled' : ''">
              {{ item.text }}
            </router-link>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-container v-cloak
          @drop.prevent="addDropFile"
          @dragover.prevent="addDragFile"
          @dragleave.prevent="addDragLeaveFile"
          >
          <v-file-input
            :label="dragAndDropMessage"
            v-model="files"
            counter
            show-size
            accept=".csv,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              application/vnd.ms-excel"
            multiple>
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-container>
        <v-spacer></v-spacer>
        <v-btn
          @click="sendOrder"
          :disabled="!(!isLoading && (Object.keys(batchOrder).length || files.length))"
          tile
          color="success"
          class="mx-4">
          <v-icon left>
            mdi-check-circle
          </v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="stores"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Collateral Stores</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Name, City, Division, Customer No, Code and State"
              single-line
              hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.customerId`]="{ item, header }">
        {{ printCustomer(item, header) }}
      </template>
      <template v-slot:[`item.shipToCityId`]="{ item }">
        {{ printCity(item) }}
      </template>
      <template v-slot:[`item.shipToStateId`]="{ item }">
        {{ printState(item) }}
      </template>
      <template v-slot:[`item.udfShipAddressTypeId`]="{ item }">
        {{ printShipAddressType(item) }}
      </template>
      <template v-slot:[`item.udfSlscrId`]="{ item }">
        {{ printSlscr(item) }}
      </template>
      <template v-slot:[`item.order`]="{ item }">
        <div class="d-flex">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="selectedStoreId = item.id"
                icon
                small
                :color="batchOrder[`${item.id}`] ? 'blue' : ''"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-tag</v-icon>
              </v-btn>
            </template>
            <span>Create order for this store</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-if="stores.length" v-model="orderCreateModalVisible" :persistent="isLoading">
      <order-create-modal
        @cancel="selectedStoreId = null"
        @saveChanges="saveChanges"
        :prevOrderStore="prevOrderStore"
        :inventory="storeInventory"/>
    </v-dialog>

    <v-dialog
      v-model="confirmModal"
      :persistent="isLoading">
      <verify-batch-order
        :batchOrder="batchOrder"
        :users="users"
        @confirmSubmit="confirmBatchOrder"/>
    </v-dialog>
    <v-dialog
      v-model="confirmFiles"
      :persistent="isLoading">
      <v-card>
        <v-card-title>Confirm Files</v-card-title>
        <v-container>
          <v-card-subtitle>
            You are about to send:
          </v-card-subtitle>
          <v-list>
            <v-list-item-group model="1">
              <v-list-item
                v-for="(item, i) in files"
                :key="i">
                <v-list-item-icon>
                  <v-icon>mdi-cloud-upload</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-container>
        <v-card-actions>
          <v-btn
            @click="confirmFiles = false"
            :disabled="isLoading"
            tile
            color="error"
            class="mx-4">
            <v-icon left>
              mdi-check-circle
            </v-icon>
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmSendFiles"
            :disabled="isLoading"
            :loading="isLoading"
            tile
            color="success"
            class="mx-4">
            <v-icon left>
              mdi-check-circle
            </v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  .input-quantity {
    max-width: 50px;
  }
</style>
<script>
import {
  getStores,
} from 'API/store';
import {
  getUsers,
} from 'API/user';
import {
  createBatchOrderFile,
  createBatchOrder,
} from 'API/order';
import { getCustomers } from 'API/customer';
import { getCities } from 'API/city';
import { getStates } from 'API/state';
import { getShipAddressTypes } from 'API/ship-address-type';
import { getSlscrs } from 'API/slscr';
import OrderCreateModal from './OrderCreateModal.vue';
import VerifyBatchOrder from './VerifyBatchOrder.vue';

export default {
  name: 'BatchOrderCreate',
  components: {
    'order-create-modal': OrderCreateModal,
    'verify-batch-order': VerifyBatchOrder,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'AR Division No', value: 'arDivisionNo' },
      { text: 'Customer No', value: 'customerId' },
      { text: 'Customer Name ', value: 'customerId' },
      { text: 'Ship To Code ', value: 'shipToCode' },
      { text: 'Ship To Name', value: 'shipToName' },
      { text: 'Ship To City', value: 'shipToCityId' },
      { text: 'Ship To State', value: 'shipToStateId' },
      { text: 'UDF Ship Address Type', value: 'udfShipAddressTypeId' },
      { text: 'UDF SLSCR', value: 'udfSlscrId' },
      { text: 'Order', value: 'order', sortable: false },
    ],
    stores: [],
    customers: [],
    cities: [],
    states: [],
    shipAddressTypes: [],
    slscrs: [],
    itemsPerPage: 50,
    page: 1,
    pageCount: 0,
    itemsBreadcrumbs: [],
    isLoading: true,
    error: {
      status: false,
      message: '',
    },
    batchOrder: {},
    confirmModal: false,
    users: [],
    files: [],
    dragAndDropMessage: 'Select files or drop files here',
    confirmFiles: false,
  }),
  computed: {
    selectedStoreId: {
      get() {
        const { action, storeId } = this.$route.query || {};
        if (action === 'createOrder' && storeId) {
          return +storeId;
        }
        return null;
      },
      set(storeId) {
        if (storeId) {
          this.$router.replace({ query: { ...this.$route.query, action: 'createOrder', storeId } });
        } else {
          this.$router.replace({
            query: { ...this.$route.query, action: undefined, storeId: undefined },
          });
        }
      },
    },
    orderCreateModalVisible: {
      get() {
        return !!this.selectedStoreId;
      },
      set(newValue) {
        if (!newValue) {
          this.selectedStoreId = null;
        }
      },
    },
    storeInventory() {
      const store = this.stores.find((item) => item.id === this.selectedStoreId);
      return store?.Inventories;
    },
    prevOrderStore() {
      if (this.batchOrder[`${this.selectedStoreId}`]) {
        return JSON.parse(JSON.stringify(this.batchOrder[`${this.selectedStoreId}`]));
      }
      return null;
    },
  },
  methods: {
    printCustomer(store, header) {
      const field = header.text.toLowerCase().includes('name') ? 'name' : 'no';
      const customer = this.customers.find((c) => c.id === store.customerId) || {};
      return customer[field] || '';
    },
    printCity(store) {
      return this.cities.find((city) => city.id === store.shipToCityId)?.name || '';
    },
    printState(store) {
      return this.states.find((state) => state.id === store.shipToStateId)?.name || '';
    },
    printShipAddressType(store) {
      return this.shipAddressTypes.find((shipAddressType) => shipAddressType.id === store.udfShipAddressTypeId)?.name || '';
    },
    printSlscr(store) {
      return this.slscrs.find((slscr) => slscr.id === store.udfSlscrId)?.name || '';
    },
    async getStores(jwt) {
      const { data = [] } = await getStores(jwt);
      return data;
    },
    async getUsers(jwt) {
      const { data = [] } = await getUsers(jwt);
      return data;
    },
    async getCustomers(jwt) {
      const { data = [] } = await getCustomers(jwt);
      return data;
    },
    async getCities(jwt) {
      const { data = [] } = await getCities(jwt);
      return data;
    },
    async getStates(jwt) {
      const { data = [] } = await getStates(jwt);
      return data;
    },
    async getShipAddressTypes(jwt) {
      const { data = [] } = await getShipAddressTypes(jwt);
      return data;
    },
    async getSlscrs(jwt) {
      const { data = [] } = await getSlscrs(jwt);
      return data;
    },
    closeModal() {
      this.openModal = false;
      this.selectedStore = undefined;
      this.prevOrderStore = undefined;
      this.storeInventory = undefined;
    },
    saveChanges(order) {
      if (order && order.Inventories && order.Inventories.length) {
        this.batchOrder[`${this.selectedStoreId}`] = order;
      } else if (this.batchOrder[`${this.selectedStoreId}`] && !order.Inventories.length) {
        delete this.batchOrder[`${this.selectedStoreId}`];
      }
      this.selectedStoreId = null;
    },
    sendOrder() {
      if (this.files.length) {
        this.confirmFiles = true;
      } else {
        this.confirmModal = true;
      }
    },
    async confirmBatchOrder() {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        await createBatchOrder(jwt, this.batchOrder);
        this.batchOrder = {};
        this.$router.push({ path: '/orders' });
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
        this.confirmModal = false;
      }
    },
    addDropFile(e) {
      this.dragAndDropMessage = 'Select files or drop files here';
      this.files.push(...e.dataTransfer.files);
    },
    addDragFile() {
      this.dragAndDropMessage = 'DROP THE FILE';
    },
    addDragLeaveFile() {
      this.dragAndDropMessage = 'Select files or drop files here';
    },
    async confirmSendFiles() {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        const body = new FormData();
        this.files.forEach((file, index) => {
          body.append(`file_${index}`, file);
        });
        await createBatchOrderFile(jwt, body);
        this.files = [];
        this.$router.push({ path: '/orders' });
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
        this.confirmFiles = false;
      }
    },
  },
  async mounted() {
    try {
      this.itemsBreadcrumbs = [{
        text: 'Orders',
        to: '/orders',
        disabled: false,
      },
      {
        text: 'Create New Batch Order',
        to: '/batch-order/new',
        disabled: true,
      }];
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      [
        this.users,
        this.stores,
        this.customers,
        this.cities,
        this.shipAddressTypes,
        this.slscrs,
        this.states,
      ] = await Promise.all([
        this.getUsers(jwt),
        this.getStores(jwt),
        this.getCustomers(jwt),
        this.getCities(jwt),
        this.getShipAddressTypes(jwt),
        this.getSlscrs(jwt),
        this.getStates(jwt),
      ]);
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
