import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getUserStores = (token, userId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/stores`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const deleteUserStore = (token, userId, storeId) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/stores/${storeId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const updateUserStore = (token, userId, storeId, body) => axios.patch(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/stores/${storeId}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const createUserStore = (token, userId, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/stores/`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const saveUserStores = (token, userId, storeIds) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/user/${userId}/save_user_stores`, { storeIds }, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
