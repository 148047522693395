<template>
  <div>
    <v-toolbar flat>
      <h3>Collateral Assignment</h3>
      <v-spacer></v-spacer>
      <v-select
        class="mt-3 me-2 flex-grow-0"
        dense
        v-model="filterMonth"
        :items="availableMonths"
        :item-text="'value'"
        :item-value="'value'"
        placeholder="Filter by month">
        <template slot="append">
          <v-btn
            v-if="filterMonth"
            elevation="2"
            x-small
            icon
            @click.stop.prevent="filterMonth = null">
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </template>
      </v-select>
      <v-select
        class="mt-3 me-2 flex-grow-0"
        dense
        v-model="filterYear"
        :items="availableYears"
        placeholder="Filter by year">
        <template slot="append">
          <v-btn
            v-if="filterYear"
            elevation="2"
            x-small
            icon
            @click.stop.prevent="filterYear = null">
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </template>
      </v-select>
      <v-btn
        @click="download"
        :disabled="!regions.length || isLoading || isDownloading || !filterMonth"
        tile
        color="primary"
        class="mx-4"
      >
        <v-icon left>
          mdi-download
        </v-icon>
        Download CSV
      </v-btn>
    </v-toolbar>
    <v-divider class="my-4"></v-divider>
    <v-progress-linear
      v-if="isLoading"
      color="teal accent-4"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
    <v-expansion-panels v-if="!isLoading" focusable v-model="panels">
      <v-expansion-panel
        v-for="(region, i) in (regions)" :key="i"
        :ref="'panel' + region.id">
        <v-expansion-panel-header class="pb-0">
          <v-row no-gutters>
            <v-col cols="6">
              <strong>{{ region.name }}</strong> ({{ region.data.length}} stores)
            </v-col>
            <v-col cols="5">
              <span v-html="region.htmlText"></span>
            </v-col>
            <v-col cols="1">
              <div class="text-right my-4">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      fab
                      color="blue"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="toggleRegionSelection(region)"
                      :disabled="!Boolean(region.id)"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Assign Collateral</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :search="storeSearch"
            :headers="headers"
            :items="region.data"
            item-key="name"
            class="elevation-1 my-4"
            hide-default-footer
            disable-pagination
            sortBy="storeNo"
          >
            <template v-slot:top>
              <v-text-field
                v-model="storeSearch"
                append-icon="mdi-magnify"
                label="Search by Retailer, Store Number, Store Name, SRSM"
                single-line
                hide-details
                class="mx-4"
              ></v-text-field>
            </template>
            <template v-slot:[`item.assignCollateralDoor`]="{ item }">
              <v-btn
                outlined
                small
                color="light-green darken-2"
                @click="assignCollateralToDoor(item)"
              >
                <v-icon left>mdi-pencil</v-icon> Assign Coll
              </v-btn>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <router-link
                :to="`/regions/${item.id}`"
                class="text-decoration-none">
                <v-btn
                  outlined
                  small
                  color="light-blue darken-2"
                  >
                  <v-icon left>mdi-pencil</v-icon> Edit
                </v-btn>
              </router-link>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                outlined
                small
                color="red darken-2"
                @click="itemToDelete = item"
                >
                <v-icon left>mdi-delete</v-icon> Delete
              </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="dialog"
      max-width="70%"
      @click:outside="closeDialog"
      v-if="dialog">
      <v-card>
        <v-card-title class="text-h5">
          {{ regionSelected.name || doorSelected.name }}
        </v-card-title>

        <v-card-text>
          <div v-if="regionSelectedId" class="text-right my-4">
            Number of Stores: <strong>{{ regionSelected.data.length }}</strong>
          </div>
          <div>
            <v-data-table
              :headers="assignmentHeaders"
              :items="regionSelectedId
                ? regionSelected.collateralAssignments
                : doorSelected.collateralAssignments"
              item-key=""
              class="elevation-1 my-4"
              hide-default-footer
              disable-pagination
              :custom-sort="customSort"
              sort-by="propData.year"
              :sortDesc="true"
              must-sort
              :loading="isLoading"
            >
              <template v-slot:top>
                <v-btn
                  text
                  small
                  color="light-blue darken-2"
                  class="text-decoration-underline"
                  @click="resetAssignmentForm(); openAssignmentForm()"
                >
                  New Entry
                </v-btn>
              </template>
              <template v-slot:[`item.propData.month`]="{ item }">
                {{ printMonth(item.propData.month) }}
              </template>
              <template v-slot:[`item.propData.maxInventoryAlloc`]="{ item }">
                {{ formatPercent(item.propData.maxInventoryAlloc) }}
              </template>
              <template v-slot:[`item.propData.maxCostAlloc`]="{ item }">
                {{ formatPrice(item.propData.maxCostAlloc) }}
              </template>
              <template v-slot:[`item.propData.brandId`]="{ item }">
                {{ findBrand(item.propData.brandId) }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  fab
                  text
                  x-small
                  color="blue"
                  aria-label="Edit Assignment"
                  @click="editAssignmentForm(item.id)"
                >
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                  fab
                  text
                  x-small
                  color="red darken-2"
                  aria-label="Delete Assignment"
                  @click="deleteAssignmentClicked(item.id)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink darken-1" @click="closeDialog" outlined>
            Cancel
          </v-btn>

          <v-btn color="teal darken-1" @click="closeDialog" text>
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="55%" v-model="dialogAssignmentForm">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedAssignmentId === undefined ? 'New Entry' : 'Edit Entry' }}
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-row>
              <v-col sm="3" align="right">
                <v-card-subtitle>
                  Brand:
                </v-card-subtitle>
              </v-col>
              <v-col>
                <v-select
                  v-model="assignmentForm.brandId"
                  :items="availableBrands"
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="3" align="right">
                <v-card-subtitle>
                  Year:
                </v-card-subtitle>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="assignmentForm.year"
                  single-line
                  type="number"
                  @input="checkAssignmentFormMonth"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="3" align="right">
                <v-card-subtitle>
                  Month:
                </v-card-subtitle>
              </v-col>
              <v-col>
                <v-select
                  v-model.number="assignmentForm.month"
                  :items="brandAvailableMonths"
                  item-text="value"
                  item-value="key"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="3" align="right">
                <v-card-subtitle>
                  Max Inventory Alloc:
                </v-card-subtitle>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="assignmentForm.maxInventoryAlloc"
                  single-line
                  type="number"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="3" align="right">
                <v-card-subtitle>
                  Max Cost Alloc:
                </v-card-subtitle>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="assignmentForm.maxCostAlloc"
                  single-line
                  type="number"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="pink darken-1" @click="closeAssignmentForm" outlined>
            Cancel
          </v-btn>

          <v-btn color="teal darken-1" @click="saveAssignment" text>
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isDeleting" max-width="60%" :persistent="isLoading">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this entry?</v-card-title>
        <v-col>
          <v-text-field
            :placeholder="`Type in ${confirmDeleteText}`"
            v-model="confirmDelete"
            :disabled="isLoading"
          />
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-2"
            text
            :disabled="isLoading"
            @click="itemToDelete = null"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-2"
            :disabled="isLoading || confirmDelete !== confirmDeleteText"
            text
            @click="confirmDeleteItem">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.type"
      >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <confirm-dialog
      title="Are you sure you want to delete this assignment?"
      v-model="confirmDeleteAssignment"
      @cancel="confirmDeleteAssignment = false"
      @confirm="deleteAssignment"
    />
  </div>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */

import { getMonth, getYear, compareAsc } from 'date-fns';
import {
  createCollateralAssignment,
  updateCollateralAssignment,
  deleteCollateralAssignment,
} from 'API/collateralAssignments';
import { getRegions } from 'API/retailLabRegion';
import { getBrands } from 'API/brands';
import { downloadCSV } from 'API/downloadCSV';
import { deleteRegion } from 'API/region';
import ConfirmDialog from 'SharedComponents/ConfirmDialog';
import {
  formatPrice,
  formatPercent,
  availableMonths,
  fiscalYears,
} from '~/consts';

export default {
  name: 'Regions',
  components: { ConfirmDialog },
  data: () => ({
    availableMonths,
    filteredMonths: availableMonths,
    availableYears: fiscalYears.map(({ value }) => value),
    storeSearch: '',
    headers: [
      { text: 'RETAILER', value: 'retailer.name' },
      { text: 'STORE TYPE', value: 'storeType.name' },
      { text: 'ACCOUNT TYPE', value: 'accountType.name' },
      { text: 'STORE #', value: 'storeNo' },
      { text: 'STORE NAME', value: 'storeName' },
      { text: 'NSD REGION', value: 'nsdRegion.name' },
      { text: 'SRSM', value: 'regionalMarket.name' },
      { text: 'Assign Collateral', value: 'assignCollateralDoor', sortable: false },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false },
    ],
    assignmentHeaders: [
      { text: 'Brand', value: 'propData.brandId' },
      { text: 'Year', value: 'propData.year' },
      { text: 'Month', value: 'propData.month', sortable: false },
      { text: 'Max Inventory Alloc', value: 'propData.maxInventoryAlloc' },
      { text: 'Max Cost Alloc', value: 'propData.maxCostAlloc' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    regions: [],
    originalRegions: [],
    isLoading: true,
    error: {
      status: false,
      message: '',
    },
    dialogAssignmentForm: false,
    selectedAssignmentId: undefined,
    doorSelectedId: null,
    confirmDeleteAssignment: false,
    assignmentForm: {
      year: getYear(new Date()),
      month: getMonth(new Date()) + 2 <= 10 ? getMonth(new Date()) + 2 : getMonth(new Date()),
      maxInventoryAlloc: undefined,
      maxCostAlloc: undefined,
      brandId: undefined,
    },
    availableBrands: [],
    itemToDelete: null,
    confirmDelete: null,
    confirmDeleteText: 'DELETE',
    snackbar: {
      status: false,
      message: '',
      type: 'error',
    },
    isDownloading: false,
  }),
  watch: {
    assignmentForm: {
      handler(newValue) {
        if (newValue.brandId) {
          const brandFreeMonths = this.getBrandFreeMonths();
          this.filteredMonths = brandFreeMonths;
        }
      },
      deep: true,
    },
  },
  computed: {
    regionSelected() {
      return this.regions.find((region) => region.id === this.regionSelectedId) || {
        name: '',
        id: 0,
        data: [],
        collateralAssignments: [],
      };
    },
    doorSelected() {
      const region = this
        .regions
        .find(({ id }) => `${id}` === `${this?.$route?.query?.regionId}`);
      const door = region?.data.find(({ id }) => id === this.doorSelectedId);

      return {
        name: `Door ${door?.storeNo} - ${door?.storeName}`,
        id: this.doorSelectedId,
        data: [],
        collateralAssignments: door?.collateralAssignment ?? [],
      };
    },
    regionSelectedId: {
      get() {
        const { regionId, action } = this.$route.query;
        return regionId && action === 'edit' ? +regionId : null;
      },
      set(regionId) {
        if (regionId) {
          this.$router.replace({ query: { ...this.$route.query, action: 'edit', regionId } });
        } else {
          this.regions = this.getFilteredRegions(this.originalRegions);
          this.$router.replace({ query: {} });
        }
      },
    },
    dialog: {
      get() {
        return !!this.regionSelectedId || !!this.doorSelectedId;
      },
      set(value) {
        if (!value) {
          this.$router.replace({ query: {} });
        }
      },
    },
    regionExpandedId: {
      get() {
        const { regionId, action } = this.$route.query;
        return regionId && action === 'expand' ? +regionId : null;
      },
      set(regionId) {
        if (regionId) {
          this.$router.replace({ query: { ...this.$route.query, action: 'expand', regionId } });
        } else {
          this.regions = this.getFilteredRegions(this.originalRegions);
          this.$router.replace({ query: {} });
        }
      },
    },
    panels: {
      get() {
        if (this.regionExpandedId) {
          const index = this.regions
            .findIndex((region) => region.id === this.regionExpandedId);
          if (index !== null && index > -1) {
            return index;
          }
        }
        return null;
      },
      set(value) {
        if (value !== undefined && value !== null) {
          const region = this.regions[value];
          if (region) this.regionExpandedId = region.id;
        } else this.$router.replace({ query: {} });
      },
    },
    isDeleting: {
      get() {
        return !!this.itemToDelete;
      },
      set(value) {
        if (!value) this.itemToDelete = null;
      },
    },
    filterMonth: {
      get() {
        const { month } = this.$route.query;
        return month || null;
      },
      set(value) {
        if (value) {
          this.regions = this.getFilteredRegions(this.originalRegions, value, this.filterYear);
          this.$router.replace({ query: { ...this.$route.query, month: value } });
        } else {
          this.regions = this.getFilteredRegions(this.originalRegions);
          this.$router.replace({ query: {} });
        }
      },
    },
    filterYear: {
      get() {
        const { year } = this.$route.query;
        return year || null;
      },
      set(value) {
        if (value) {
          this.regions = this.getFilteredRegions(this.originalRegions, this.filterMonth, value);
          this.$router.replace({ query: { ...this.$route.query, year: value } });
        } else {
          this.regions = this.getFilteredRegions(this.originalRegions);
          this.$router.replace({});
        }
      },
    },
    brandAvailableMonths() {
      return this.filteredMonths;
    },
  },
  methods: {
    formatPrice,
    formatPercent,
    printMonth(month) {
      const { value = '' } = availableMonths.find(({ key }) => key === month);
      return value;
    },
    getCollateralBudgets(collateralAssignments) {
      const budgets = collateralAssignments.map(
        ({ propData }) => {
          const { brandId, maxInventoryAlloc, maxCostAlloc } = propData || {};
          const brand = this.findBrand(brandId) || 'Creed';
          const brandMaxInventoryAlloc = `${brand} Max Inventory Alloc`;
          const brandMaxCostAlloc = `${brand} Max Cost Alloc`;

          return {
            [brandMaxInventoryAlloc]: formatPercent(maxInventoryAlloc),
            [brandMaxCostAlloc]: formatPrice(maxCostAlloc),
          };
        },
      );

      return Object.assign({}, ...budgets);
    },
    download() {
      this.isDownloading = true;

      const { month, year } = this.regions[0]?.collateralAssignments?.[0]?.propData;
      const formattedmonth = month.toString().length < 2 ? month.toString().padStart(2, '0') : month;
      const brandsAvailable = new Set();
      const elements = [];

      this.regions
        .filter(({ collateralAssignments }) => collateralAssignments?.length)
        .forEach(({ name, data, collateralAssignments }) => {
          const regionCollateralBudgets = this.getCollateralBudgets(collateralAssignments);
          Object.keys(regionCollateralBudgets).forEach((key) => brandsAvailable.add(key));

          elements.push({
            name,
            ...regionCollateralBudgets,
          });

          data
            .filter(({ collateralAssignment }) => collateralAssignment?.length)
            .forEach(({ storeNo, storeName, collateralAssignment }) => {
              const door = `Door ${storeNo} - ${storeName}`;
              const doorCollateralBudgets = this.getCollateralBudgets(collateralAssignment);
              Object.keys(doorCollateralBudgets).forEach((key) => brandsAvailable.add(key));

              elements.push({
                name: door,
                ...doorCollateralBudgets,
              });
            });
        });

      // adding missing brands to the header
      brandsAvailable.forEach((brand) => {
        const header = elements[0];
        if (!(brand in header)) {
          header[brand] = '';
        }
      });

      downloadCSV({
        name: `Collateral Assignment ${formattedmonth}-${year}`,
        elements,
      });

      this.isDownloading = false;
    },
    toggleRegionSelection({ id }) {
      this.dialog = true;
      this.regionSelectedId = id;
    },
    closeDialog() {
      if (this.doorSelectedId) {
        this.doorSelectedId = null;
      } else {
        this.dialog = false;
      }
    },
    openAssignmentForm() {
      this.dialogAssignmentForm = true;
    },
    assignCollateralToDoor(item) {
      this.doorSelectedId = item.id;
    },
    closeAssignmentForm() {
      this.selectedAssignmentId = undefined;
      this.dialogAssignmentForm = false;
      this.resetAssignmentForm();
    },
    editAssignmentForm(id) {
      this.selectedAssignmentId = id;

      const collateralAssignments = this.doorSelectedId
        ? this.doorSelected.collateralAssignments
        : this.regionSelected.collateralAssignments;
      const collateralAssignment = collateralAssignments.find(
        (item) => item.id === id,
      );

      this.assignmentForm = { ...collateralAssignment.propData };
      this.openAssignmentForm();
    },
    resetAssignmentForm() {
      this.assignmentForm = {
        year: getYear(new Date()),
        month: getMonth(new Date()) + 2 <= 10 ? getMonth(new Date()) + 2 : getMonth(new Date()),
        maxInventoryAlloc: undefined,
        maxCostAlloc: undefined,
        brandId: undefined,
      };
      this.filteredMonths = this.availableMonths;
    },
    getBrandFreeMonths(initMonthDisabled = false) {
      const { collateralAssignments } = this.regionSelected;
      const { brandId, year, month: assignmentMonth } = this.assignmentForm;

      const brandFilledMonths = collateralAssignments
        .filter(({ propData }) => propData.year === year && propData.brandId === brandId)
        .map(({ propData }) => propData.month);

      if (!brandFilledMonths.length) {
        return availableMonths;
      }

      const brandFreeMonths = availableMonths.map((month) => {
        const initMonthCheck = initMonthDisabled || assignmentMonth !== month.key;

        return {
          ...month,
          ...(initMonthCheck
            && brandFilledMonths.includes(month.key)
            ? { disabled: true }
            : undefined
          ),
        };
      });

      return brandFreeMonths;
    },
    checkAssignmentFormMonth() {
      const brandFreeMonths = this.getBrandFreeMonths(true);
      const { month: assignmentFormMonth } = this.assignmentForm;
      const disabledMonth = brandFreeMonths?.find(
        ({ key }) => key === assignmentFormMonth,
      ).disabled;

      if (disabledMonth) {
        this.assignmentForm = {
          ...this.assignmentForm,
          month: brandFreeMonths[0].key,
        };
      }
    },
    async saveAssignment() {
      try {
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();

        if (this.selectedAssignmentId === undefined) {
          await createCollateralAssignment(jwt, {
            ...(this.regionSelectedId
              ? { retailLabRegionId: this.regionSelectedId }
              : { regionId: this.doorSelectedId }
            ),
            propData: this.assignmentForm,
          });
        } else {
          await updateCollateralAssignment(jwt, {
            id: this.selectedAssignmentId,
            propData: this.assignmentForm,
          });
        }

        this.closeAssignmentForm();
        this.closeDialog();

        this.resetAssignmentForm();

        await this.loadData(false);
      } catch (err) {
        console.error(err);
      }
    },
    deleteAssignmentClicked(id) {
      this.selectedAssignmentId = id;
      this.confirmDeleteAssignment = true;
    },
    async deleteAssignment() {
      if (this.selectedAssignmentId === undefined) {
        return;
      }

      try {
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();

        await deleteCollateralAssignment(jwt, {
          id: this.selectedAssignmentId,
        });

        this.confirmDeleteAssignment = false;
        this.closeAssignmentForm();
        this.closeDialog();

        await this.loadData();
      } catch (err) {
        console.error(err);
      }
    },
    async loadData(isLoading = true) {
      try {
        this.isLoading = isLoading;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        const { data = [] } = await getRegions(jwt);
        const { data: brands = [] } = await getBrands(jwt);
        this.availableBrands = brands;
        this.originalRegions = data;
        this.regions = this.getFilteredRegions(data, this.filterMonth, this.filterYear);
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        setTimeout(() => {
          this.isLoading = false;
          this.goToPanel();
        }, 1000);
      }
    },
    getFilteredRegions(regions, month, year) {
      const filterMonth = month && availableMonths.find(({ value }) => value === month).key;
      const filterYear = year && Number(year);
      return regions
        .map((region) => {
          const regionFiltered = region?.regions
            ?.map(({ collateralAssignment, ...regionData }) => ({
              ...regionData,
              collateralAssignment: collateralAssignment?.filter(({ propData }) => (
                (filterMonth ? propData?.month === filterMonth : true)
                &&
                (filterYear ? propData?.year === filterYear : true)
              )),
            }));

          const collateralAssignmentsFiltered =
            region.collateralAssignments
              .filter(({ propData }) => (
                (filterMonth ? propData?.month === filterMonth : true)
                &&
                (filterYear ? propData?.year === filterYear : true)
              ));

          const firstMonthAndYear = collateralAssignmentsFiltered
            .sort(({ propData: propDataA }, { propData: propDataB }) => {
              const date1 = new Date(propDataA?.year, propDataA?.month - 1);
              const date2 = new Date(propDataB?.year, propDataB?.month - 1);
              return date1 - date2;
            })?.[0]?.propData;

          const showOnMain = collateralAssignmentsFiltered
            .filter(({ propData }) => propData?.month === firstMonthAndYear?.month
              && propData?.year === firstMonthAndYear.year);
          const htmlText = firstMonthAndYear && `
            ${this.printMonth(firstMonthAndYear?.month)} ${firstMonthAndYear.year}
            </br>
            ${showOnMain.map(({ propData }) => `
              ${this.findBrand(propData?.brandId) || 'No Brand'}:
              ${formatPrice(propData.maxCostAlloc)} - ${formatPercent(propData?.maxInventoryAlloc)}
            `).join(' | ')}
          `;

          return {
            name: region?.name || 'N/A',
            data: regionFiltered,
            id: region?.id,
            collateralAssignments: collateralAssignmentsFiltered,
            htmlText,
          };
        })
        .sort((a, b) => b.name - a.name, 0);
    },
    findBrand(id) {
      const { availableBrands = [] } = this;
      const brand = availableBrands.find((item) => item.id === id);
      return brand?.name || '';
    },
    customSort(items, [index], [isDesc]) {
      const property = index && index.replace('propData.', '');
      const itemsSorted = items.sort(({ propData: a }, { propData: b }) => {
        if (property === 'year') {
          const aDate = new Date(a.year, a.month);
          const bDate = new Date(b.year, b.month);
          if (!isDesc) return compareAsc(aDate, bDate);
          return compareAsc(bDate, aDate);
        }

        if (property === 'brandId') {
          if (!isDesc) return (a[property] || 0) < (b[property] || 0) ? -1 : 1;
          return (b[property] || 0) < (a[property] || 0) ? -1 : 1;
        }

        if (!isDesc) return a[property] < b[property] ? -1 : 1;
        return b[property] < a[property] ? -1 : 1;
      });
      return itemsSorted;
    },
    goToPanel(time = 100) {
      setTimeout(() => {
        const element = this.$refs[`panel${this.regionExpandedId}`];
        if (element) this.$vuetify.goTo(element[0]);
      }, time);
    },
    async confirmDeleteItem() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        const res = await deleteRegion(jwt, this.itemToDelete.id);
        if (res.statusText === 'OK') {
          this.regions.forEach((region) => {
            const { id } = region;
            if (id === this.regionExpandedId) {
              const data = region.data.filter((item) => item.id !== this.itemToDelete.id);
              Object.assign(region, {
                data,
              });
            }
          });
          this.snackbar = {
            status: true,
            type: 'success',
            message: 'Region deleted',
          };
        }
      } catch (error) {
        this.snackbar = {
          status: true,
          type: 'error',
          message: error,
        };
      } finally {
        this.isLoading = false;
        this.itemToDelete = null;
        this.confirmDelete = null;
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
