<template>
  <div>
    <v-list-item class="px-2">
      <v-list-item-avatar @click.stop="$emit('toggleDrawer')">
        <v-icon>menu</v-icon>
      </v-list-item-avatar>

      <v-list-item-title>ICP Collateral</v-list-item-title>

      <v-btn icon @click.stop="$emit('toggleDrawer')" >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <div
        v-for="item in navList"
        :key="item.name">
        <router-link
          v-if="item.link"
          :to="item.link"
          class="text-decoration-none">
          <v-list-item link>
              <v-list-item-icon class="me-3">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-group
          v-else-if="item.items.length > 0"
          no-action>
          <template v-slot:activator>
            <v-list-item-icon class="me-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
          <router-link
            v-for="child in item.items"
            :key="child.title"
            :to="child.link"
            class="text-decoration-none">
            <v-list-item link class="ms-9">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>
      </div>
    </v-list>
  </div>
</template>

<script>

export default {
  name: 'AdminDrawerLayout',
  data() {
    const managerNavList = [
      'Inventory',
      'Orders',
      'Store Matrix',
      'Collateral Exports',
    ];
    const groups = [{
      name: 'Reports',
      icon: 'table_view',
    }];
    return {
      navList: [
        {
          link: '/inventory',
          icon: 'featured_play_list',
          name: 'Inventory',
        },
        {
          link: '/orders',
          icon: 'developer_board',
          name: 'Orders',
          bottomDivider: true,
        },
        {
          link: '/users',
          icon: 'person',
          name: 'Users',
          bottomDivider: true,
        },
        {
          link: '/regions',
          icon: 'public',
          name: 'Store Matrix',
        },
        /*
        {
          link: '/stores',
          icon: 'store_mall_directory',
          name: 'Collateral Stores',
        },
        */
        {
          link: '/collateral-assignment',
          icon: 'layers',
          name: 'Collateral Assigment',
        },
        {
          link: '/exports',
          name: 'Collateral Exports',
          group: 'Reports',
        },
        {
          link: '/overdue-export',
          name: 'Overdue Manager Orders Export',
          group: 'Reports',
        },
        {
          link: '/email-notification',
          icon: 'notification_add',
          name: 'Email Notification',
        },
      ].filter(({ name }) => (this.$auth?.user?.UserType?.role === 'manager'
        ? managerNavList.includes(name)
        : true
      )).reduce((acc, { group: groupName, ...rest }) => {
        if (!groupName) {
          acc.push({ ...rest });
          return acc;
        }
        const accGroup = acc.find(({ name }) => name === groupName);
        if (accGroup) {
          accGroup.items.push(rest);
          return acc;
        }
        const group = groups.find(({ name }) => name === groupName);
        acc.push({
          ...group,
          items: [rest],
        });
        return acc;
      }, []),
    };
  },
};
</script>
