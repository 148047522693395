import AdminLayout from './Admin.vue';
import CollateralAssignment from './layouts/CollateralAssignment/CollateralAssignment.vue';
import Exports from './layouts/Exports/Exports.vue';
import Inventory from './layouts/Inventory/Inventory.vue';
import Users from './layouts/Users/Users.vue';
import Orders from './layouts/Orders/Orders.vue';
import OrderById from './layouts/Orders/OrderById.vue';
import OrderCreate from './layouts/Orders/OrderCreate.vue';
import BatchOrderCreate from './layouts/Orders/BatchOrderCreate.vue';
import Stores from './layouts/Stores/Stores.vue';
import StoreCreate from './layouts/Stores/StoreCreate.vue';
import StoreById from './layouts/Stores/StoreById.vue';
import Regions from './layouts/Regions/Regions.vue';
import RegionCreate from './layouts/Regions/RegionCreate.vue';
import RegionById from './layouts/Regions/RegionById.vue';
import EmailNotification from './layouts/EmailNotification/EmailNotification.vue';
import OverdueManagerOrdersExport from './layouts/OverdueManagerOrdersExport/OverdueManagerOrdersExport.vue';

export default {
  AdminLayout,
  CollateralAssignment,
  Inventory,
  Exports,
  Users,
  Orders,
  OrderById,
  BatchOrderCreate,
  OrderCreate,
  Stores,
  StoreById,
  StoreCreate,
  Regions,
  RegionCreate,
  RegionById,
  EmailNotification,
  OverdueManagerOrdersExport,
};
