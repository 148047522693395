<template>
  <div>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="regions"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Doors</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by Name, City, Division, Customer No, Code and State"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical />
          <router-link to="/regions/new">
            <v-btn text small color="light-blue darken-2" class="text-decoration-underline">
              New Door
            </v-btn>
          </router-link>
          <v-divider class="mx-4" inset vertical />
          <v-btn
            @click="download"
            :disabled="!regions || isLoading"
            tile
            color="primary"
            class="mx-4"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            Download CSV
          </v-btn>
          <v-divider class="mx-4" inset vertical />
          <v-btn
            @click="bulkEdit"
            :disabled="!regions || isLoading || regions.filter(region => region.check).length === 0"
            tile
            color="primary"
            class="mx-4"
            v-if="$auth.user.UserType.role === 'admin'"
          >
            <v-icon left>
              mdi-pencil
            </v-icon>
            Bulk Edit
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`header.check`]="{}">
          <v-checkbox
            v-model="selectedAll"
            @change="selectAll"
            :disabled="isLoading"
            v-if="$auth.user.UserType.role === 'admin'"
          ></v-checkbox>
        </template>

      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-checkbox
                  v-model="item.check"
                  @click.stop
                  v-bind="attrs"
                  v-on="on"
                  v-if="$auth.user.UserType.role === 'admin'"
                ></v-checkbox>
              </template>

              <span>Select</span>
            </v-tooltip>
          </td>

          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="salesPlansRegionId = item.id"
                  icon
                  small
                  class="mr-1"
                  aria-label="Edit Sales Plans"
                  link
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>receipt_long</v-icon>
                </v-btn>
              </template>

              <span>Show sales plan</span>
            </v-tooltip>
            <v-tooltip v-if="$auth.user.UserType.role === 'admin'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="$router.push(`/regions/${item.id}`)"
                  icon
                  small
                  class="mr-1"
                  aria-label="Edit"
                  link
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Edit Door</span>
            </v-tooltip>
          </td>

          <td class="font-weight-medium">
            {{ printRetailer(item) }}
          </td>

          <td>
            {{ item.storeNo }}
          </td>

          <td>
            {{ item.storeName }}
          </td>

          <td>
            {{ printStoreType(item) }}
          </td>

          <td>
            {{ printAccountType(item) }}
          </td>

          <td>
            {{ printUsersNames(item, 1) }}
          </td>

          <td>
            {{ printNSDRegion(item) }}
          </td>

          <td>
            {{ item.retailLabRegion.name }}
          </td>

          <td>
            {{ printSeniorRegionalSalesManagerMarket(item) }}
          </td>

          <td>
            {{ printUsersNames(item, 2) }}
          </td>

          <td>
            {{ printUsersNames(item, 3) }}
          </td>

          <td>
            {{ printUsersNames(item, 4) }}
          </td>

          <td>
            {{ printUsersNames(item, 5) }}
          </td>

          <td>
            {{ printBrandNames(item) }}
          </td>

          <td>
            {{ printDoorName(item) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-snackbar v-model="error.status" color="error" centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-if="regions.length && salesPlansRegionId"
      v-model="salesPlansDialogVisible"
      :persistent="isLoading"
    >
      <SalesPlansList
        :name="salesPlanRegion(salesPlansRegionId)"
        :region="getSalesPlansRegion(salesPlansRegionId)"
        :regionId="salesPlansRegionId"
        :key="salesPlansRegionId"
      />
    </v-dialog>

    <v-dialog
      v-model="bulkEditing"
      :persistent="isLoading"
    >
      <region-form
        v-if="bulkEditing"
        :regions="regionsEditing"
        @cancel="bulkEditing = false"
        @confirm="confirmBulkEdit"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getStoreMatrixRegions } from 'API/region';
import { getRetailers } from 'API/retailer';
import { getAccountTypes } from 'API/account-type';
import { getNSDRegions } from 'API/nsd-region';
import { getRegionalMarkets } from 'API/regional-market';
import { getStoreTypes } from 'API/store-type';
import { getBrands } from 'API/brands';
import { downloadCSV } from 'API/downloadCSV';
import { getUsers } from 'API/user';
import { getDoors } from 'API/doors';
import SalesPlansList from '../../components/Regions/SalesPlansList/index.vue';
import RegionForm from './RegionForm.vue';

export default {
  name: 'Regions',
  components: { SalesPlansList, RegionForm },
  data: () => ({
    search: '',
    headers: [
      { text: 'check', value: 'check', sortable: false },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '100px',
      },
      { text: 'RETAILER', value: 'retailerId' },
      { text: 'STORE #', value: 'storeNo' },
      { text: 'STORE NAME', value: 'storeName' },
      { text: 'STORE TYPE', value: 'storeTypeId' },
      { text: 'ACCOUNT TYPE', value: 'accountTypeId' },
      { text: 'NSD', value: 'printNSD' },
      { text: 'NSD REGION', value: 'nsdRegionId' },
      { text: 'Region', value: 'retailLabRegion.name', width: '260px' },
      { text: 'SRSM MARKET', value: 'seniorRegionalSalesManagerMarketId' },
      { text: 'SRSM', value: 'printSRSM' },
      { text: 'RSM', value: 'printRSM' },
      { text: 'RSM-Emerging Brands', value: 'printRSMEmerging' },
      { text: 'AE', value: 'printAE' },
      { text: 'Brands', value: 'brandIds', width: '100px' },
      { text: 'Door', value: 'doorId' },
    ],
    regions: [],
    retailers: [],
    accountTypes: [],
    nsdRegions: [],
    regionalMarkets: [],
    storeTypes: [],
    availableBrands: [],
    users: [],
    doors: [],
    itemsPerPage: 50,
    page: 1,
    pageCount: 0,
    isLoading: false,
    error: {
      status: false,
      message: '',
    },
    selectedAll: false,
    bulkEditing: false,
    regionsEditing: [],
  }),
  computed: {
    salesPlansRegionId: {
      get() {
        const { view, regionId } = this.$route.query || {};
        if (view === 'salesPlans' && regionId) {
          return +regionId;
        }
        return null;
      },
      set(regionId) {
        if (regionId) {
          this.$router.replace({ query: { view: 'salesPlans', regionId } });
        } else {
          this.$router.replace({ query: {} });
        }
      },
    },
    salesPlansDialogVisible: {
      get() {
        return !!this.salesPlansRegionId;
      },
      set(newValue) {
        if (!newValue) {
          this.salesPlansRegionId = null;
        }
      },
    },
  },
  methods: {
    printRetailer(region) {
      return this.retailers.find((retailer) => retailer.id === region?.retailerId)?.name || '';
    },
    printAccountType(region) {
      return (
        this.accountTypes.find((accountType) => accountType.id === region.accountTypeId)?.name || ''
      );
    },
    printUsersNames(item, roleId) {
      const retailLabRegionId = item.retailLabRegion.id;
      const overrideSettings = item?.overrideSettings;

      return this.users?.filter(({ id: userId, UserRole, retailLabRegions }) => {
        const userRegions = retailLabRegions.map(({ id }) => id);
        const overrideValues = overrideSettings?.find((
          { roleId: settingsRoleId },
        ) => settingsRoleId === roleId);

        if (overrideValues?.roleId) {
          return overrideValues?.userId
            && userId === overrideValues?.userId;
        }
        return UserRole?.id === roleId
          && userRegions.includes(retailLabRegionId);
      }).map(({ fullName = '' }) => fullName).join(', ');
    },
    printNSDRegion(region) {
      return this.nsdRegions.find((nsdRegion) => nsdRegion.id === region.nsdRegionId)?.name || '';
    },
    printSeniorRegionalSalesManagerMarket(region) {
      return (
        this.regionalMarkets.find(
          (regionalMarket) => regionalMarket.id === region.seniorRegionalSalesManagerMarketId,
        )?.name || ''
      );
    },
    printStoreType(region) {
      return this.storeTypes.find((storeType) => storeType.id === region.storeTypeId)?.name || '';
    },
    printBrandNames(item) {
      return item?.brandIds?.map((brandId) => {
        const brand = this.availableBrands?.find(({ id: bId }) => bId === brandId);
        return brand?.name;
      }).join(', ');
    },
    printDoorName(item) {
      return this.doors.find((door) => door.id === item.doorId)?.status || '';
    },
    salesPlanRegion(salesPlansRegionId) {
      const region = this.getSalesPlansRegion(salesPlansRegionId);
      return `${this.printRetailer(region)} - ${region.storeName}`;
    },
    getSalesPlansRegion(salesPlansRegionId) {
      return this.regions.find(({ id }) => id === salesPlansRegionId);
    },
    download() {
      const brandsAvailable = new Set();
      const elements = [...this.regions]
        .map((region) => {
          const {
            retailer = {},
            storeType = '',
            accountType = {},
            storeNo = '',
            storeName = '',
            nsdRegion = {},
            regionalMarket = {},
            retailLabRegion = {},
            mall = '',
            location = '',
            city,
            brandIds,
          } = region;
          const obj = {
            RETAILER: retailer?.name ?? '',
            'STORE TYPE': storeType?.name ?? '',
            'ACCOUNT TYPE': accountType?.name ?? '',
            'STORE #': storeNo ?? '',
            'STORE NAME': storeName ?? '',
            NSD: this.printUsersNames({ retailLabRegion }, 1),
            'NSD REGION': nsdRegion?.name ?? '',
            REGION: retailLabRegion?.name ?? '',
            'SENIOR REGIONAL SALES MANAGER MARKET': regionalMarket.name ?? '',
            SRSM: this.printUsersNames({ retailLabRegion }, 2),
            RSM: this.printUsersNames({ retailLabRegion }, 3),
            'RSM-Emerging Brands': this.printUsersNames({ retailLabRegion }, 4),
            AE: this.printUsersNames({ retailLabRegion }, 5),
            'REGIONAL MARKET': city?.name ?? '',
            MALL: mall ?? '',
            ADDRESS: location ?? '',
          };
          const brands = brandIds?.map((brandId) => {
            const brand = this.availableBrands.find(({ id }) => id === brandId);
            return brand?.name;
          }).filter((b) => !!b).forEach((name) => {
            obj[name] = 'x';
            brandsAvailable.add(name);
          });
          const dt = { brands, ...obj };
          delete dt.brands;
          return dt;
        });
      // adding missing brands to the header
      brandsAvailable.forEach((brand) => {
        const header = elements[0];
        if (!(brand in header)) {
          header[brand] = '';
        }
      });
      downloadCSV({
        elements,
        name: 'StoreMatrix',
      });
    },
    selectAll(a) {
      this.regions = this.regions.map((region) => ({ ...region, check: a }));
    },
    bulkEdit() {
      this.regionsEditing = this.regions.filter((region) => region.check);
      this.bulkEditing = Boolean(this.regionsEditing.length) || false;
    },
    confirmBulkEdit(regions) {
      regions.forEach((region) => {
        const { id } = region;
        const regionToUpdate = this.regions.find(({ id: regionId }) => regionId === id);
        Object.assign(regionToUpdate, region);
      });
      this.bulkEditing = false;
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const [
        regionsResponse,
        retailersResponse,
        accountTypesResponse,
        nsdRegionsResponse,
        regionalMarketsResponse,
        storeTypesResponse,
        brandsResponse,
        usersResponse,
        doorsResponse,
      ] = await Promise.all([
        getStoreMatrixRegions(jwt),
        getRetailers(jwt),
        getAccountTypes(jwt),
        getNSDRegions(jwt),
        getRegionalMarkets(jwt),
        getStoreTypes(jwt),
        getBrands(jwt),
        getUsers(jwt),
        getDoors(jwt),
      ]);
      this.regions = regionsResponse.data.map((region) => ({
        ...region,
        check: false,
      }));
      this.retailers = retailersResponse.data;
      this.accountTypes = accountTypesResponse.data;
      this.nsdRegions = nsdRegionsResponse.data;
      this.regionalMarkets = regionalMarketsResponse.data;
      this.storeTypes = storeTypesResponse.data;
      this.availableBrands = brandsResponse.data;
      this.users = usersResponse.data;
      this.doors = doorsResponse.data;
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
