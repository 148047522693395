<template>
  <v-combobox
    v-model="address"
    :multiple="false"
    :items="suggestions"
    :label="label || 'Type to search address'"
    clearable
    required
    v-debounce="searchAddress"
    no-filter
    :loading="isSearching"
    @update:search-input="updateValue"
  />
</template>

<script>
import { searchAddress } from 'API/addresses';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    isSearching: false,
    address: '',
    suggestions: [],
    error: {
      status: false,
      message: '',
    },
  }),

  methods: {
    updateValue(address) {
      this.$emit('input', address);
    },

    async searchAddress(phrase) {
      if (phrase.length < 3) return;

      this.isSearching = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();

      try {
        const { data: options = [] } = await searchAddress(jwt, phrase);

        if (options.length) {
          this.suggestions = options.map(({ description }) => description);
        }
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isSearching = false;
      }
    },
  },

  mounted() {
    this.address = this.$attrs.value;
  },
};
</script>
