// import * as axios from 'axios';

// const { VUE_APP_API_URL } = process.env || {};
// export const getStatusOptions = (token) =>
// axios.get(`${VUE_APP_API_URL}/api/v1/admin/statusOptions`, {
//   headers: {
//     Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
//   },
// });

export const getStatusOptions = () => ['Pending', 'Approved', 'Denied', 'Archived'];
