<template>
  <v-data-table
    :headers="headers"
    :items="entries"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn @click.native="addNewRow" color="primary" plain x-small>New Entry</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.week="props">
        <v-edit-dialog
          :return-value.sync="props.item.week"
          @close="save"
        >
          <span v-if="!!props.item.week">{{ props.item.week }}</span>
          <small class="grey--text" v-else>Week Number</small>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Week Number
            </div>
            <v-text-field
              v-model="props.item.week"
              label="Edit"
              single-line
              type="number"
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
    </template>
    <template v-slot:item.goal="props">
        <v-edit-dialog
          :return-value.sync="props.item.goal"
          @close="save"
        >
          <span v-if="!!props.item.goal">{{ formatPrice(props.item.goal) }}</span>
          <small class="grey--text" v-else>Sales Plan Value</small>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Sales Plan
            </div>
            <v-text-field
              v-model="props.item.goal"
              label="Edit"
              single-line
              type="number"
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
    </template>
  </v-data-table>
</template>

<script>
import { formatPrice } from '~/consts';

export default {
  props: {
    goalAdjustments: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Week#', value: 'week', sortable: false },
        { text: 'Plan', value: 'goal', sortable: false },
      ],
      entries: [],
    };
  },
  mounted() {
    if (this.goalAdjustments && this.goalAdjustments.length) {
      this.entries = this.goalAdjustments;
    }
  },
  methods: {
    formatPrice,
    addNewRow() {
      this.entries = [].concat(this.entries, [{ week: null, goal: null }]);
    },
    save() {
      const entries = this.entries.filter(({ week = null, goal = null }) => !!week && !!goal);
      if (entries.length) {
        this.$emit('updatePlans', entries);
      }
    },
  },
};
</script>
