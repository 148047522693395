<template>
  <div>
    <v-card
      :loading="isLoading"
      >
      <v-card-title>
        <v-breadcrumbs
          divider=">"
          :items="breadCrumbs || itemsBreadcrumbs">
          <template v-slot:item="{ item }">
            <router-link
              :to="item.to"
              class="text-decoration-none"
              :class="item.disabled ? 'text text--disabled' : ''">
              {{ item.text }}
            </router-link>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-form ref="form">
        <v-container>
          <v-row :justify="'space-between'">
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Shipping To: &nbsp;
              </v-card-subtitle>
              <v-select
                v-model="orderFormValues.entity"
                :rules="orderFormRules.entityRules"
                :items="usersAndStores"
                :item-text="getItemRegionText"
                :disabled="currentOrder ? Boolean(currentOrder.id) : false"
                required
                persistent-hint
                return-object
                single-line
                placeholder="Store">
                <template slot="selection" slot-scope="data">
                  <dropdown :entity="data"></dropdown>
                </template>
                <template slot="item" slot-scope="data">
                  <dropdown :entity="data"></dropdown>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row :justify="'space-between'">
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Brand: &nbsp;
              </v-card-subtitle>
              <div v-if="currentOrder && currentOrder.id">
                <v-text-field
                  :value="getSelectedBrandName()"
                  disabled
                  ></v-text-field>
              </div>
              <div v-else>
                <v-select
                  v-model="orderFormValues.brand"
                  :items="brands"
                  :item-text="getBrandText"
                  :disabled="disableBrandSelection"
                  required
                  persistent-hint
                  return-object
                  single-line
                  placeholder="Brand">
                </v-select>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="orderFormValues.Inventories"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          disable-pagination
          hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Items</v-toolbar-title>
              <v-btn icon @click="clickPDF" aria-label="Create PDF">
                <v-icon>mdi-pdf-box</v-icon>
              </v-btn>
              <v-btn icon @click="clickPrint" aria-label="Print" class="mr-2">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="clickExport"
                    v-if="order"
                    aria-label="Export"
                    v-on="on"
                    v-bind="attrs"
                    class="mr-2">
                    <v-icon>mdi-export</v-icon>
                  </v-btn>
                </template>
                <span>Export Order</span>
              </v-tooltip>
              <div>
                Remaining Budget (Entire region):
                <v-chip
                  small
                  :color="selectedEntity.remainingCost >= 0 ? 'success' : 'red'"
                  text-color="white"
                  class="font-weight-bold"
                >
                  {{ formatPrice(selectedEntity.remainingCost) }}
                </v-chip>
              </div>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      fab
                      outlined
                      small
                      color="red darken-2"
                      class="mr-2"
                      @click="confirmDelete = true"
                      v-if="order"
                      v-bind="attrs"
                      v-on="on"
                      aria-label="Delete Order">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Order</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      fab
                      dark
                      small
                      color="primary"
                      class="mr-2"
                      @click.stop="openModal = !openModal"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!selectedEntity.id || duplicatedOrderId ? true : false"
                      aria-label="Add or Edit Items">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Order</span>
              </v-tooltip>
              <!-- TODO: refactor, Disabled for now -->
              <div v-if="false">
                <v-btn
                    fab
                    dark
                    small
                    color="primary"
                    class="mr-2"
                    @click="openSlideOut = !openSlideOut"
                    aria-label="Add or Edit Items">
                  <v-icon>mdi-storefront</v-icon>
                </v-btn>
              </div>
            </v-toolbar>
            <v-toolbar flat v-if="selectedEntity.remainingCost < 0">
              <p class="red--text">
                Order is not saved because the total cost is higher than the remaining budget
              </p>
            </v-toolbar>
             <v-toolbar flat v-if="duplicatedOrderId">
              <p class="red--text">
                This order is a duplicate of
                <a :href="`/orders/${duplicatedOrderId}`">{{ duplicatedOrderId }}</a>
              </p>
            </v-toolbar>
          </template>
          <template v-slot:[`header.totalCost`]="{ header }">
            {{ header.text }}&nbsp;
            <v-chip small color="primary">
              {{ formatPrice(getTotalPrice()) }}
            </v-chip>
          </template>
          <template v-slot:[`item.totalCost`]="{ item }">
            <v-chip small color="primary">
              {{ formatPrice(getItemTotalPrice(item)) }}
            </v-chip>
          </template>
          <template v-slot:[`item.lastTotalUnitCost`]="{ item }">
            {{ formatPrice(item.lastTotalUnitCost) }}
          </template>
        </v-data-table>
        <v-row class="my-4">
          <v-col>
            <v-textarea
              solo
              background-color="amber lighten-4"
              v-model="orderFormValues.comments"
              label="Comments"
              class="mx-4"
              v-debounce="saveChanges"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmSubmit = true"
            :disabled="
              isLoading
              || !orderFormValues.Inventories.length
              "
            tile
            color="success"
            large
            class="mx-4">
            <v-icon left>
              mdi-check-circle
            </v-icon>
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-dialog v-model="confirmSubmit" max-width="320" :persistent="isLoading">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to {{ order ? "modify" : "place" }} this order?
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="confirmSubmit = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="submitChanges">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :persistent="isLoading"
      title="Are you sure you want to delete this order?"
      v-model="confirmDelete"
      @cancel="confirmDelete = false"
      @confirm="deleteOrder"
    />
    <inventory-modal
      v-if="selectedEntity.id"
      v-model="openModal"
      v-on:updateInventoryQuantity="updateInventoryQuantity"
      :selectedEntity="selectedEntity"
      :selectedInventory="orderFormValues.Inventories"
      :itemText="getItemRegionText(selectedEntity)"
      :brandId="orderFormValues.brand.id"
      :currentMonthOrders="currentMonthOrders"
      :usedInventoryQuantity="usedInventoryQuantity"
      >
    </inventory-modal>

    <inventory-slide-out
      v-model="openSlideOut"
      @close="openSlideOut = false"
      :selectedInventory="regionsInventory"
      itemText="COLLATERAL INVENTORY"
      :usedInventoryQuantityPerRegion="usedInventoryQuantityPerRegion"
      />
    <v-snackbar
      v-model="snackbar.status"
      :color="snackbar.type"
      >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  .input-quantity {
    max-width: 50px;
  }
</style>
<script>
import { startOfDay, endOfDay, sub } from 'date-fns';
import {
  getOrders,
  createOrder,
  updateOrder,
  getOrder,
  deleteOrder,
} from 'API/order';
import {
  getStores,
} from 'API/store';
import { getRegions } from 'API/region';
import { getInventoryList } from 'API/inventory';
import { getBrands } from 'API/brands';
import { getSchedule } from 'API/emailNotificationSchedule';
import ConfirmDialog from 'SharedComponents/ConfirmDialog';
import {
  formatPrice, generateFile, getTotalPrice, getItemTotalPrice,
} from '~/consts';
import Dropdown from './components/Order/Dropdown.vue';
import InventoryModal from './components/Order/InventoryModal.vue';
import InventorySlideOut from './components/Order/InventorySlideOut.vue';

export default {
  name: 'OrderById',
  components: {
    Dropdown,
    InventoryModal,
    ConfirmDialog,
    InventorySlideOut,
  },
  props: {
    breadCrumbs: Array,
    order: Object,
  },
  data: () => ({
    defaultBrandName: 'Creed',
    itemsBreadcrumbs: [
      {
        text: 'Orders',
        to: '/orders',
        disabled: false,
      },
      {
        text: 'Create New Order',
        to: '/order/new',
        disabled: true,
      },
    ],
    waitingForCreate: false,
    isLoading: true,
    selectedEntity: {
      remainingCost: 0,
    },
    openModal: false,
    openSlideOut: false,
    currentOrder: null,
    orderFormValues: {
      brand: null,
      entity: null,
      Inventories: [],
      comments: '',
    },
    orderFormRules: {
      entityRules: [
        (v) => !!v || 'Selection required',
      ],
    },
    sortBy: 'itemCodeDesc',
    sortDesc: false,
    headers: [
      { text: 'Name', value: 'itemCodeDesc' },
      { text: 'SKU', value: 'itemCode' },
      { text: 'Quantity', value: 'quantity' },
      { text: 'Cost per Unit', value: 'lastTotalUnitCost', sortable: false },
      { text: 'Total Cost', value: 'totalCost', sortable: false },
    ],
    snackbar: {
      status: false,
      message: '',
      type: 'error',
    },
    formAddItem: {
      quantity: {},
      items: [],
    },
    confirmSubmit: false,
    confirmDelete: false,
    retailLabRegions: [],
    usersAndStores: [],
    brands: [],
    originalBrands: [],
    allInventory: [],
    currentMonthOrders: [],
    pastTwoWeeksOrders: [],
    regionsInventory: [],
    regionsAssociated: [],
    collateralDates: [],
    duplicatedOrderId: null,
  }),
  computed: {
    disableBrandSelection() {
      if (this.currentOrder?.id) {
        return true;
      }
      if (!this.orderFormValues.entity?.id) {
        return true;
      }
      return false;
    },
    usedInventoryQuantity() {
      if (this.selectedEntity.retailLabRegionId) {
        const collRetailLabRegionId = this.selectedEntity.retailLabRegionId;
        const otherOrdersInventoryUsed = this.currentMonthOrders.filter(
          ({
            Region: { id = '', retailLabRegionId = '' },
            brandId,
          }) => retailLabRegionId === collRetailLabRegionId
          && brandId === this.orderFormValues.brand.id
          && id !== this.orderFormValues?.entity?.id,
        ).flatMap(
          ({ Inventories }) => Inventories.map(
            ({
              OrdersInventory: { quantity } = {},
              id,
              lastTotalUnitCost,
            }) => ({ quantity, id, lastTotalUnitCost }),
          ),
        );
        const currOrderInventory = this.orderFormValues.Inventories.map(
          ({ id, quantity, lastTotalUnitCost }) => ({ id, quantity, lastTotalUnitCost }),
        );
        const inventoryUsed = this.selectedEntity?.isCollateralOverriden
          ? currOrderInventory
          : [].concat(currOrderInventory, otherOrdersInventoryUsed);
        const inventoryInOrders = [...new Set(inventoryUsed.map(({ id }) => id))]
          .map((id) => ({
            id,
            qty: inventoryUsed
              .filter(({ id: InventoryId }) => id === InventoryId)
              .map(({ quantity }) => quantity)
              .reduce((a, b) => a + b, 0),
            totalPrice: inventoryUsed
              .filter(({ id: InventoryId }) => id === InventoryId)
              .map((i) => parseFloat(i?.quantity).toFixed(2) * i?.lastTotalUnitCost)
              .reduce((a, b) => a + b, 0),
          }));

        return inventoryInOrders;
      }
      return [];
    },
    usedInventoryQuantityPerRegion() {
      if (this.currentMonthOrders) {
        const inventoryUsed = this.currentMonthOrders
          .filter(
            ({
              Region: { retailLabRegionId = '' },
            }) => [...this.regionsAssociated]
              .map(({ retailLabRegionId: id }) => id)
              .includes(retailLabRegionId),
          )
          .flatMap(
            ({ Inventories, Region }) => Inventories.map(
              ({
                OrdersInventory: { quantity } = {},
                id,
                lastTotalUnitCost,
              }) => ({
                quantity,
                id,
                lastTotalUnitCost,
                Region,
              }),
            ),
          );
        const inventoryInOrders = [...new Set(inventoryUsed.map(({ Region }) => Region.id))]
          .map((id) => ({
            Inventory:
              [...new Set(inventoryUsed
                .filter(({ Region: { id: regionId } }) => id === regionId)
                .map(({ id: regionId }) => regionId)),
              ]
                .map((itemId) => ({
                  id: itemId,
                  qty: inventoryUsed
                    .filter(({ id: InventoryId }) => itemId === InventoryId)
                    .map(({ quantity }) => quantity)
                    .reduce((a, b) => a + b, 0),
                })),
            ...inventoryUsed.find(({ Region }) => Region.id === id).Region,
          }));
        return inventoryInOrders;
      }
      return [];
    },
    availableCollateral() {
      return this.allInventory
        .filter((inventory) => {
          const regionFilter = inventory.Regions.length
            ? inventory.Regions.some(({ id: regionId }) => [...this.regionsAssociated]
              .some(({ id }) => id === regionId))
            : true;
          const regionCheck = inventory.Regions.map(({ id }) => id)
            .includes(this.orderFormValues.entity.id);
          return (
            (!inventory.Regions.length || regionCheck)
            && (inventory.qtyAvailable > 50 || regionFilter)
            && inventory.qtyAvailable > 0
          );
        })
        .map(({ itemCode }) => itemCode);
    },
  },
  watch: {
    orderFormValues: {
      deep: true,
      handler(val) {
        const entityId = val?.entity?.id;
        const selectedBrandId = val?.brand?.id;
        if (!entityId) return;

        if (entityId && selectedBrandId) {
          const {
            isDuplicated,
            duplicatedOrderId,
          } = this.isOrderDuplicate(entityId, selectedBrandId);
          if (isDuplicated) {
            this.duplicatedOrderId = duplicatedOrderId;
          } else {
            this.duplicatedOrderId = null;
          }
        }
        if (entityId) {
          const productLinesFromInvetory = this.allInventory
            .filter(({ Regions }) => Regions.map(({ id }) => id).includes(entityId))
            .map(({ productLine }) => productLine);

          const possibleBrands = this.originalBrands
            .filter(({ productLines }) => (
              productLines.some((productLine) => productLinesFromInvetory.includes(productLine))
            ))
            .map(({ name }) => name);

          const placedOrderBrandIds = this.currentMonthOrders.filter(
            ({ regionId }) => regionId === entityId,
          ).map(({ brandId }) => brandId);

          this.brands = possibleBrands.length
            ? this.originalBrands.filter(
              ({ name, id }) => possibleBrands.includes(name) && !placedOrderBrandIds.includes(id),
            )
            : this.originalBrands.filter(({ name }) => name === this.defaultBrandName);
        }

        const { brand: { productLines = [] } = {} } = val;

        const { collateralAssignment = [] } = this.retailLabRegions
          ?.flatMap(({ regions = [] }) => regions)
          ?.find(({ id }) => id === entityId);

        const {
          collateralAssignments = [],
          name = '',
        } = this.retailLabRegions
          .find(({ regions = [] }) => regions.find((r) => r?.id === entityId))
          || {};

        const collateral = collateralAssignment?.length
          ? collateralAssignment
          : collateralAssignments;

        const inventorySelection = this.allInventory.filter(({
          itemCode,
          productLine,
          Regions,
        }) => {
          const regionFilter = Regions.length > 0
            ? Regions.some(({ id }) => id === entityId)
            : true;
          return regionFilter && productLines.includes(productLine)
          && this.availableCollateral.includes(`${itemCode}`);
        });

        this.selectedEntity = {
          collateral,
          isCollateralOverriden: Boolean(collateralAssignment?.length),
          inventory: inventorySelection,
          name,
          ...this.orderFormValues.entity,
        };
        this.updateRemainingCost();
      },
    },
    waitingForCreate(waiting) {
      if (!waiting) {
        this.saveChanges();
      }
    },
  },
  methods: {
    formatPrice,
    getItemTotalPrice,
    getTotalPrice() {
      return getTotalPrice(this.orderFormValues.Inventories);
    },
    updateRemainingCost() {
      const remainingBudget = this.selectedEntity
        ?.collateral
        ?.find(
          ({ propData: { brandId = 0 } = {} }) => brandId === this.orderFormValues?.brand?.id,
        )
        ?.propData
        ?.maxCostAlloc * 1000;
      const dollarsSpentInCurrMonth = this.usedInventoryQuantity
        .map(({ totalPrice }) => totalPrice)
        .reduce((a, b) => a + b, 0);

      this.selectedEntity.remainingCost = remainingBudget - dollarsSpentInCurrMonth;
    },
    clickPDF() {
      window.print();
    },
    clickPrint() {
      window.print();
    },
    clickExport() {
      if (!this.currentOrder) return;
      const storeOrder = this.currentOrder?.Inventories.map((value) => ({
        'Order Id': this.currentOrder?.id,
        'Item Name': value?.itemCodeDesc,
        'Item SKU': value?.itemCode,
        Quantity: value?.OrdersInventory?.quantity,
      }));
      generateFile({
        data: storeOrder,
        sheetName: `COLL-${this.currentOrder?.Region?.storeNo}`,
        fileName: `COLL-${this.currentOrder?.Region?.storeName}.xlsx`,
      });
    },
    async getStores(jwt) {
      const { data = [] } = await getStores(jwt);
      return data;
    },
    async getRegions(jwt) {
      const { data = [] } = await getRegions(jwt);
      return data;
    },
    async getInventoryList(jwt) {
      const { data = [] } = await getInventoryList(jwt);
      return data;
    },
    async getBrands(jwt) {
      const { data = [] } = await getBrands(jwt);
      return data;
    },
    async getOrder(jwt, id) {
      const { data = [] } = await getOrder(jwt, id);
      return data;
    },
    async getCurrentMonthOrders(jwt) {
      const { data: { orders = [] } } = await getOrders(jwt, {
        dateStart: this.collateralDates[0],
        dateEnd: new Date(),
      });

      return orders;
    },
    async getPastTwoWeeksOrders(jwt) {
      const { data: { orders = [] } } = await getOrders(jwt, {
        dateStart: sub(new Date(), { days: 14 }),
        dateEnd: new Date(),
      });
      return orders;
    },
    async saveChanges({ updatedQuantity = false } = {}) {
      if (!this.selectedEntity?.id) {
        return;
      }

      if (this.waitingForCreate) {
        return;
      }

      if (this.selectedEntity.remainingCost < 0) {
        return;
      }

      this.isLoading = true;

      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();

      const {
        entity: { id: regionId = '' } = {},
        Inventories = [],
        comments = '',
        brand: { id: brandId } = {},
      } = this.orderFormValues;

      const data = {
        id: this?.currentOrder?.id,
        regionId,
        Inventories,
        comments,
        brandId,
      };

      try {
        if (!data.id) {
          this.waitingForCreate = true;
        }

        const { data: { id } } = data.id
          ? await updateOrder(jwt, data.id, data)
          : await createOrder(jwt, data);

        this.waitingForCreate = false;
        this.currentOrder = { id };

        if (this.duplicatedOrderId) {
          this.snackbar = {
            status: true,
            type: 'error',
            message: `Order is duplicated with #${this.duplicatedOrderId}`,
          };
        } else {
          this.snackbar = {
            status: true,
            type: 'blue-grey darken-4',
            message: data.id ? 'Order Saved' : 'Order Created',
          };
        }

        if (!updatedQuantity) {
          this.currentOrder = await this.getOrder(jwt, id);
          await this.setupOrder(this.currentOrder);
        }
      } catch (error) {
        this.snackbar = {
          status: true,
          type: 'error',
          message: error,
        };
      } finally {
        this.isLoading = false;
        this.confirmSubmit = false;
      }
    },
    async deleteOrder() {
      if (!this?.order?.id) {
        return;
      }

      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        await deleteOrder(jwt, this.order.id);
        this.snackbar = {
          status: true,
          type: 'success',
          message: 'Order Deleted',
        };
        this.$router.push({ path: '/orders' });
      } catch (error) {
        this.snackbar = {
          status: true,
          type: 'error',
          message: error,
        };
      } finally {
        this.isLoading = false;
        this.confirmDelete = false;
      }
    },
    async submitChanges() {
      await this.saveChanges();

      if (!this.order?.id && this.currentOrder?.id) {
        this.$router.push({ path: '/orders', params: { id: this.currentOrder.id } });
      }
    },
    async updateInventoryQuantity(item, callback) {
      const currFormInventory = this.orderFormValues.Inventories;
      this.orderFormValues.Inventories = [].concat(
        ...currFormInventory.filter((i) => i.id !== item.InventoryId),
        [item],
      ).filter((i) => i.quantity > 0);
      await this.saveChanges({ updatedQuantity: true });
      this.updateRemainingCost();
      callback();
    },
    getItemRegionText(item) {
      return item?.storeNo
        ? `${item?.storeNo} -
          ${item?.retailer?.name} -
          ${item?.storeName} -
          ${item?.regionalMarket?.name} -
          ${item?.nsdRegion?.name}`
        : '';
    },
    getBrandText(brand) {
      return brand?.name;
    },
    getSelectedBrandName() {
      return this.orderFormValues?.brand?.name || '';
    },
    async setupOrder(order) {
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const [
        { retailLabRegions = [] },
        brands,
        allInventory,
        { data: schedules },
      ] = await Promise.all([
        this.getRegions(jwt),
        this.getBrands(jwt),
        this.getInventoryList(jwt),
        getSchedule(jwt),
      ]);
      const schedulesSorted = schedules
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map(({ date }) => date);
      this.collateralDates = [
        startOfDay(new Date(schedulesSorted[0])),
        endOfDay(new Date(schedulesSorted[schedulesSorted.length - 1])),
      ];
      this.retailLabRegions = retailLabRegions;
      this.brands = brands;
      this.originalBrands = brands;
      this.allInventory = allInventory;
      this.currentMonthOrders = await this.getCurrentMonthOrders(jwt);
      this.pastTwoWeeksOrders = await this.getPastTwoWeeksOrders(jwt);
      this.regionsAssociated = retailLabRegions
        .flatMap((r) => r.regions)
        .filter(({ id }) => {
          const productLinesFromInvetory = this.allInventory
            .filter(({ Regions }) => Regions.map(({ id: regionId }) => regionId).includes(id))
            .map(({ productLine }) => productLine);

          const brandValues = this.originalBrands
            .filter(({ productLines }) => (
              productLines.some((productLine) => productLinesFromInvetory.includes(productLine))
            ))
            .map(({ name }) => name);

          const regionOrders = this.currentMonthOrders.filter(
            ({ regionId }) => regionId === id,
          ) || [];

          return !(regionOrders.length && regionOrders.length === brandValues.length);
        })
        .sort((a, b) => a.retailer.name.localeCompare(b.retailer.name));

      if (order) {
        this.usersAndStores = [order?.Region];
        this.orderFormValues = {
          brand: order?.Brand,
          entity: order?.Region,
          Inventories: order?.Inventories.map(
            ({ OrdersInventory: { quantity = 0 } = {}, ...item }) => ({ quantity, ...item }),
          ),
          comments: order?.comments,
        };
      } else {
        this.orderFormValues.brand = this.brands.find(
          (brand) => brand.name === this.defaultBrandName,
        );

        this.usersAndStores = this.regionsAssociated;
      }
      this.regionsInventory = this.allInventory.filter(({
        Regions,
      }) => {
        const regionFilter = Regions.length > 0
          ? Regions.some(({ id: regionId }) => [...this.regionsAssociated]
            .some(({ id }) => id === regionId))
          : true;
        return regionFilter;
      });
    },
    isOrderDuplicate(newRegionId, newBrandId) {
      const order = this.pastTwoWeeksOrders.find(({
        brandId, regionId,
      }) => brandId === newBrandId && regionId === newRegionId);
      return {
        isDuplicated: !this.order?.id && Boolean(order?.id),
        duplicatedOrderId: order?.id,
      };
    },
  },
  async mounted() {
    try {
      this.currentOrder = this.order;
      await this.setupOrder(this.currentOrder);
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
