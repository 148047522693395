<template>
  <v-card class="px-10 py-5">
    <v-card-title class="headline">
      Create account type
    </v-card-title>
    <v-progress-circular
      v-if="isLoading"
      :size="60"
      :width="8"
      class="d-block mx-auto mt-16"
      indeterminate
      color="primary"
    />
    <v-form
      v-if="!isLoading"
      ref="form"
      v-model="formAccountType.valid"
    >
      <v-col>
        <v-text-field
          v-model="formAccountType.name"
          :rules="formAccountType.nameRules"
          label="Account Type Name"
          required
        />
      </v-col>
      <v-card-actions>
        <v-spacer />
        <v-btn color="red darken-2" text :disabled="isLoading" @click="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          color="light-blue darken-2"
          :disabled="isLoading"
          text
          @click="doCreateAccountType"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { createAccountType } from 'API/account-type';

export default {
  data: () => ({
    isLoading: false,
    formAccountType: {
      valid: true,
      name: '',
      nameRules: [
        (v) => !!v || 'Full Name is required',
      ],
    },
    error: {
      status: false,
      message: '',
    },
  }),
  methods: {
    async doCreateAccountType() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const body = {
        name: this.formAccountType.name,
      };

      try {
        const res = await createAccountType(jwt, body);
        this.$emit('created', res.data);
        this.$emit('close');
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
