import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getAccountTypes = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/accountTypes`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getAccountType = (token, accountTypeId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/accountTypes/${accountTypeId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createAccountType = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/accountTypes`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateAccountType = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/accountTypes/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteAccountType = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/accountTypes/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
