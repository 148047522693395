var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders[_vm.tab.toLowerCase()],"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort,"items-per-page":_vm.pageSize,"hide-default-footer":"","loading":_vm.isLoading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.tab)+" Orders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Order Id, User/Email or Date","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.tab === 'All')?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('router-link',{attrs:{"to":"/order/new"}},[_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":"","small":"","color":"light-blue darken-2"}},[_vm._v(" New Order ")])],1)],1)]},proxy:true},{key:"item.Region.storeName",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.Region.storeNo))]),_vm._v(" "+_vm._s(item.Region.retailer.name)+" - "+_vm._s(item.Region.storeName)+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/orders/' + item.id}},[_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":"","small":"","color":"light-blue darken-2"}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"item.submittedOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.submittedOn).toDateString()))])]}},{key:"item.modifiedOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.modifiedOn ? new Date(item.modifiedOn).toDateString() : '-'))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","outlined":"","x-small":"","color":"red darken-2","aria-label":"Delete Order"},on:{"click":function($event){return _vm.deleteOrderClicked(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Order")])])]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('v-pagination',{attrs:{"value":_vm.currentPage,"length":_vm.pageCount},on:{"input":_vm.onSelectPage}}),_c('v-text-field',{attrs:{"value":_vm.pageSize,"label":"Items per page","type":"number","min":"1","max":"200"},on:{"input":_vm.onChangeItemsPerPage}})],1),_c('confirm-dialog',{attrs:{"persistent":_vm.isLoading,"title":"Are you sure you want to delete this order?"},on:{"cancel":function($event){_vm.confirmDelete = false},"confirm":_vm.deleteOrder},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.type,"centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.status = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar.status),callback:function ($$v) {_vm.$set(_vm.snackbar, "status", $$v)},expression:"snackbar.status"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }