<template>
  <div>
    <v-tabs align="right">
      <v-spacer />
      <v-btn
        @click="download"
        :disabled="!orders.all || isLoading"
        tile
        color="primary"
        class="m-4">
        <v-icon left>
          mdi-download
        </v-icon>
        Download CSV
      </v-btn>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs v-model="tab" grow>
      <v-tab
        v-for="tab in tabs"
        :key="tab">{{ tab }} Orders</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="tab in tabs"
        :key="tab">
          <OrdersTable
            v-if="tab !== 'Batch'"
            :headers="headers"
            :orders="orders"
            :isLoading="isLoading"
            :pageSize="pageSize"
            :currentPage="currentPage"
            :pageCount="pageCount"
            :onSelectPage="onSelectPage"
            :searchText="searchText"
            :onChangeItem="onChangeItem"
            :tab="tab"/>
          <BatchOrdersTable
            v-if="tab === 'Batch' && batchOrders.length"
            :headers="headers"
            :batchOrders="orders.batch"
            :isLoading="isLoading"
            :tab="tab"/>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

import {
  getPaginatedOrders,
  getOrders,
} from 'API/order';
import {
  getStatusOptions,
} from 'API/status';
import { downloadCSV } from 'API/downloadCSV';
import OrdersTable from './OrdersTable.vue';
import BatchOrdersTable from './BatchOrdersTable.vue';

export default {
  name: 'Orders',
  components: {
    OrdersTable,
    BatchOrdersTable,
  },
  data: () => ({
    tabs: [
      'All',
      'Pending',
      'Past',
      'Batch',
    ],
    headers: [
      { text: 'User', value: 'User.fullName' },
      { text: 'Store Name', value: 'Region.storeName' },
      { text: 'User Email', value: 'User.email', align: ' d-none' },
      { text: 'Order Id', value: 'id' },
      { text: 'Submitted On', value: 'submittedOn' },
      { text: 'Brand', value: 'brand.name' },
      { text: 'Delete', value: 'delete', sortable: false },
    ],
    orders: {
      all: [],
      pending: [],
      past: [],
    },
    batchOrders: [],
    currentPage: 1,
    searchText: '',
    pageSize: 50,
    pageCount: 10,
    sortBy: 'submittedOn',
    sortDesc: true,
    isLoading: true,
    error: {
      status: false,
      message: '',
    },
    allStatus: getStatusOptions(),
  }),
  methods: {
    async download() {
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const res = await getOrders(jwt);
      downloadCSV({
        elements: res.data.orders,
        name: 'Orders',
      });
    },
    async getAllOrders({
      jwt,
      params,
    }) {
      const { data: { orders, totalPages } } = await getPaginatedOrders(jwt, params);
      this.pageCount = totalPages;
      const formatOrder = (({ Brand: brand = {}, ...data }) => ({ brand, ...data }));
      this.orders.all = orders.map(formatOrder);
      this.orders.pending = orders.filter(({ status }) => status === 'Pending').map(formatOrder);
      this.orders.past = orders.filter(
        ({ deadlineDate }) => new Date(deadlineDate) < new Date(),
      ).map(formatOrder);

      if (!this.batchOrders.length) {
        this.tabs = this.tabs.filter((t) => t !== 'Batch');
      } else {
        this.orders.batch = this.batchOrders;
      }
    },
    async onSelectPage(pageNumber) {
      this.currentPage = pageNumber;
      this.onFetchOrders();
    },
    onChangeItem(itemKey, itemValue) {
      this[itemKey] = itemValue;
      this.onFetchOrders();
    },
    async onFetchOrders() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      await this.getAllOrders({
        jwt,
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          searchText: this.searchText,
          sortBy: this.sortBy,
          sortDir: this.sortDesc ? 'DESC' : 'ASC',
        },
      });
      this.isLoading = false;
    },
  },
  computed: {
    tab: {
      get() {
        const { tab } = this.$route.query;
        if (!tab) return 0;
        return this.tabs.findIndex((item) => item.toLowerCase() === tab);
      },
      set(value) {
        if (value === 0) {
          this.$router.replace({ query: {} });
        } else {
          const tab = this.tabs[value].toLowerCase();
          this.$router.replace({ query: { tab } });
        }
      },
    },
  },
  async mounted() {
    try {
      await this.onFetchOrders();
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
