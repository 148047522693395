<template>
  <v-data-table
    :headers="headers"
    :items="entries"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn @click.native="addNewRow" color="primary" plain x-small>New Entry</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.week="props">
        <v-edit-dialog
          :return-value.sync="props.item.week"
          @close="save"
        >
          <span v-if="!!props.item.week">{{ props.item.week }}</span>
          <small class="grey--text" v-else>Week Number</small>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Week Number
            </div>
            <v-text-field
              v-model="props.item.week"
              label="Edit"
              single-line
              type="number"
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
    </template>
    <template v-slot:item.note="props">
        <v-edit-dialog
          :return-value.sync="props.item.note"
          large
          @close="save"
          @save="save"
        >
          <span v-if="!!props.item.note">{{ props.item.note }}</span>
          <small class="grey--text" v-else>Note</small>
          <template v-slot:input>
            <div class="mt-4 text-h6">
              Update Note
            </div>
            <v-textarea
              v-model="props.item.note"
              label="Edit"
              :rules="ruleTextarea"
            ></v-textarea>
          </template>
        </v-edit-dialog>
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    notes: Array,
  },
  data() {
    return {
      date: new Date(),
      headers: [
        { text: 'Week#', value: 'week', sortable: false },
        { text: 'Notes', value: 'note', sortable: false },
      ],
      entries: [],
      ruleTextarea: [(v) => (v || '').length <= 2000 || 'Description must be 2000 characters or less'],
    };
  },
  mounted() {
    if (this.notes && this.notes.length) {
      this.entries = this.notes;
    }
  },
  methods: {
    addNewRow() {
      this.entries = [].concat(
        this.entries,
        [{
          week: null,
          note: null,
        }],
      );
    },
    save() {
      const entries = this.entries.filter(({
        week = null,
        note = null,
      }) => !!week && !!note);
      if (entries.length) {
        this.$emit('updateNotes', entries);
      }
    },
  },
};
</script>
