<template>
  <v-card class="pa-6">
    <v-card-title class="headline">Associate Stores</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-select
            :items="regions"
            v-model="selectedRetailLabRegionId"
            placeholder="Select Region"
            outlined
            dense
            clearable
            single-line
            item-text="retailLabRegion.name"
            item-value="retailLabRegionId"
            @change="resetSelectedRetailLabRegionId"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :items="regions"
            v-model="selectedRetailerId"
            placeholder="Select Retailer"
            outlined
            dense
            clearable
            single-line
            :item-text="printRetailer"
            item-value="retailerId"
            @change="resetSelectedRetailerId"
          ></v-select
        ></v-col>
        <v-col cols="6" class="d-flex justify-end align-baseline">
          <v-btn
            tile
            color="primary"
            class="mx-4"
            :disabled="isLoading"
            @click="selectAll"
          >
            Select All
          </v-btn>
          <v-btn
            color="light-blue darken-2"
            outlined
            :disabled="isLoading"
            @click="deselectAll"
          >
            Deselect All
          </v-btn>
          <p class="ml-2 items-selected">
            Items Seleted:
            <span v-if="selectedRegions.length">{{
              selectedRegions.length
            }}</span>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headersInventory"
      :items="filteredRegions"
      :loading="isLoading || isLoadingFromInventory"
      v-model="selectedRegions"
      show-select
      class="mx-6 regions-table"
    />
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-2"
        text
        :disabled="isLoading || isLoadingFromInventory"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      <v-btn
        color="light-blue darken-2"
        :disabled="isLoading || isLoadingFromInventory"
        text
        @click="$emit('update', $event, selectedRegions)"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getStoreMatrixRegions } from 'API/region';
import { getRetailers } from 'API/retailer';

export default {
  name: 'AssociateRegionForm',
  props: {
    inventoriesSelected: {
      type: Array,
      default: () => [],
    },
    isLoadingFromInventory: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    headersInventory: [
      { text: 'Region', value: 'retailLabRegion.name' },
      { text: 'Retailer', value: 'retailer.name' },
      { text: 'Store Number', value: 'storeNo' },
      { text: 'Store Name', value: 'storeName' },
    ],
    regions: [],
    selectedRegions: [],
    selectedRetailLabRegionId: undefined,
    selectedRetailerId: undefined,
    error: {
      status: false,
      message: '',
    },
  }),
  computed: {
    filteredRegions: {
      get() {
        if (this.selectedRetailLabRegionId && this.selectedRetailerId) {
          return this.regions.filter(
            (region) => region.retailLabRegionId === this.selectedRetailLabRegionId
              && region.retailerId === this.selectedRetailerId,
          );
        }

        if (this.selectedRetailLabRegionId) {
          return this.regions.filter(
            (region) => region.retailLabRegionId === this.selectedRetailLabRegionId,
          );
        }

        if (this.selectedRetailerId) {
          return this.regions.filter(
            (region) => region.retailerId === this.selectedRetailerId,
          );
        }

        return this.regions;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    printRetailer(region) {
      return (
        this.retailers.find((retailer) => retailer.id === region?.retailerId)
          ?.name || ''
      );
    },
    resetSelectedRetailLabRegionId(id) {
      if (!id) {
        this.selectedRetailLabRegionId = undefined;
      }
    },
    resetSelectedRetailerId(id) {
      if (!id) {
        this.selectedRetailerId = undefined;
      }
    },
    selectAll() {
      this.selectedRegions = this.filteredRegions;
    },
    deselectAll() {
      this.selectedRegions = [];
    },
  },
  async created() {
    try {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const [regionsResponse, retailersResponse] = await Promise.all([
        getStoreMatrixRegions(jwt),
        getRetailers(jwt),
      ]);
      this.regions = regionsResponse.data;
      this.filteredRegions = regionsResponse.data;
      this.retailers = retailersResponse.data;

      const selectedRegions = [];
      this.filteredRegions.forEach((region) => {
        const checkRegion = this.inventoriesSelected.map((inventory) => inventory.Regions
          .some((regionSelected) => regionSelected.id === region.id))
          .every((value) => value);

        if (checkRegion) {
          selectedRegions.push(region);
        }
      });
      this.selectedRegions = selectedRegions;
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
.items-selected {
  min-width: 120px;
}

.regions-table {
  border: thin solid #0000001f;
}

.regions-table th,
.regions-table td {
  border-left: thin solid #0000001f;
}

.regions-table th:first-child,
.regions-table td:first-child {
  border-left: none;
}
</style>
