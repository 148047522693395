import XLSX from 'xlsx';
import { add, format } from 'date-fns';

export const availableMonths = [
  { key: 1, value: 'JANUARY' },
  { key: 2, value: 'FEBRUARY' },
  { key: 3, value: 'MARCH' },
  { key: 4, value: 'APRIL' },
  { key: 5, value: 'MAY' },
  { key: 6, value: 'JUNE' },
  { key: 7, value: 'JULY' },
  { key: 8, value: 'AUGUST' },
  { key: 9, value: 'SEPTEMBER' },
  { key: 10, value: 'OCTOBER' },
  { key: 11, value: 'NOVEMBER' },
  { key: 12, value: 'DECEMBER' },
];

export const fiscalYears = Array.from({ length: 10 }, (v, i) => {
  const date = add(new Date(), { years: i - 5 });
  const correspondingDate = add(new Date(), { years: i - 4 });
  return {
    value: format(date, 'yyyy'),
    label: `${format(date, 'yyyy')}-${format(correspondingDate, 'yyyy')}`,
  };
});

export const formatPercent = (number) => `${parseFloat(number).toFixed(2)}%`;

export const formatPrice = (number = 0) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return number && typeof parseFloat(number) === 'number'
    ? currencyFormatter.format(number)
    : 0;
};

export const generateFile = ({ data, sheetName, fileName }) => {
  const xlData = XLSX.utils.json_to_sheet(data);
  const xlBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(xlBook, xlData, sheetName);
  return XLSX.writeFile(xlBook, fileName);
};

// TODO Add to Database once we get PO and Department value
export const adminExportObj = [
  { value: 'HOLTREN', key: 'HOLT RENFREW-C', dn: '25' },
  { value: 'NORDCAN', key: 'NORDSTROM-C', dn: '25' },
  { value: 'DILLARD', key: 'DILLARDS', dn: '41' },
  { value: 'NEIMANG', key: 'NEIMAN MARCUS', dn: '49' },
  { value: 'NORDSTR', key: 'NORDSTROM', dn: '50' },
  { value: 'VONMAUR', key: 'VON MAUR', dn: '53' },
  { value: 'COSBAR', key: 'COS BAR', dn: '54' },
  { value: 'HARRY', key: 'HARRY ROSEN', dn: '25' },
  { value: 'SAKSCAN', key: 'SAKS FIFTH AVENUE-C', dn: '25' },
  { value: 'SAKS', key: 'SAKS FIFTH AVENUE', dn: '51' },
  { value: 'CREED', key: 'CREED BOUTIQUES', dn: '65' },
  { value: 'NEIMANG', key: 'BERGDORF GOODMAN', dn: '49' },
  { value: 'MACYBLO', key: "BLOOMINGDALE'S", dn: '42' },
];

export function getItemTotalPrice({ lastTotalUnitCost, quantity }) {
  return lastTotalUnitCost * quantity;
}

export function getTotalPrice(inventory) {
  return inventory.map(getItemTotalPrice).reduce((a, b) => a + b, 0);
}

export const availableCollateral = [
  /* Creed */
  '5607502',
  '5607503',
  '5607505',
  '5610039',
  '5610043',
  '5610062',
  '5607510',
  '5607517',
  '5607552',
  '5607554',
  '5607556',
  '5607560',
  '5607561',
  '5607563',
  '5607564',
  '5607566',
  '5607568',
  '5607576',
  '5610015',
  '5610016',
  '5610030',
  '5610031',
  '5610032',
  '5610033',
  '5610034',
  '5610035',
  '5610036',
  '5610038',
  '5610040',
  '5610041',
  '5610042',
  '5610046',
  '5610048',
  '5610049',
  '5610095',
  '5610096',
  '5610097',
  '5610098',
  '5607570',
  '9001953',
  '9000415',
  '9007954',
  '9007953',
  '1400001',
  '1400002',
  '9001954',
  '9019003',
  '9019004',
  '19030',
  '9011900',
  '9011963',
  '9011966',
  '9011997',
  '9011942',
  '9011932',
  '9011939',
  '9011961',
  '9011933',
  '9011941',
  '9011940',
  '9011943',
  '9011964',
  '9011901',
  '9011956',
  '9011935',
  '9011902',
  '9011996',
  '9011962',
  '9011905',
  '9001956',
  '9001955',
  '19070',
  '9001900',
  '9001925',
  '9001927',
  '9001942',
  '9001949',
  '9001950',
  '9001951',
  'PO015AR',
  'PO015OR',
  'PO050AR',
  'PO050OR',
  'FL015',
  'FL050',
  '9011998',
  '9001952',
  /* Mizensir */
  'MIZFYL100T',
  'MIZEDG100T',
  'MIZIDO100T',
  'MIZLUX100T',
  'MIZMET100T',
  'MIZPOU100T',
  'MIZTRC100T',
  'MIZCDF100T',
  'MIZMYV100T',
  'PI.TB',
  'GR.TB',
  'MIZREX100T',
  'PM.TB',
  'FO.TB',
  'VM.TB',
  'FS.TB',
  'GJ.TB',
  'PM.TMB',
  'MIZBDM100T',
  'MIZCEW100T',
  'MIZSWP100T',
  'MIZCDM100T',
  'BT.TB',
  'AO.TB',
  'CF.TB',
  'FV.TB',
  'RB.TB',
  'PI.TMB',
  'BT.TMB',
  'CF.TMB',
  'FO.TMB',
  'VM.TMB',
  'MIZBLOTTERS',
  'MIZBAGCUBE',
  'MIZBAGSMALL',
  'MIZFYL2S',
  'MIZEDG2S',
  'MIZTRC2S',
  'MIZLUX2S',
  'MIZPOU2S',
  'MIZBDM2S',
  'MIZCEW2S',
  'MIZCDF2S',
  'MIZCDM2S',
  'MIZIDO2S',
  'MIZMET2S',
  'MIZMYV2S',
  'MIZREX2S',
  'MIZSWP2S',
  'MIZBAGMEDIUM',
  'MIZRIBRIBGGL',
  'MIZRIBRIBGGT',
  'MIZRIBRIBSSL',
  'MIZRIBRIBSST',
  'BAR.TB',
  'BO.TB',
  'CL.TB',
  'EA.TB',
  'FN.TB',
  'SN.TB',
  'MIZPPS',
  /* Floris */
  '05891',
  '06891',
  '09891',
  '12891',
  '30891',
  '31891',
  '32891',
  '33891',
  '37891',
  '39891',
  '40891',
  '45891',
  '46891',
  '47891',
  '48891',
  '50891',
  '51891',
  '68891',
  '74891',
  '76891',
  '77891',
  '78891',
  '92891',
  '9005005',
  '04895',
  '05882',
  '05883',
  '05884',
  '05886',
  '05895',
  '06895',
  '09883',
  '09884',
  '09885',
  '09886',
  '09894',
  '09895',
  '09896',
  '09897',
  '10882',
  '10886',
  '12895',
  '13882',
  '20895',
  '30881',
  '30883',
  '30886',
  '30887',
  '30890',
  '30895',
  '31881',
  '31883',
  '31887',
  '31888',
  '31890',
  '31895',
  '32881',
  '32895',
  '33895',
  '36896',
  '36897',
  '37895',
  '39895',
  '40895',
  '45895',
  '46895',
  '47895',
  '48895',
  '50895',
  '51883',
  '51884',
  '51895',
  '53895',
  '54895',
  '55895',
  '59896',
  '59897',
  '68895',
  '74895',
  '75895',
  '76895',
  '77895',
  '78895',
  '89517',
  '89529',
  '92883',
  '92884',
  '92886',
  '92895',
  '88001',
  '87101',
  '87183',
  '87161',
  '87162',
];
