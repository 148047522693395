<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        <v-breadcrumbs divider=">" :items="itemsBreadcrumbs">
          <template v-slot:item="{ item }">
            <router-link
              :to="item.to"
              class="text-decoration-none"
              :class="item.disabled ? 'text text--disabled' : ''"
            >
              {{ item.text }}
            </router-link>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-form ref="form" v-model="regionForm.valid">
        <v-container>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Retailer:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.retailerId"
                :items="retailers"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="retailerCreateModalOpened = true"
                    aria-label="Add Retailer"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Retailer
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Store type:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.storeTypeId"
                :items="storeTypes"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="storeTypeCreateModalOpened = true"
                    aria-label="Add Store Type"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Store Type
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Account type:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.accountTypeId"
                :items="accountTypes"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="accountTypeCreateModalOpened = true"
                    aria-label="Add Account Type"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Account Type
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Store no:
              </v-card-subtitle>
              <v-text-field v-model="regionForm.storeNo" required />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Store Name:
              </v-card-subtitle>
              <v-text-field v-model="regionForm.storeName" required />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Retail Lab Door:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.retailLabRegionId"
                :items="retailLabRegions"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="retailLabRegionIdCreateModalOpened = true"
                    aria-label="Add Retail Lab Door"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Retail Lab Door
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                NSD Region:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.nsdRegionId"
                :items="nsdRegions"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="nsdRegionCreateModalOpened = true"
                    aria-label="Add NSD Region"
                  >
                    <v-icon left>mdi-plus</v-icon>Add NSD Region
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Senior Regional Sales Manager Market:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.seniorRegionalSalesManagerMarketId"
                :items="regionalMarkets"
                item-text="name"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="regionalMarketCreateModalOpened = true"
                    aria-label="Add Regional Market"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Regional Market
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Market:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.cityId"
                :items="cities"
                :item-text="getNameTextUpperCase"
                item-value="id"
                required
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="marketCreateModalOpened = true"
                    aria-label="Add Market"
                  >
                    <v-icon left>mdi-plus</v-icon>Add market
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Mall:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.mallId"
                :items="availableMalls"
                :item-text="getNameTextUpperCase"
                item-value="id"
              >
                <template slot="append-outer">
                  <v-btn
                    color="primary"
                    @click="mallCreateModalOpened = true"
                    aria-label="Add Mall"
                  >
                    <v-icon left>mdi-plus</v-icon>Add Mall
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Address:
              </v-card-subtitle>
              <v-text-field
                v-model="regionForm.location"
                placeholder="45 W 25th St 7th Floor, New York, NY 10001">
              </v-text-field>
              <!-- <address-search v-model="regionForm.location" label="Enter store address" /> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Brand:
              </v-card-subtitle>
              <v-combobox
                multiple
                deletable-chips
                chips
                class="input-quantity"
                v-model="regionForm.brandIds"
                :items="availableBrands"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" cols="12" class="d-md-flex">
              <v-card-subtitle>
                Door:
              </v-card-subtitle>
              <v-autocomplete
                class="input-quantity"
                v-model="regionForm.doorId"
                :items="doors"
                item-text="status"
                item-value="id"
                required
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="submit"
          :disabled="isLoading || !regionForm.valid"
          tile
          color="success"
          class="mx-4"
        >
          <v-icon left>
            mdi-check-circle
          </v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="retailerCreateModalOpened" max-width="500" :persistent="isLoading">
      <retailer-form
        v-if="retailerCreateModalOpened"
        @close="retailerCreateModalOpened = false"
        @created="onRetailerCreated"
      />
    </v-dialog>
    <v-dialog v-model="accountTypeCreateModalOpened" max-width="500" :persistent="isLoading">
      <account-type-form
        v-if="accountTypeCreateModalOpened"
        @close="accountTypeCreateModalOpened = false"
        @created="onAccountTypeCreated"
      />
    </v-dialog>
    <v-dialog v-model="retailLabRegionIdCreateModalOpened" max-width="500" :persistent="isLoading">
      <retail-lab-region-form
        v-if="retailLabRegionIdCreateModalOpened"
        @close="retailLabRegionIdCreateModalOpened = false"
        @created="onRetailLabRegionCreated"
      />
    </v-dialog>
    <v-dialog v-model="nsdRegionCreateModalOpened" max-width="500" :persistent="isLoading">
      <nsd-region-form
        v-if="nsdRegionCreateModalOpened"
        @close="nsdRegionCreateModalOpened = false"
        @created="onNSDRegionCreated"
      />
    </v-dialog>
    <v-dialog v-model="regionalMarketCreateModalOpened" max-width="500" :persistent="isLoading">
      <regional-market-form
        v-if="regionalMarketCreateModalOpened"
        @close="regionalMarketCreateModalOpened = false"
        @created="onRegionalMarketCreated"
      />
    </v-dialog>
    <v-dialog v-model="marketCreateModalOpened" max-width="500" :persistent="isLoading">
      <market-form
        v-if="marketCreateModalOpened"
        @close="marketCreateModalOpened = false"
        @created="onMarketCreated"
      />
    </v-dialog>
    <v-dialog v-model="mallCreateModalOpened" max-width="500" :persistent="isLoading">
      <mall-form
        v-if="mallCreateModalOpened"
        @close="mallCreateModalOpened = false"
        @created="onMallCreated"
      />
    </v-dialog>
    <v-dialog v-model="storeTypeCreateModalOpened" max-width="500" :persistent="isLoading">
      <store-type-form
        v-if="storeTypeCreateModalOpened"
        @close="storeTypeCreateModalOpened = false"
        @created="onStoreTypeCreated"
      />
    </v-dialog>
    <snack-bar
      :status="snackBar.status"
      :message="snackBar.message"
      :type="snackBar.type" />
  </div>
</template>
<script>
import { updateRegion, getRegion } from 'API/region';
import { getRetailers } from 'API/retailer';
import { getAccountTypes } from 'API/account-type';
import { getNSDRegions } from 'API/nsd-region';
import { getRegionalMarkets } from 'API/regional-market';
import { getStoreTypes } from 'API/store-type';
import { getRegions } from 'API/retailLabRegion';
import { getCities } from 'API/city';
import { getMalls } from 'API/mall';
import { getBrands } from 'API/brands';
import { getDoors } from 'API/doors';
import AccountTypeForm from '~/views/Admin/components/Regions/AccountTypeForm.vue';
import NsdRegionForm from '~/views/Admin/components/Regions/NsdRegionForm.vue';
import RegionalMarketForm from '~/views/Admin/components/Regions/RegionalMarketForm.vue';
import RetailerForm from '~/views/Admin/components/Regions/RetailerForm.vue';
import StoreTypeForm from '~/views/Admin/components/Regions/StoreTypeForm.vue';
// import AddressSearch from '~/components/AddressSearch/AddressSearch.vue';
import RetailLabRegionForm from '~/views/Admin/components/Regions/RetailLabRegionForm.vue';
import MarketForm from '~/views/Admin/components/Regions/MarketForm.vue';
import MallForm from '~/views/Admin/components/Regions/MallForm.vue';
import SnackBar from '~/views/Admin/components/SnackBar.vue';

export default {
  name: 'RegionById',
  components: {
    RetailerForm,
    AccountTypeForm,
    NsdRegionForm,
    RegionalMarketForm,
    StoreTypeForm,
    // AddressSearch,
    RetailLabRegionForm,
    MarketForm,
    MallForm,
    SnackBar,
  },
  data() {
    return {
      isLoading: false,
      snackBar: {
        message: '',
        status: false,
        type: 'error',
      },
      regionForm: {
        valid: true,
        retailerId: null,
        storeTypeId: null,
        accountTypeId: null,
        storeNo: '',
        storeName: '',
        nsdRegionId: null,
        seniorRegionalSalesManagerMarketId: null,
        cityId: null,
        mallId: null,
        location: null,
        brandIds: [],
        doorId: null,
      },
      retailers: [],
      accountTypes: [],
      nsdRegions: [],
      regionalMarkets: [],
      storeTypes: [],
      availableMalls: [],
      availableBrands: [],
      doors: [],
      itemsBreadcrumbs: [
        {
          text: 'Doors',
          to: '/regions',
          disabled: false,
        },
        {
          text: `Door ${this.$route.params.id}`,
          to: `/regions/${this.$route.params.id}`,
          disabled: true,
        },
      ],
      origin: { path: '/regions' },
      retailLabRegionId: null,
      retailLabRegions: [],
      cities: [],
    };
  },
  computed: {
    retailerCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createRetailer';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createRetailer' : undefined },
        });
      },
    },
    accountTypeCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createAccountType';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createAccountType' : undefined },
        });
      },
    },
    retailLabRegionIdCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createRetailLabRegion';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createRetailLabRegion' : undefined },
        });
      },
    },
    nsdRegionCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createNSDRegion';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createNSDRegion' : undefined },
        });
      },
    },
    regionalMarketCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createRegionalMarket';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createRegionalMarket' : undefined },
        });
      },
    },
    marketCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createMarket';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createMarket' : undefined },
        });
      },
    },
    mallCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createMall';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createMall' : undefined },
        });
      },
    },
    storeTypeCreateModalOpened: {
      get() {
        return this.$route.query.action === 'createStoreType';
      },
      set(opened) {
        this.$router.replace({
          query: { ...this.$route.query, action: opened ? 'createStoreType' : undefined },
        });
      },
    },
  },
  methods: {
    onRetailerCreated(retailer) {
      this.retailers.push(retailer);
      this.regionForm.retailerId = retailer.id;
    },
    onAccountTypeCreated(accountType) {
      this.accountTypes.push(accountType);
      this.regionForm.accountTypeId = accountType.id;
    },
    onRetailLabRegionCreated(retailLabRegion) {
      this.retailLabRegions.push(retailLabRegion);
      this.regionForm.retailLabRegionId = retailLabRegion.id;
    },
    onNSDRegionCreated(nsdRegion) {
      this.nsdRegions.push(nsdRegion);
      this.regionForm.nsdRegionId = nsdRegion.id;
    },
    onRegionalMarketCreated(regionalMarket) {
      this.regionalMarkets.push(regionalMarket);
      this.regionForm.seniorRegionalSalesManagerMarketId = regionalMarket.id;
    },
    onMarketCreated(market) {
      this.cities.push(market);
      this.regionForm.cityId = market.id;
    },
    onMallCreated(mall) {
      this.availableMalls.push(mall);
      this.regionForm.mallId = mall.id;
    },
    onStoreTypeCreated(storeType) {
      this.storeTypes.push(storeType);
      this.regionForm.storeTypeId = storeType.id;
    },
    submit() {
      this.submitData();
    },
    async submitData() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const body = {
        ...this.regionForm,
        brandIds: this.regionForm.brandIds.map(({ value }) => value),
      };
      try {
        await updateRegion(jwt, this.$route.params.id, body);
        this.isLoading = false;
        this.snackBar = {
          type: 'success',
          message: `Door ${this.regionForm.storeName} has been updated`,
          status: true,
        };
      } catch (e) {
        this.error = e;
        this.snackBar = {
          type: 'error',
          message: e?.message,
          status: true,
        };
      } finally {
        setTimeout(() => {
          this.snackBar.status = false;
        }, 4000);
        this.isLoading = false;
      }
    },
    goStartLocation() {
      this.$router.push({ ...this.origin });
    },
    getNameTextUpperCase(item) {
      return item.name.toUpperCase();
    },
    getBrandName(item) {
      return item.id;
    },
  },
  beforeRouteEnter(_, { path, query }, next) {
    if (path && path !== '/') {
      return next((vm) => {
        Object.assign(vm, {
          origin: { path, query },
        });
      });
    }
    return next();
  },
  async mounted() {
    this.isLoading = true;
    const regionId = this.$route.params.id;
    const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
    try {
      const [
        regionResponse,
        retailersResponse,
        accountTypesResponse,
        nsdRegionsResponse,
        regionalMarketsResponse,
        storeTypesResponse,
        mallsResponse,
        retailLabRegions,
        citiesResponse,
        brandsResponse,
        doorsResponse,
      ] = await Promise.all([
        getRegion(jwt, regionId),
        getRetailers(jwt),
        getAccountTypes(jwt),
        getNSDRegions(jwt),
        getRegionalMarkets(jwt),
        getStoreTypes(jwt),
        getMalls(jwt),
        getRegions(jwt),
        getCities(jwt),
        getBrands(jwt),
        getDoors(jwt),
      ]);

      const { id, brandIds = [], ...orderData } = regionResponse.data || {};
      Object.assign(this.regionForm, orderData);
      this.retailers = retailersResponse.data;
      this.accountTypes = accountTypesResponse.data;
      this.nsdRegions = nsdRegionsResponse.data;
      this.regionalMarkets = regionalMarketsResponse.data;
      this.storeTypes = storeTypesResponse.data;
      this.availableMalls = mallsResponse.data;
      this.retailLabRegions = retailLabRegions.data;
      this.cities = citiesResponse.data;
      this.availableBrands = brandsResponse.data
        .map(({ name, id: brandId }) => ({ text: name, value: brandId }));
      this.regionForm.brandIds = brandIds?.length
        ? brandIds.map((orderBrandId) => {
          const brand = brandsResponse.data
            ?.find(({ id: brandId }) => brandId === orderBrandId);
          return { text: brand?.name, value: brand?.id };
        })
        : [];
      this.doors = doorsResponse.data;
    } catch (e) {
      this.error = e;
      this.snackBar = {
        type: 'error',
        message: e?.message,
        status: true,
      };
    } finally {
      setTimeout(() => {
        this.snackBar.status = false;
      }, 4000);
      this.isLoading = false;
    }
  },
};
</script>
