<template>
  <v-app>
    <div v-if="$auth.loading || !$auth.user" class="text-center">
      <LoadingSpinner />
    </div>
    <div v-else>
      <v-navigation-drawer app
       v-model="drawer"
       :mini-variant.sync="mini"
       permanent
       color="grey lighten-5"
       class="no-print">
      <!-- -->
        <AdminDrawer mini=mini @toggleDrawer=toggleDrawer />
      </v-navigation-drawer>
      <!-- Must have the app property -->
      <v-app-bar color="white" :elevation=5 permanent flat app class="no-print">
        <div class="flex">
          <div class="d-flex">
            <div width="100">
              <v-img src="/ICP-Logo.png" max-width="110" max-height="110" contain ></v-img>
            </div>
            <div class="ml-auto">
              <v-chip
                  class="ma-2"
                  color="blue-grey lighten-3"
                  outlined
                  label
                  >
                <span class="text-uppercase">{{$auth.user.UserType.role}}</span>
              </v-chip>
              <span class="font-weight-medium blue-grey--text text--darken-1">
                {{$auth.user.email}}
              </span>
              <v-btn
                class="mx-4"
                small
                fab
                outlined
                color="indigo"
                @click="logout"><v-icon>logout</v-icon></v-btn>
            </div>
          </div>
        </div>
      </v-app-bar>
      <v-main class="print">
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <div class="pa-sm-3 pa-md-8">
            <router-view></router-view>
          </div>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>
<style scoped>
  @media print {
    .print {
      padding: 0 !important;
    }
    .no-print {
      display: none;
    }
  }
</style>
<script>
import AdminDrawer from './views/Admin/components/AdminDrawer.vue';
import LoadingSpinner from './components/LoadingSpinner';

export default {
  components: {
    AdminDrawer,
    LoadingSpinner,
  },
  data() {
    return {
      drawer: true,
      mini: false,
    };
  },
  methods: {
    toggleDrawer() {
      this.mini = !this.mini;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>
