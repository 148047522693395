<template>
  <v-data-table
    :headers="headers"
    :items="entries"
    disable-pagination
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn @click.native="addNewRow" color="primary" plain x-small>New Entry</v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.date="props">
      <v-edit-dialog
        :return-value.sync="props.item.date"
        large
        persistent
        save-text="Save"
        @close="save"
      >
        <small v-if="Array.isArray(props.item.date) && props.item.date.length">
          {{ dateRangeText(props.item.date) }}
        </small>
        <small class="grey--text" v-else>Change Date</small>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            Update Date
          </div>
          <v-date-picker
            v-model="props.item.date"
            range
          ></v-date-picker>
          <v-text-field
            label="Date range"
            prepend-icon="mdi-calendar"
            :value="dateRangeText(props.item.date)"
            readonly
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.event="props">
      <v-edit-dialog
        :return-value.sync="props.item.event"
        large
        persistent
        save-text="Save"
        @close="save"
      >
        <span v-if="!!props.item.event">{{ props.item.event }}</span>
        <small class="grey--text" v-else>Update Event Desc.</small>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            Update Event Desc.
          </div>
          <v-text-field
            v-model="props.item.event"
            label="Edit"
            single-line
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.goal="props">
      <v-edit-dialog
        :return-value.sync="props.item.goal"
        large
        persistent
        save-text="Save"
        @close="save"
      >
        <span v-if="!!props.item.goal">{{ formatPrice(props.item.goal) }}</span>
        <small class="grey--text" v-else>Update Goal</small>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            Update Goal
          </div>
          <v-text-field
            type="number"
            v-model="props.item.goal"
            label="Edit"
            single-line
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.actual="props">
      <v-edit-dialog
        :return-value.sync="props.item.actual"
        large
        persistent
        save-text="Save"
        @close="save"
      >
        <span v-if="!!props.item.actual">{{ formatPrice(props.item.actual) }}</span>
        <small class="grey--text" v-else>Update Actual</small>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            Update Actual
          </div>
          <v-text-field
            type="number"
            v-model="props.item.actual"
            label="Edit"
            single-line
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.actions="props">
      <v-btn
        color="red darken-2"
        fab
        x-small
        outlined
        @click="removeRow(props.item.idx)"
        >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { formatPrice } from '~/consts';

export default {
  props: {
    eventDetails: Array,
  },
  data() {
    return {
      date: new Date(),
      headers: [
        { text: 'Date', value: 'date', sortable: false },
        { text: 'Event Desc.', value: 'event', sortable: false },
        { text: 'Goal', value: 'goal', sortable: false },
        { text: 'Actual', value: 'actual', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      entries: [],
    };
  },
  mounted() {
    if (this.eventDetails && this.eventDetails.length) {
      this.entries = this.eventDetails.map((obj, i) => ({ idx: i, ...obj }));
    }
  },
  methods: {
    formatPrice,
    dateRangeText(dates = []) {
      return Array.isArray(dates) && dates.length ? dates.join('~') : '';
    },
    addNewRow() {
      this.entries = [].concat(
        this.entries,
        [{
          idx: this.entries.length + 1,
          date: null,
          event: null,
          goal: null,
          actual: null,
        }],
      );
    },
    removeRow(idx) {
      const newEntries = this.entries.filter((v) => v.idx !== idx) || [];
      this.entries = newEntries;
    },
    save() {
      this.$emit('updateEvents', this.entries);
    },
  },
};
</script>
