var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":"date","hide-default-footer":"","loading":_vm.isLoading},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Email Notification Scheduler")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Date or Month","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mx-4",attrs:{"icon":"","elevation":"1","aria-label":"Create Date"},on:{"click":function($event){_vm.isCreating = true}}},[_c('v-icon',[_vm._v("mdi-bell-plus")])],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filter by date range","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"range":"","min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1)]},proxy:true},{key:"item.month",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.month.name))])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"light-blue darken-2"},on:{"click":function($event){_vm.selectedDateId = item.id}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":"","color":"red darken-2"},on:{"click":function($event){_vm.dateToDelete = item}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.checkEvent(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((new (Function.prototype.bind.apply( Date, [ null ].concat( _vm.getYearMonthDay(item.date)) ))).toDateString()))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format(item.time, "hh:mm aaaaa'm'")))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toDateString()))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toDateString()))])]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('v-text-field',{attrs:{"value":_vm.itemsPerPage,"label":"Items per page","type":"number","min":"1","max":"200"},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1}}})],1),_c('div'),_c('v-dialog',{attrs:{"max-width":"60%","persistent":_vm.isLoading},model:{value:(_vm.isDeleting),callback:function ($$v) {_vm.isDeleting=$$v},expression:"isDeleting"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this date?")]),_c('v-col',[_c('v-text-field',{attrs:{"placeholder":"Type in DELETE"},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"light-blue darken-2","text":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.dateToDelete = null}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-2","disabled":_vm.isLoading || _vm.confirmDelete !== 'DELETE',"text":""},on:{"click":_vm.confirmDeleteItem}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"750px","persistent":_vm.isLoading},model:{value:(_vm.isNotificationDialogOpened),callback:function ($$v) {_vm.isNotificationDialogOpened=$$v},expression:"isNotificationDialogOpened"}},[(_vm.isNotificationDialogOpened)?_c('date-form',{key:_vm.selectedDateId || 0,attrs:{"date":_vm.selectedDate},on:{"close":function($event){_vm.isNotificationDialogOpened = false},"created":function (date) { return _vm.schedules.push(Object.assign({}, date,
        {month: {
          name: _vm.availableMonths[date.month] ? _vm.availableMonths[date.month].value : '',
          value: date.month,
        }})); },"updated":_vm.updateDate}}):_vm._e()],1),_c('v-snackbar',{attrs:{"color":"error","centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.error.status = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.error.status),callback:function ($$v) {_vm.$set(_vm.error, "status", $$v)},expression:"error.status"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }