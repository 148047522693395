import axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};

export const getRegionCollateralAssignment = (
  token,
  { regionId },
) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.get(
    `${VUE_APP_API_URL}/api/v1/admin/region-collateral-assignments/${regionId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
      },
    },
  );

export const createCollateralAssignment = (
  token,
  { retailLabRegionId, regionId, propData },
) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.post(
    `${VUE_APP_API_URL}/api/v1/admin/collateral-assignments`,
    { retailLabRegionId, regionId, propData },
    {
      headers: {
        Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
      },
    },
  );

export const updateCollateralAssignment = (token, { id, propData }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.put(
    `${VUE_APP_API_URL}/api/v1/admin/collateral-assignments/${id}`,
    propData,
    {
      headers: {
        Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
      },
    },
  );

export const deleteCollateralAssignment = (token, { id }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.delete(`${VUE_APP_API_URL}/api/v1/admin/collateral-assignments/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
    },
  });
