<template>
  <div>
    <v-card
      :loading="isLoading"
      >
      <v-card-title>
        <v-breadcrumbs
          divider=">"
          :items="itemsBreadcrumbs">
          <template v-slot:item="{ item }">
            <router-link
              :to="item.to"
              class="text-decoration-none"
              :class="item.disabled ? 'text text--disabled' : ''">
              {{ item.text }}
            </router-link>
          </template>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-card-title>
      <v-form ref="form" v-model="orderFormValues.valid">
        <v-container>
          <v-row>
            <v-col md="6" cols="8" class="d-md-flex">
              <v-card-subtitle>
                Shipping On: &nbsp;
              </v-card-subtitle>
              <v-menu
                :close-on-content-click="false"
                v-model="datePicker"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="orderFormValues.shippingOn"
                    :rules="orderFormRules.shippingOnRules"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    required
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="orderFormValues.shippingOn"
                  @change="datePicker = false">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="orderFormValues.Inventories"
          sort-by="id"
          hide-default-footer>
          <template v-slot:top>
            <v-toolbar flat >
              <v-toolbar-title>Items</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="openModal = true"
                aria-label="Add Items">
                <v-icon left>mdi-plus</v-icon>Add Items
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.OrdersInventory.quantity`]="{ item }">
            <v-text-field
              class="input-quantity"
              type="number"
              v-model="item.OrdersInventory.quantity"
              required
            ></v-text-field>
          </template>
        </v-data-table>
        <v-row>
          <v-col>
            <v-card-subtitle>
              Comments: &nbsp;
            </v-card-subtitle>
            <v-textarea
              solo
              v-model="orderFormValues.comments"
              class="mx-4"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            @click="$emit('cancel', 'cancel')"
            tile
            color="error"
            class="mx-4">
            <v-icon left>
              mdi-close-circle
            </v-icon>
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveChanges"
            :disabled="isLoading || !orderFormValues.valid || !orderFormValues.Inventories.length"
            tile
            color="success"
            class="mx-4">
            <v-icon left>
              mdi-check-circle
            </v-icon>
            Add Order
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-dialog v-model="openModal" :persistent="isLoading">
      <v-card>
        <v-card-title class="headline">
          Add Items
        </v-card-title>
        <v-data-table
          :search="search"
          :headers="headersInventory"
          :items="inventory"
          :loading="isLoading">
          <template v-slot:top>
            <v-toolbar flat >
              <v-toolbar-title>Inventory</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search by Name or SKU"
                  single-line
                  hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`item.add`]="{ item }">
            <v-text-field
              class="input-quantity"
              type="number"
              v-model="formAddItem.quantity[`${item.id}`]"
              :rules="[(v) => v ? v > 0 : true]"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            :disabled="isLoading"
            @click="openModal = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isLoading"
            text
            @click="addItem">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  .input-quantity {
    max-width: 50px;
  }
</style>
<script>
import {
  getUsers,
} from 'API/user';

export default {
  name: 'OrderById',
  props: {
    prevOrderStore: Object,
    inventory: Array,
  },
  data: () => ({
    users: [],
    itemsBreadcrumbs: [],
    isLoading: true,
    orderFormValues: {
      valid: true,
      shippingOn: null,
      Inventories: [],
      comments: '',
    },
    orderFormRules: {
      shippingOnRules: [
        (v) => !!v || 'Field is required',
      ],
      InventoriesRules: [
        (v) => !!v,
      ],
    },
    datePicker: false,
    headers: [
      { text: 'Name', value: 'itemCodeDesc' },
      { text: 'SKU', value: 'itemCode' },
      { text: 'Quantity', value: 'OrdersInventory.quantity' },
    ],
    error: {
      status: false,
      message: '',
    },
    openModal: false,
    formAddItem: {
      quantity: {},
      items: [],
    },
    search: '',
    headersInventory: [
      { text: 'Brand', value: 'brand' },
      { text: 'UPC', value: 'upc' },
      { text: 'Name', value: 'itemCodeDesc' },
      { text: 'SKU', value: 'itemCode' },
      { text: 'Qty Available', value: 'qtyAvailable' },
      { text: 'Max Available', value: 'maxQuantity' },
      { text: 'Add/Quantity', value: 'add' },
    ],
    itemsPerPage: 100,
    page: 1,
    pageCount: 0,
    confirmSubmit: false,
  }),
  methods: {
    async getUsers(jwt) {
      const { data = [] } = await getUsers(jwt);
      return data;
    },
    saveChanges() {
      this.isLoading = true;
      this.orderFormValues.Inventories = this.orderFormValues.Inventories
        .filter((Inventory) => Inventory.OrdersInventory.quantity > 0);
      this.$emit('saveChanges', JSON.parse(JSON.stringify(this.orderFormValues)));
      this.isLoading = false;
      this.setOrderValues();
    },
    setOrderValues(values) {
      if (values) {
        this.orderFormValues = values;
      } else {
        this.$refs.form.reset();
        this.orderFormValues = {
          valid: true,
          shippingOn: null,
          Inventories: [],
          comments: '',
        };
      }
    },
    addItem() {
      this.isLoading = true;
      const idToAdd = Object.keys(this.formAddItem.quantity);
      this.formAddItem.items = this.inventory
        .filter((item) => idToAdd.includes(`${item.id}`))
        .filter((item) => +this.formAddItem.quantity[`${item.id}`] > 0)
        .map((item) => ({
          ...item,
          OrdersInventory: {
            quantity: +this.formAddItem.quantity[`${item.id}`],
            storeId: item.id,
          },
        }))
        .forEach((item) => {
          if (!this.orderFormValues.Inventories.find((inventory) => inventory.id === item.id)) {
            this.orderFormValues.Inventories.push(item);
          }
        });
      this.isLoading = false;
      this.openModal = false;
      this.formAddItem = {
        quantity: {},
        items: [],
      };
    },
  },
  watch: {
    prevOrderStore(newVal) {
      this.setOrderValues(newVal);
    },
  },
  async mounted() {
    try {
      this.itemsBreadcrumbs = [{
        text: 'Orders',
        to: '/orders',
        disabled: false,
      },
      {
        text: 'Create New Order',
        to: '/order/new',
        disabled: true,
      }];
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      [this.users] = await Promise.all([
        this.getUsers(jwt),
      ]);
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
