<template>
  <v-dialog max-width="80%" v-model="show">
    <v-card>
      <v-card-title>
        <span class="text-center">{{ itemText }}</span>
      </v-card-title>
      <v-data-table
          :search="search"
          :headers="headersInventory"
          :items="selectedEntity.inventory"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hide-default-footer
          disable-pagination
          fixed-header
          height="500px"
          >
        <template v-slot:top>
          <v-toolbar flat >
            <v-toolbar-title>Inventory</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
                ></v-divider>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search by Name or SKU"
                single-line
                hide-details
                ></v-text-field>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  small
                  v-on="on"
                  :color="selectedEntity.remainingCost >= 0 ? 'success' : 'red'"
                  text-color="white"
                >
                  {{ formatPrice(selectedEntity.remainingCost) }}
                </v-chip>
              </template>
              <span>Total Remaining Budget for {{ selectedEntity.name }}</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:[`header.add`]="{ header }">
          {{ header.text }}
        </template>
        <template v-slot:[`item.qtyAvailable`]="{ item }">
          {{ applyQtyRestrictions(item) }}
        </template>
        <template v-slot:[`item.lastTotalUnitCost`]="{ item }">
          {{ formatPrice(item.lastTotalUnitCost) }}
        </template>
        <template v-slot:[`item.add`]="{ item }">
          <v-text-field
              :ref="'qtyItem' + item.id"
              class="input-quantity"
              type="number"
              min="0"
              :max="applyQtyRestrictions(item)"
              :value="getQuantity(item)"
              :rules="[rules.multiple(item)]"
              :step="item.multiple"
              :error="!isQuantityAvailable(item)"
              @input="allocItem(item, $event, 'input')"
              @blur="allocItem(item, $event, 'blur')"
              @wheel="$event.target.blur()"
              placeholder="0"
              :disabled="quantityInputDisabled || canModifyQuantity(item)"
              :hint="getHint(item)"
              ></v-text-field>
        </template>
        <template v-slot:[`header.totalCost`]="{ header }">
          {{ header.text }} &nbsp;
          <v-chip small color="primary">
            {{ formatPrice(getTotalPrice(selectedInventory)) }}
          </v-chip>
        </template>
        <template v-slot:[`item.totalCost`]="{ item }">
          <v-chip small color="primary">
            {{ formatPrice(getItemTotalPrice(item)) }}
          </v-chip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          tex
          @click.stop="show=false"
          :disabled="!canClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from 'lodash.debounce';
import { formatPrice, getTotalPrice } from '~/consts';

export default {
  name: 'InventoryModal',
  props: {
    itemText: String,
    selectedEntity: null,
    selectedInventory: Array,
    currentMonthOrders: Array,
    brandId: Number,
    value: Boolean,
    usedInventoryQuantity: Array,
  },
  data: () => ({
    sortBy: 'itemCodeDesc',
    sortDesc: false,
    search: '',
    inventory: [],
    remainingCost: 0,
    headersInventory: [
      { text: 'Name', value: 'itemCodeDesc' },
      { text: 'SKU', value: 'itemCode' },
      { text: 'Qty Available for this Region', value: 'qtyAvailable' },
      { text: 'Cost', value: 'lastTotalUnitCost' },
      { text: 'Add/Modify Quantity', value: 'add', sortable: false },
      { text: 'Total Cost', value: 'totalCost', sortable: false },
    ],
    rules: {
      multiple: (item) => {
        if (item.multiple) {
          return (value) => (value % item.multiple === 0 || `The quantity must be a multiple of ${item.multiple}`);
        }
        return () => true;
      },
      /*
      max: (item, applyQtyRestrictions) => {
        const str = `Item exceeds max quantity (${applyQtyRestrictions(item)})`;
        return (value) => (value > applyQtyRestrictions(item) || str);
      },
      */
    },
    canClose: true,
    quantityInputDisabled: false,
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    getRemainingCost() {
      const totalRegionCost = this.selectedEntity?.collateral?.propData?.maxCostAlloc;
      return formatPrice(totalRegionCost);
    },
  },
  watch: {
    show(showValue) {
      if (!showValue) {
        this.quantityInputDisabled = false;
      }
    },
  },
  methods: {
    formatPrice,
    getTotalPrice,
    getQuantity(item) {
      const selectedItem = this.selectedInventory.find(({ id }) => id === item?.id);
      return selectedItem?.quantity ?? 0;
    },
    isQuantityAvailable(item) {
      const inputRef = this.$refs[`qtyItem${item.id}`];

      if (!inputRef) {
        return true;
      }

      const maxInventory = this.applyQtyRestrictions(item);
      const inputValue = parseInt(inputRef.$refs.input.value, 10);
      const quantityAvailable = inputValue <= maxInventory;

      return quantityAvailable;
    },
    getHint(item) {
      const quantity = !this.isQuantityAvailable(item) ? "Quantity isn't available" : '';
      const multiple = item.multiple ? `Multi pack of ${item.multiple}` : '';
      const hint = [quantity, multiple].filter(Boolean).join(', ');

      return hint;
    },
    getItemTotalPrice(item) {
      return item.lastTotalUnitCost * this.getQuantity(item);
    },
    canModifyQuantity({ qtyAvailable = 0 }) {
      const maxInventoryAlloc = this.selectedEntity
        ?.collateral
        ?.find(
          ({ propData: { brandId = 0 } = {} }) => brandId === this.brandId,
        )
        ?.propData
        ?.maxInventoryAlloc;
      const maxInv = Math.floor((parseFloat(maxInventoryAlloc) / 100) * qtyAvailable);
      return maxInv <= 0;
    },
    countOfOrderedInventory(id) {
      const count = this.usedInventoryQuantity?.find(
        ({ id: inventoryId }) => inventoryId === id,
      )?.qty || 0;
      return count;
    },
    allocItem: debounce(function allocItem(item, val, type) {
      if (this.canClose) {
        const value = type === 'blur'
          ? parseInt(val.target.value, 10)
          : parseInt(val, 10);

        if (item.multiple && value % item.multiple !== 0) {
          return;
        }

        this.quantityInputDisabled = true;
        const maxInventory = this.applyQtyRestrictions(item);

        if (value <= maxInventory) {
          this.$emit('updateInventoryQuantity', {
            InventoryId: item.id,
            quantity: value,
            ...item,
          }, () => {
            this.quantityInputDisabled = false;
          });
        } else {
          this.quantityInputDisabled = false;
        }
      }
    }, 1000),
    applyQtyRestrictions({ qtyAvailable = 0, multiple, id }) {
      const maxInventoryAlloc = this.selectedEntity
        ?.collateral
        ?.find(
          ({ propData: { brandId = 0 } = {} }) => brandId === this.brandId,
        )
        ?.propData
        ?.maxInventoryAlloc;
      const qtyForOrder = Math.floor(
        (parseFloat(maxInventoryAlloc) / 100) * qtyAvailable,
      );
      const qty = multiple && qtyForOrder <= multiple ? multiple : qtyForOrder;
      const quantityUsed = this.countOfOrderedInventory(id);
      return quantityUsed && quantityUsed >= qty
        ? 0
        : qty - quantityUsed;
    },
  },
};
</script>
