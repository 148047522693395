<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      sort-by="date"
      hide-default-footer
      class="elevation-1"
      :loading="isLoading"
      >
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Email Notification Scheduler</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Date or Month"
              single-line
              hide-details
          />
          <v-btn
            icon elevation="1"
            @click="isCreating = true"
            aria-label="Create Date"
            class="mx-4"
          >
            <v-icon>mdi-bell-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar flat >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Filter by date range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              :active-picker.sync="activePicker"
              range
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-toolbar>
      </template>
      <template v-slot:[`item.month`]="{ item }">
        <span>{{ item.month.name }}</span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn @click="selectedDateId = item.id"
          outlined
          small
          color="light-blue darken-2"
          >
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn @click="dateToDelete = item"
          outlined
          small
          color="red darken-2"
          >
          <v-icon left>mdi-delete</v-icon> Delete
        </v-btn>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-checkbox v-model="item.active" @click="checkEvent(item)"></v-checkbox>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ (new Date(...getYearMonthDay(item.date))).toDateString() }}</span>
      </template>
      <template v-slot:[`item.time`]="{ item }">
        <span>{{ format(item.time, "hh:mm aaaaa'm'") }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ new Date(item.createdAt).toDateString() }}</span>
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        <span>{{ new Date(item.updatedAt).toDateString() }}</span>
      </template>
    </v-data-table>
    <div class="mt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
          ></v-pagination>
      <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="1"
          max="200"
          @input="itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1"
          />
    </div>
    <div>
    </div>
    <v-dialog v-model="isDeleting" max-width="60%" :persistent="isLoading">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this date?</v-card-title>
        <v-col>
          <v-text-field
            placeholder="Type in DELETE"
            v-model="confirmDelete"
          />
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-2"
            text
            :disabled="isLoading"
            @click="dateToDelete = null"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-2"
            :disabled="isLoading || confirmDelete !== 'DELETE'"
            text
            @click="confirmDeleteItem">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isNotificationDialogOpened" max-width="750px" :persistent="isLoading">
      <date-form
        v-if="isNotificationDialogOpened"
        :key="selectedDateId || 0"
        :date="selectedDate"
        @close="isNotificationDialogOpened = false"
        @created="date => schedules.push({
          ...date,
          month: {
            name: availableMonths[date.month] ? availableMonths[date.month].value : '',
            value: date.month,
          },
        })"
        @updated="updateDate"
      />
    </v-dialog>
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import {
  deleteSchedule,
  getSchedule,
  updateDate,
} from 'API/emailNotificationSchedule';
import { format } from 'date-fns';
import { availableMonths } from '~/consts';
import DateForm from './DateForm.vue';

export default {
  name: 'EmailNotification',
  components: { DateForm },
  data: () => ({
    isLoading: true,
    schedules: [],
    search: '',
    headers: [
      { text: 'Date', align: 'start', value: 'date' },
      { text: 'Time', align: 'start', value: 'time' },
      {
        text: 'Collateral Month',
        align: 'start',
        value: 'month',
        sort: (a, b) => a.value - b.value,
      },
      {
        text: 'Collateral Year',
        align: 'start',
        value: 'year',
      },
      { text: 'Active', value: 'active' },
      { text: 'Edit', value: 'edit', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false },
    ],
    itemsPerPage: 50,
    page: 1,
    pageCount: 0,
    dateToDelete: null,
    error: {
      status: false,
      message: '',
    },
    confirmDelete: null,
    menu: false,
    activePicker: null,
    dateRange: null,
    availableMonths,
  }),
  computed: {
    selectedDate() {
      return this.schedules.find((date) => date.id === this.selectedDateId);
    },
    isCreating: {
      get() {
        return this.$route.query.action === 'create';
      },
      set(value) {
        if (value) {
          this.$router.replace({ query: { action: 'create' } });
        }
      },
    },
    selectedDateId: {
      get() {
        const { dateId } = this.$route.query;
        return dateId ? +dateId : null;
      },
      set(dateId) {
        if (dateId) {
          this.$router.replace({ query: { action: 'edit', dateId } });
        }
      },
    },
    isNotificationDialogOpened: {
      get() {
        return !!this.selectedDateId || this.isCreating;
      },
      set(value) {
        if (!value) {
          this.$router.replace({ query: {} });
        }
      },
    },
    isDeleting: {
      get() {
        return !!this.dateToDelete;
      },
      set(value) {
        if (!value) {
          this.dateToDelete = null;
        }
      },
    },
    dateRangeText() {
      return this.dateRange?.join(' ~ ');
    },
    schedulesFiltered() {
      return this.schedules.filter((schedule) => {
        if (this.dateRange) {
          const [start, end] = this.dateRange;
          const date = new Date(schedule.date);
          return date >= new Date(start) && date <= new Date(end);
        }
        return true;
      });
    },
    filteredItems() {
      return this.schedulesFiltered.filter((schedule) => {
        const { date, month: { name } } = schedule;
        const stringDate = new Date(...this.getYearMonthDay(date)).toDateString();
        const stringDateValidation = stringDate.toLowerCase().includes(this.search.toLowerCase());
        const nameValidation = name.toLowerCase().includes(this.search.toLowerCase());
        return stringDateValidation || nameValidation;
      });
    },
  },
  methods: {
    async getSchedule() {
      try {
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        const { data } = await getSchedule(jwt);
        this.schedules = data.map(({ month, ...rest }) => ({
          ...rest,
          month: {
            name: availableMonths[month] ? availableMonths[month].value : '',
            value: month,
          },
        }));
        this.isLoading = false;
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
      }
    },
    async checkEvent(item) {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const { active, id } = item;
      const body = { active };
      try {
        const { data } = await updateDate(jwt, id, body);
        Object.assign(item, {
          ...data,
          month: {
            name: availableMonths[data.month] ? availableMonths[data.month].value : '',
            value: data.month,
          },
        });
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
        Object.assign(item, { active: !active });
      } finally {
        this.isLoading = false;
      }
    },
    updateDate(dateData) {
      this.schedules.forEach((date) => {
        const { month } = dateData;
        if (date.id === dateData.id) {
          Object.assign(date,
            {
              ...dateData,
              month: {
                name: availableMonths[month] ? availableMonths[month].value : '',
                value: month,
              },
            });
        }
      });
    },
    async confirmDeleteItem() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        const res = await deleteSchedule(jwt, this.dateToDelete.id);
        if (res.statusText === 'OK') {
          this.schedules = this.schedules.filter((date) => date.id !== this.dateToDelete.id);
          this.dateToDelete = null;
        }
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
        this.dateToDelete = null;
        this.confirmDelete = '';
      }
    },
    format(time, formatString) {
      const date = new Date();
      date.setHours(+time.split(':')[0], +time.split(':')[1]);
      return format(date, formatString);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getYearMonthDay(date) {
      return [+date.substr(0, 4), date.substr(5, 2) - 1, +date.substr(8, 2)];
    },
  },
  async mounted() {
    await this.getSchedule();
  },
};
</script>
