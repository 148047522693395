<template>
  <div>
    <div v-if="entity.item.UserType">
      <v-chip class="mr-2">User {{ entity.item.id }}</v-chip>
      <span>{{ entity.item.fullName }}</span>
    </div>
    <div v-else>
      <v-chip class="mr-2">
        Store: {{ entity.item.storeNo }}
      </v-chip>
      <span>
        {{ entity.item.retailer.name }} - {{ entity.item.storeName }}
      </span>
      <span>
        - {{ entity.item.regionalMarket.name }} - {{ entity.item.nsdRegion.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: {},
  },
};
</script>
