<template>
  <div>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="inventoriesFiltered"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      :sort-by="['qtyAvailable']"
      :sort-desc="[true]"
      v-model="selectedItems"
      hide-default-footer
      class="elevation-1"
      :show-select="$auth.user.UserType.role === 'admin'"
      :loading="isLoading">
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Collateral Inventory</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Code, Desc, Product Line, UPC"
              single-line
              hide-details
              ></v-text-field>
          <v-btn
            @click="download"
            :disabled="!inventoriesFiltered || isLoading"
            tile
            color="primary"
            class="mx-4">
            <v-icon left>
              mdi-download
            </v-icon>
            Download
          </v-btn>
        </v-toolbar>
        <filters
          :isLoading="isLoading"
          :brands="brands"
          :inventoriesFiltered="inventoriesFiltered"
          :dillardId="dillardId"
          :hasInventoryFilter="hasInventory"
          :hasStoresFilter="hasStores"
          v-model="filter"
          @change-has-inventory="hasInventory = $event"
          @change-has-stores="hasStores = $event"
          v-if="$auth.user.UserType.role === 'admin'"
        />
        <v-btn
          v-if="selectedItems.length && $auth.user.UserType.role === 'admin'"
          @click="bulkUpdateModal = true"
          tile
          color="deep-purple"
          class="mx-4 white--text associate-stores-btn"
        >
          Associate Stores
        </v-btn>
      </template>
      <template v-slot:[`item.lastTotalUnitCost`]="{ item }">
        <span>{{ formatPrice(item.lastTotalUnitCost) }}</span>
      </template>
      <template v-slot:[`item.qtyAvailable`]="{ item }">
        <span>{{ qtyAvailableLabel(item) }}</span>
      </template>
      <template v-slot:[`item.Regions`]="{ item }">
          <div class="d-flex">
          <div class="d-inline">
            {{ item.Regions.length > 0
              ? item.Regions.length
              : item.orderableByAllStores
                ? 'All'
                : 0
            }}
          </div>
          <div class="d-inline mx-2" v-if="$auth.user.UserType.role === 'admin'">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="selectedItem = item"
                  icon
                  small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  >
                  <v-icon>mdi-store</v-icon>
                </v-btn>
              </template>
              <span>Associate Region</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.multiple`]="{ item }">
        <span v-if="manager">{{ item.multiple }}</span>
        <v-text-field
          v-else
          type="number"
          :value="item.multiple"
          label="Multiple"
          single-line
          :min="1"
          class="col-6"
          @change="changeMultiple(item, $event)"
        ></v-text-field>
      </template>
    </v-data-table>
    <div class="mt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
          ></v-pagination>
      <v-text-field
          :value="itemsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="200"
          @input="itemsPerPage = parseInt($event, 10) > 0 ? parseInt($event, 10) : 1"
          ></v-text-field>
    </div>
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-if="inventoriesFiltered.length" v-model="isAssociatingRegions"
      :persistent="isLoading">
      <associate-region-form
        v-if="selectedItem"
        :regionsSelected="selectedItem"
        @update="updateInventory"
        @cancel="selectedItem = null"
        :isLoadingFromInventory="isLoading"
      />
    </v-dialog>
    <v-dialog
      v-if="inventoriesFiltered.length"
      v-model="bulkUpdateModal"
      :persistent="isLoading"
      @click:outside="closeBulkUpdateModal"
    >
      <associate-bulk-region-form
        v-if="selectedItems.length"
        :inventoriesSelected="selectedItems"
        @update="bulkUpdateInventory"
        @cancel="closeBulkUpdateModal"
        :isLoadingFromInventory="isLoading"
      />
    </v-dialog>
  </div>
</template>

<script>
import { getInventoryList, updateItem } from 'API/inventory';
import { postInventoryRegion, getStoreMatrixRegions } from 'API/region';
import { format } from 'date-fns';
import { getBrands } from 'API/brands';
import { formatPrice, generateFile } from '~/consts';
import AssociateRegionForm from './AssociateRegionForm.vue';
import AssociateBulkRegionForm from './AssociateBulkRegionForm.vue';
import Filters from './Filters.vue';

export default {
  name: 'Inventory',
  components: {
    AssociateRegionForm,
    AssociateBulkRegionForm,
    Filters,
  },
  data() {
    const { inventoryId = null } = this.$route.query;
    return {
      search: '',
      selectedItemId: inventoryId,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      headers: [
        { text: 'Item Code', align: 'start', value: 'itemCode' },
        { text: 'Item Description', value: 'itemCodeDesc' },
        /* { text: 'Brand', value: 'brand' }, */
        { text: 'Product Line', value: 'productLine' },
        { text: 'UPC', value: 'upc' },
        { text: 'Total Qty. Available', value: 'qtyAvailable' },
        { text: 'Last Total Unit Cost', value: 'lastTotalUnitCost', sortable: false },
        { text: 'Associate Regions', value: 'Regions', sortable: false },
        { text: 'Multiple', value: 'multiple', sortable: false },
      ],
      inventories: [],
      selectedItem: null,
      selectedItems: [],
      isLoading: true,
      formItem: {
        valid: true,
        maxQuantity: null,
        maxQuantityRules: [
          (v) => !!v && /^\d+$/.test(v),
        ],
      },
      error: {
        status: false,
        message: '',
      },
      manager: this.$auth?.user?.UserType?.role === 'manager',
      regions: [],
      filter: null,
      brands: [],
      hasInventory: false,
      hasStores: false,
      dillardId: 66,
      bulkUpdateModal: false,
    };
  },
  computed: {
    isAssociatingRegions: {
      get() {
        if (this.selectedItem) {
          const { inventoryId = null } = this.$route.query;
          if (inventoryId && this.selectedItem.id !== +inventoryId) {
            this.$router.replace({ query: { action: 'associateRegion', inventoryId: this.selectedItem.id } });
          }
          return true;
        }
        return false;
      },
      set(value) {
        if (!value) {
          const { inventoryId = null } = this.$route.query;
          if (inventoryId) this.$router.replace({ query: { } });
          this.selectedItem = null;
        }
      },
    },
    isEditing: {
      get() {
        return !!this.selectedItemId;
      },
      set(value) {
        if (!value) {
          this.selectedItemId = null;
        }
      },
    },
    inventoriesFiltered() {
      return this.inventories
        .filter(({ qtyAvailable = 0, Regions = [], productLine = '' } = {}) => {
          if (this.hasInventory && !(qtyAvailable > 0)) return false;
          if (this.hasStores && !Regions.length) return false;
          if (!this.filter) return true;
          if (this.filter === this.dillardId) {
            if (!Regions.length) return false;
            return Regions.some(({ retailerId }) => retailerId === this.dillardId);
          }
          const brandSelected = this.brands.find((brand) => brand.id === this.filter);
          return brandSelected?.productLines.includes(productLine) || false;
        });
    },
  },
  async mounted() {
    try {
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims() || {};
      const [
        { data: inventories = [] },
        { data: regions = [] },
        { data: brands = [] },
      ] = await Promise.all([
        getInventoryList(jwt),
        getStoreMatrixRegions(jwt),
        getBrands(jwt),
      ]);
      this.inventories = inventories;
      this.regions = regions;
      this.brands = brands;
      if (this.selectedItemId) this.setSelectedItemById(this.selectedItemId);
      else this.isAssociatingRegions = false;
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    formatPrice,
    download() {
      const dataHeaders = this.headers.map(({ value = '' }) => value);
      const data = this.inventoriesFiltered.map((inventory) => dataHeaders.map((key) => {
        if (key === 'qtyAvailable') {
          return { [key]: this.qtyAvailableLabel(inventory) };
        }
        return { [key]: `${inventory[key]}` };
      }).reduce((a, b) => ({ ...a, ...b })));
      const xlIdentifier = format(new Date(), 'yyyy-MM-dd').toUpperCase();
      const fileName = `Collateral_Inventory_${xlIdentifier}.xlsx`;
      return generateFile({
        data,
        sheetName: 'Collateral Inventory',
        fileName,
      });
    },
    qtyAvailableLabel(item) {
      if (this?.$auth?.user?.UserType?.role === 'admin') {
        return item.qtyAvailable;
      }
      if (item.qtyAvailable <= 0) {
        return 'Not In Stock';
      }
      return item.qtyAvailable > 0 && item.qtyAvailable < 50
        ? 'Low Stock'
        : 'In Stock';
    },
    setSelectedItemById(id) {
      this.selectedItem = this.inventories.find((i) => i.id === +id);
    },
    async updateInventory(e, regions, orderableByAllStores) {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims() || {};
        const body = {
          inventoryId: this.selectedItem.id,
          orderableByAllStores,
          regions: regions.every((region) => !region.set) || orderableByAllStores
            ? []
            : regions.filter((region) => region.set).map((region) => region.id),
        };
        const { data: inventory } = await postInventoryRegion(jwt, body);
        this.inventories = this.inventories.map((i) => {
          if (i.id === inventory.id) return inventory;
          return i;
        });
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
        this.selectedItem = null;
        this.isAssociatingRegions = false;
      }
    },
    async bulkUpdateInventory(e, regions) {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims() || {};
        const promises = this.selectedItems.map(async (selectedItem) => {
          const body = {
            inventoryId: selectedItem.id,
            regions: regions.map(({ id }) => id),
          };
          const { data: inventory } = await postInventoryRegion(jwt, body);

          return inventory;
        });

        const inventories = await Promise.all(promises);
        this.inventories = this.inventories.map((inventory) => {
          const newInventory = inventories.find(({ id }) => id === inventory.id);

          return newInventory || inventory;
        });
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
        this.closeBulkUpdateModal();
        this.isAssociatingRegions = false;
      }
    },
    closeBulkUpdateModal() {
      this.selectedItems = [];
      this.bulkUpdateModal = false;
    },
    async changeMultiple(item, value) {
      try {
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims() || {};
        const { data = [] } = await updateItem(jwt, item.id, { multiple: parseInt(value, 10) });
        this.inventories = [].concat(
          data,
          ...this.inventories.filter((i) => i.id !== item.id),
        );
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.associate-stores-btn {
  position: absolute;
  top: 7rem;
  right: 3.75rem;
}
</style>
