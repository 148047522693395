<template>
  <div>
    <order-create v-if="order.id" :breadCrumbs="breadCrumbs" :order="order" />
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
</style>
<script>
import { getOrder } from 'API/order';
import OrderCreate from './OrderCreate.vue';

export default {
  name: 'OrderById',
  components: {
    OrderCreate,
  },
  data: () => ({
    order: {},
    breadCrumbs: [
    ],
    error: {
      status: false,
      message: '',
    },
  }),
  methods: {
    async getOrder(jwt) {
      const { data = [] } = await getOrder(jwt, this.orderId);
      return data;
    },
  },
  async mounted() {
    try {
      this.orderId = await this.$route.params.id;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      this.order = await this.getOrder(jwt, this.orderId);
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.breadCrumbs = [{
        text: 'Orders',
        to: '/orders',
        disabled: false,
      },
      {
        text: `Order ${this.orderId}`,
        to: `/orders/${this.orderId}`,
        disabled: true,
      }];
    }
  },
};
</script>
