export const downloadCSV = ({ elements, name }) => {
  const header = Object.keys(elements[0]);
  const replace = (key, value) => {
    if (typeof value === 'object') {
      const obj = JSON.stringify(value).replaceAll(',', '.');
      return obj === null ? '' : obj;
    }
    return value === null ? '' : value;
  };
  const csv = [
    header.join(','),
    ...elements.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replace)).join(',')),
  ].join('\r\n');
  // Download the file as CSV
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${name}-${new Date().toISOString()}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
