<template>
  <v-card>
    <v-card-title class="headline">
      Associate Inventory
    </v-card-title>
    <v-data-table
      :search="searchInventory"
      :headers="headersInventory"
      :items="inventorySelected"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Collateral Inventory</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="searchInventory"
            append-icon="mdi-magnify"
            label="Search by Code, Desc, Brand, Product Line, UPC"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.lastTotalUnitCost`]="{ item }">
        <span>{{ formatPrice(item.lastTotalUnitCost) }}</span>
      </template>
      <template v-slot:[`item.set`]="{ item }">
        <v-checkbox v-model="item.set" @click="checkEvent(item)"></v-checkbox>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="red darken-2"
        text
        :disabled="isLoading"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      <v-btn
        color="light-blue darken-2"
        :disabled="isLoading"
        text
        @click="associateInventory">
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { postStoreInventory, getStore } from 'API/store';
import { formatPrice } from '~/consts';

export default {
  name: 'AssociateInventoryForm',
  props: {
    storeId: {
      type: Number,
      default: null,
    },
    inventory: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    headersInventory: [
      { text: 'Item Code', align: 'start', value: 'itemCode' },
      { text: 'Item Description', value: 'itemCodeDesc' },
      { text: 'Brand', value: 'brand' },
      { text: 'Product Line', value: 'productLine' },
      { text: 'UPC', value: 'upc' },
      { text: 'Qty. Available', value: 'qtyAvailable' },
      { text: 'Cost', value: 'lastTotalUnitCost' },
      { text: 'Set', value: 'set', sortable: false },
    ],
    searchInventory: '',
    inventorySelected: [],
    store: null,
    toAdd: [],
    toRemove: [],
    error: {
      status: false,
      message: '',
    },
  }),
  methods: {
    formatPrice,
    checkEvent(item) {
      if (this.store.Inventories.includes(item.id) && !item.set) {
        this.toRemove.push(item.id);
      } else if (!this.store.Inventories.includes(item.id) && item.set) {
        this.toAdd.push(item.id);
      } else if (this.toRemove.includes(item.id) && item.set) {
        this.toRemove = this.toRemove.filter((id) => id !== item.id);
      } else if (this.toAdd.includes(item.id) && !item.set) {
        this.toAdd = this.toAdd.filter((id) => id !== item.id);
      }
    },
    async associateInventory() {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        await postStoreInventory(jwt, {
          storeId: this.store.id,
          toAdd: this.toAdd,
          toRemove: this.toRemove,
        });
        this.$emit('update');
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const { data } = await getStore(jwt, this.storeId);
      data.Inventories = data.Inventories.map((item) => item.id);
      this.store = data;
      this.inventorySelected = [...this.inventory].map((item) => {
        const newItem = item;
        newItem.set = this.store.Inventories.includes(item.id);
        return item;
      });
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
