<template>
  <v-progress-circular :size="100" :width="8" indeterminate color="primary">
  </v-progress-circular>
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>
