import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getSalesPlans = (token, params) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/salesPlans`, {
  params,
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const deleteSalesPlan = (token, planId) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/salesPlans/${planId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const updateSalesPlan = (token, planId, body) => axios.patch(`${VUE_APP_API_URL}/api/v1/admin/salesPlans/${planId}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const createSalesPlan = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/salesPlans`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
