<template>
  <v-card class="px-10 py-5">
    <v-card-title class="headline">
      {{ user ? 'Edit ' : 'Create ' }} user
    </v-card-title>

    <v-progress-circular
      v-if="isLoading"
      :size="60"
      :width="8"
      class="d-block mx-auto mt-16"
      indeterminate
      color="primary"
    />

    <v-form v-show="!isLoading" ref="form" v-model="formUser.valid">
      <v-col>
        <v-text-field
          v-model="formUser.fullName"
          :rules="formUser.fullNameRules"
          label="Full Name"
          required
        />

        <v-text-field
          v-model="formUser.email"
          :rules="formUser.emailRules"
          label="E-mail"
          required
        />

        <v-select
          v-model="formUser.role"
          :items="formUser.roles"
          item-text="role"
          item-value="typeId"
          :rules="[v => !!v || 'Title is required']"
          label="Title"
          required
        />

        <v-combobox
          v-model="formUser.type"
          :items="formUser.types"
          item-text="type"
          item-value="id"
          label="Type"
          chips
          deletable-chips
          hide-selected
        />

        <!--<v-combobox
          multiple
          v-model="formUser.regions"
          :items="availableRegions"
          item-text="storeName"
          label="Store"
          class="tag-input"
          chips
          deletable-chips
          hide-selected
          />-->

        <v-combobox
          multiple
          v-if="availableRetailLabRegions.length"
          v-model="formUser.retailLabRegions"
          :items="availableRetailLabRegions"
          item-text="name"
          label="Regions"
          class="tag-input"
          chips
          deletable-chips
          hide-selected
        />
      </v-col>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-2"
          text
          :disabled="isLoading"
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="light-blue darken-2"
          :disabled="isLoading"
          text
          @click="createOrUpdateUser"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { createUser, updateUser } from 'API/user';
import { saveUserRetailLabRegions } from 'API/user-retailLabRegions';
import { fetchInitialData, fetchUserInitialData } from '@/services/user-service';

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    customers: [],
    availableRegions: null,
    availableStores: null,
    availableSalesManagers: [],
    availableRetailLabRegions: [],
    availableUsers: [],
    initialUserStores: [],
    initialUserRegions: [],
    isLoading: false,
    searchRegion: '',
    searchStore: '',
    formUser: {
      valid: true,
      fullName: '',
      fullNameRules: [(v) => !!v || 'Full Name is required'],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      role: null,
      type: null,
      roles: [],
      types: [],
      regions: [],
      retailLabRegions: [],
      stores: [],
    },
    error: {
      status: false,
      message: '',
    },
  }),

  methods: {
    userSingleResponsibilityRule(userId, others) {
      if (!userId) return true;

      const errorMessage = 'Cannot assign same user multiple times.';

      return !others.includes(userId) || errorMessage;
    },

    getCustomerName(customerId) {
      const customer = this.customers.find((c) => c.id === customerId);
      return customer?.name || '';
    },

    getUserType(user) {
      return this.formUser.roles.find((role) => role.role === user.UserType.role);
    },

    getUserRole(userResponse) {
      return this.formUser.roles.find(
        (role) => role.typeId === userResponse.data.userTypeId,
      ).role;
    },

    close() {
      this.$emit('close');
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    async createUser(jwt, body) {
      const response = await createUser(jwt, body);
      await this.saveRetailLabRegions(jwt, response.data.id);
      this.$emit('created', {
        ...response.data,
        UserType: {
          role: this.getUserRole(response),
        },
      });
    },

    async updateUser(jwt, body) {
      const response = await updateUser(jwt, this.user.id, body);

      await this.saveRetailLabRegions(jwt, this.user.id);

      this.$emit('updated', {
        ...response.data,
        UserType: {
          role: this.getUserRole(response),
        },
      });
    },

    async saveRetailLabRegions(jwt, userId) {
      await saveUserRetailLabRegions(
        jwt,
        userId,
        this.formUser.retailLabRegions.map((region) => region.id),
      );
    },

    async createOrUpdateUser() {
      if (!this.$refs.form.validate()) return;

      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const body = {
        fullName: this.formUser.fullName,
        email: this.formUser.email,
        userTypeId: this.formUser.role,
        type: this.formUser.type?.id ?? null,
      };

      try {
        if (this.user) {
          await this.updateUser(jwt, body);
        } else {
          await this.createUser(jwt, body);
        }
        /*
        await saveUserRegions(jwt, userId, this.formUser.regions.map((region) => region.id));
        await saveUserStores(jwt, userId, this.formUser.stores.map((store) => store.id));
        await saveUserRetailLabRegions(jwt, userId,
          this.formUser.retailLabRegions.map((retailLabRegion) => retailLabRegion.id));
         */
        this.close();
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
        this.$emit('error', error);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    this.isLoading = true;
    const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();

    try {
      /**
       * Component initial state
       */
      const data = await fetchInitialData(jwt);

      this.formUser.roles = data.userRoles;
      this.formUser.types = data.userTypes;
      this.availableRegions = data.regions;
      this.availableStores = data.stores;
      this.availableRetailLabRegions = data.retailLabRegions;
      this.customers = data.customers;
      this.availableSalesManagers = data.salesManagers;
      this.availableUsers = data.users;

      /**
       * User-specific data
       */
      if (this.user) {
        const userData = await fetchUserInitialData(jwt, this.user);

        this.formUser.stores.push(...userData.stores);
        this.formUser.regions.push(...userData.regions);
        this.formUser.retailLabRegions.push(...userData.retailLabRegions);
        this.formUser.fullName = this.user.fullName;
        this.formUser.email = this.user.email;
        const userType = this.getUserType(this.user);
        this.formUser.role = userType.typeId;
        this.formUser.type = data.userTypes
          .find(({ id: typeId }) => this.user.type === typeId) || {};
      }
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
