import { get } from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getUserRoles = (token) => get(`${VUE_APP_API_URL}/api/v1/admin/user-types`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getUserTypes = (token) => get(`${VUE_APP_API_URL}/api/v1/admin/user-types-2`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
