import { getStores } from 'API/store';
import { getRegions } from 'API/region';
import { getRegions as getRetailLabRegions } from 'API/retailLabRegion';
import { getUserTypes, getUserRoles } from 'API/user-types';
import { getCustomers } from 'API/customer';
import { getUserStores /* saveUserStores */ } from 'API/user-stores';
import { getUserRegions /* saveUserRegions */ } from 'API/user-regions';
import { getUserRetailLabRegions } from 'API/user-retailLabRegions';
import { getSalesManagers } from 'API/sales-managers';
import { getUsers } from '../api/user';

export const fetchInitialData = async (jwt) => {
  const [
    userRolesRes,
    regionsRes,
    storesRes,
    retailLabRegionRes,
    customersRes,
    salesManagersRes,
    usersRes,
    userTypesRes,
  ] = await Promise.all([
    getUserRoles(jwt),
    getRegions(jwt),
    getStores(jwt),
    getRetailLabRegions(jwt),
    getCustomers(jwt),
    getSalesManagers(jwt),
    getUsers(jwt),
    getUserTypes(jwt),
  ]);

  return {
    userRoles: userRolesRes.data,
    regions: regionsRes.data,
    stores: storesRes.data,
    retailLabRegions: retailLabRegionRes.data,
    customers: customersRes.data,
    salesManagers: salesManagersRes.data,
    users: usersRes.data,
    userTypes: userTypesRes.data,
  };
};

export const fetchUserInitialData = async (jwt, user) => {
  const [userStoresRes, userRegionsRes, userRetailLabRegionsRes] = await Promise.all([
    getUserStores(jwt, user.id),
    getUserRegions(jwt, user.id),
    getUserRetailLabRegions(jwt, user.id),
  ]);

  return {
    stores: userStoresRes.data,
    regions: userRegionsRes.data,
    retailLabRegions: userRetailLabRegionsRes.data,
  };
};
