<template>
  <div>
    <v-row>
      <h4>Export Collateral Orders</h4>
      <hr/>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          sm="6"
          >
          <v-date-picker
              v-model="dates"
              landscape
              range
              ></v-date-picker>
          <v-text-field
              v-model="dateRangeText"
              label="Date range"
              prepend-icon="mdi-calendar"
              readonly
              ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="2"
          >
          <v-btn @click="exportOrders" :disabled="checkDisabled" outlined>Export</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
  </div>
</template>

<script>
import { getOrders } from 'API/exports';
import { getPoDepartmentNumbers } from 'API/poDepartmentNumbers';
import { getSchedule } from 'API/emailNotificationSchedule';
import { format } from 'date-fns';
import {
  generateFile,
  adminExportObj,
  formatPrice,
  availableMonths,
} from '~/consts';
import LoadingSpinner from '~/components/LoadingSpinner';

export default {
  name: 'Exports',
  components: {
    LoadingSpinner,
  },
  data: () => ({
    dates: [],
    isLoading: false,
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    checkDisabled() {
      return this.dates.length !== 2 || this.isLoading;
    },
  },
  methods: {
    getPoDepartmentKey({ customerNumber, divisionNumber }) {
      return `customer:${customerNumber};division:${divisionNumber}`;
    },
    async getPoDepartmentsMap(jwt) {
      const { data: poDepartmentNumbers } = await getPoDepartmentNumbers(jwt);

      return new Map(
        poDepartmentNumbers.map(
          ({
            customerNumber,
            divisionNumber,
            po,
            department,
          }) => [
            this.getPoDepartmentKey({ customerNumber, divisionNumber }),
            {
              PO: po,
              department,
            },
          ],
        ),
      );
    },
    getMonthString(date) {
      return date.toLocaleString('en-US', { month: 'long' });
    },
    async exportOrders() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        const [
          { data: { orders = [] } },
          poDepartments,
          { data: schedules },
        ] = await Promise.all([
          getOrders(jwt, {
            dateStart: this.dates[0],
            dateEnd: this.dates[1],
          }),
          this.getPoDepartmentsMap(jwt),
          getSchedule(jwt),
        ]);

        const data = [].concat(...orders.map((order) => {
          const primaryKey = order?.Region?.retailer?.name;
          const adminObjKey = order?.Region?.storeType?.name.includes('CA-FULL LINE')
            && primaryKey !== 'HARRY ROSEN'
            ? `${primaryKey}-C`
            : primaryKey;
          const {
            value: customerNumber = '',
            dn: divisionNumber = '',
          } = adminExportObj.find(({ key }) => key === adminObjKey) || {};
          const mainObj = {
            orderId: order?.id,
            brandId: order?.brandId,
            userEmail: order?.User?.email,
            userName: order?.User?.fullName,
            retailer: order?.Region?.retailer?.name,
            store: order?.Region?.storeName,
            shipToCode: order?.Region?.storeNo,
            storeType: order?.Region?.storeType?.name,
            comments: order?.comments,
            customerNumber,
            divisionNumber,
            ...poDepartments.get(
              this.getPoDepartmentKey({ customerNumber, divisionNumber }),
            ),
          };
          const collateralMonth = availableMonths
            ?.find(({ key }) => key === schedules?.[0]?.month + 1)
            ?.value
            ?.slice(0, 3)
            ?.toUpperCase();
          if (order?.Region?.retailer?.name === 'BERGDORF GOODMAN') {
            mainObj.PO = '10100577631';
          } else if (customerNumber === 'NORDCAN') {
            mainObj.PO = '25006212';
          } else if (customerNumber === 'HOLTREN') {
            mainObj.PO = `${collateralMonth}CLTRLHR`;
          } else if (customerNumber === 'COSBAR') {
            mainObj.PO = `${collateralMonth}CLTRLCOS`;
          } else if (customerNumber === 'VONMAUR') {
            mainObj.PO = `${collateralMonth}CLLTRLCOS`;
          } else if (customerNumber === 'HARRY') {
            mainObj.PO = `${collateralMonth}CLTRLHA`;
          } else if (customerNumber === 'DILLARD') {
            mainObj.PO = `${collateralMonth}CLLTRLDL`;
          }
          return [].concat([], ...order?.Inventories?.map((Inventory) => ({
            itemCode: Inventory?.itemCode,
            quantity: Inventory?.OrdersInventory?.quantity,
            skuDesc: Inventory?.itemCodeDesc,
            lastTotalUnitCost: formatPrice(Inventory?.lastTotalUnitCost),
            ...mainObj,
          })));
        }));
        const xlIdentifier = format(new Date(), 'yyyy-MM-dd').toUpperCase();
        const sheetName = `ICP_${format(new Date(), 'MMM').toUpperCase()}CLL`;
        const fileName = `ICP_Collateral_${xlIdentifier}.xlsx`;
        generateFile({ data, sheetName, fileName });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
      return true;
    },
  },
};
</script>
