import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};
export const getShipAddressTypes = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/shipAddressTypes`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const getShipAddressType = (token, shipAddressTypeId) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/shipAddressTypes/${shipAddressTypeId}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const createShipAddressType = (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/shipAddressTypes`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const updateShipAddressType = (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/shipAddressTypes/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
export const deleteShipAddressType = (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/shipAddressTypes/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});
