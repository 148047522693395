import * as axios from 'axios';

const { VUE_APP_API_URL } = process.env || {};

// export const getSchedule = (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/users`, {
//   headers: {
//     Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
//   },
// });
export const getSchedule = async (token) => axios.get(`${VUE_APP_API_URL}/api/v1/admin/schedule`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const deleteSchedule = async (token, id) => axios.delete(`${VUE_APP_API_URL}/api/v1/admin/schedule/${id}`, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const updateDate = async (token, id, body) => axios.put(`${VUE_APP_API_URL}/api/v1/admin/schedule/${id}`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const createDate = async (token, body) => axios.post(`${VUE_APP_API_URL}/api/v1/admin/schedule`, body, {
  headers: {
    Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
  },
});

export const tConvert = (time) => {
  let timeAmPm = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

  if (timeAmPm.length > 1) {
    timeAmPm = timeAmPm.slice(1);
    timeAmPm[5] = +timeAmPm[0] < 12 ? 'AM' : 'PM';
    timeAmPm[0] = +timeAmPm[0] % 12 || 12;
  }
  return timeAmPm.join('');
};
