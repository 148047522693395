var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.itemText))])]),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headersInventory,"items":_vm.selectedEntity.inventory,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"500px"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Inventory")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Name or SKU","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","color":_vm.selectedEntity.remainingCost >= 0 ? 'success' : 'red',"text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.selectedEntity.remainingCost))+" ")])]}}])},[_c('span',[_vm._v("Total Remaining Budget for "+_vm._s(_vm.selectedEntity.name))])])],1)]},proxy:true},{key:"header.add",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.qtyAvailable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.applyQtyRestrictions(item))+" ")]}},{key:"item.lastTotalUnitCost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.lastTotalUnitCost))+" ")]}},{key:"item.add",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{ref:'qtyItem' + item.id,staticClass:"input-quantity",attrs:{"type":"number","min":"0","max":_vm.applyQtyRestrictions(item),"value":_vm.getQuantity(item),"rules":[_vm.rules.multiple(item)],"step":item.multiple,"error":!_vm.isQuantityAvailable(item),"placeholder":"0","disabled":_vm.quantityInputDisabled || _vm.canModifyQuantity(item),"hint":_vm.getHint(item)},on:{"input":function($event){return _vm.allocItem(item, $event, 'input')},"blur":function($event){return _vm.allocItem(item, $event, 'blur')},"wheel":function($event){return $event.target.blur()}}})]}},{key:"header.totalCost",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+"   "),_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.getTotalPrice(_vm.selectedInventory)))+" ")])]}},{key:"item.totalCost",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.getItemTotalPrice(item)))+" ")])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","tex":"","disabled":!_vm.canClose},on:{"click":function($event){$event.stopPropagation();_vm.show=false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }