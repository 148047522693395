<template>
  <v-data-table
    height="90vh"
    fixed-header
    v-if="!isLoading && salesPlans"
    :headers="headers"
    :items="salesPlans"
    :sort-by="['fy', 'fs']"
    :sort-desc="[true, false]"
    disable-pagination
    hide-default-footer
    class="elevation-1"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        v-if="canEdit"
        icon
        x-small
        color="light-blue darken-2"
        @click="$emit('selectedItem', item.id)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.salesGoal`]="{ item }">
      <span v-if="item.salesGoal"><em>{{ formatPrice(item.salesGoal) }}</em></span>
      <span v-else><code>-</code></span>
    </template>
    <template v-slot:[`item.lySalesGoal`]="{ item }">
      <span v-if="item.lySalesGoal"><em>{{ formatPrice(item.lySalesGoal) }}</em></span>
      <span v-else><code>-</code></span>
    </template>
    <template v-slot:[`item.goalAdjustments`]="{ item }">
      <ul class="pa-0" v-for="(entry, index) in item.goalAdjustments" :key="index">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <strong>Week {{entry.week}}</strong>: ${{entry.goal}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ul>
    </template>
    <template v-slot:[`item.eventDetails`]="{ item }">
      <ul class="pa-0" v-for="(entry, index) in item.eventDetails" :key="index">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              {{entry.event}} - {{ formatPrice(entry.goal) }} / {{ formatPrice(entry.actual)}}<br/>
              <small>{{entry.date[0]}} ~ {{entry.date[1]}}</small>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ul>
    </template>
    <template v-slot:[`item.lyEvents`]="{ item }">
      <ul class="pa-0" v-for="(entry, index) in item.lyEvents" :key="index">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <strong>Week {{entry.week}}</strong>&nbsp;
              {{entry.event}} - {{ formatPrice(entry.goal) }} / {{ formatPrice(entry.actual)}}<br/>
              <small>{{entry.date[0]}} ~ {{entry.date[1]}}</small>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ul>
    </template>
    <template v-slot:[`item.roadmapAdjustments`]="{ item }">
      <ul class="pa-0" v-for="(entry, index) in item.roadmapAdjustments" :key="index">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <strong>Week {{entry.week}}</strong>: ${{entry.roadmap}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </ul>
    </template>
    <template v-slot:[`item.notes`]="{ item }">
      <ul class="pa-0" v-for="(note, index) in item.notes" :key="index">
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title>
              <strong>Week {{note.week}}</strong>:
            </v-list-item-title>
            <p>{{note.note}}</p>
          </v-list-item-content>
        </v-list-item>
      </ul>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    canEdit: Boolean,
    headers: Array,
    salesPlans: Array,
    itemToDelete: Number,
    formatPrice: Function,
  },
};
</script>
