<template>
  <div>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="stores"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @page-count="pageCount = $event"
      class="elevation-1"
      :loading="isLoading"
      >
      <template v-slot:top>
        <v-toolbar flat >
          <v-toolbar-title>Collateral Stores</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search by Name, City, Division, Customer No, Code and State"
              single-line
              hide-details
          ></v-text-field>
          <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
          <router-link to="/stores/new">
            <v-btn
                text
                small
                color="light-blue darken-2"
                class="text-decoration-underline">
              New Store
            </v-btn>
          </router-link>
          <v-divider
            class="mx-4"
            inset
            vertical></v-divider>
          <v-btn
            @click="download"
            :disabled="!stores || isLoading"
            tile
            color="primary"
            class="mx-4">
            <v-icon left>
              mdi-download
            </v-icon>
            Download CSV
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.arDivisionNo`]="{ item }">
        <router-link :to="'/stores/' + item.id">
          <v-btn
              text
              small
              color="light-blue darken-2"
              class="text-decoration-underline"
          >
            {{ item.arDivisionNo }}
          </v-btn>
        </router-link>
      </template>
      <template v-slot:[`item.customerId`]="{ item, header }">
        {{ printCustomer(item, header) }}
      </template>
      <template v-slot:[`item.shipToCityId`]="{ item }">
        {{ printCity(item) }}
      </template>
      <template v-slot:[`item.shipToStateId`]="{ item }">
        {{ printState(item) }}
      </template>
      <template v-slot:[`item.udfShipAddressTypeId`]="{ item }">
        {{ printShipAddressType(item) }}
      </template>
      <template v-slot:[`item.udfSlscrId`]="{ item }">
        {{ printSlscr(item) }}
      </template>
      <template v-slot:[`item.maxCostAlloc`]="{ item }">
        {{ formatPrice(item.maxCostAlloc) }}
      </template>
      <template v-slot:[`item.inventory`]="{ item }">
        <div class="d-flex">
          <div class="d-inline">{{ item.Inventories.length }}</div>
          <div class="d-inline mx-2">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="selectedItemId = item.id"
                  icon
                  small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  >
                  <v-icon>mdi-tag</v-icon>
                </v-btn>
              </template>
              <span>Associate Inventory</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn @click="storeToDelete = item"
          outlined
          small
          color="red darken-2"
        >
          <v-icon left>mdi-delete</v-icon> Delete
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="isDeleting" max-width="290" :persistent="isLoading">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this store?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue darken-2"
            text
            :disabled="isLoading"
            @click="() => { this.storeToDelete = null;}"
          >
            Cancel
          </v-btn>
          <v-btn
            color="red darken-2"
            :disabled="isLoading"
            text
            @click="deleteStore"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="stores.length" v-model="isAssociatingInventory" :persistent="isLoading">
      <associate-inventory-form
        v-if="selectedItemId"
        :store-id="selectedItemId"
        :inventory="inventory"
        @update="fetchStores"
        @cancel="selectedItemId = null"
      />
    </v-dialog>
    <v-snackbar
      v-model="error.status"
      color="error"
      centered>
      {{ error.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="error.status = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import {
  getStores,
  deleteStore,
} from 'API/store';
import {
  getInventoryList,
} from 'API/inventory';
import { getCustomers } from 'API/customer';
import { getCities } from 'API/city';
import { getStates } from 'API/state';
import { getShipAddressTypes } from 'API/ship-address-type';
import { getSlscrs } from 'API/slscr';
import { downloadCSV } from 'API/downloadCSV';
import AssociateInventoryForm from '../../components/Stores/AssociateInventoryForm.vue';
import { formatPrice } from '~/consts';

export default {
  name: 'Stores',
  components: {
    AssociateInventoryForm,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'AR Division No', value: 'arDivisionNo' },
      { text: 'Cust. No', value: 'customerId' },
      { text: 'Cust. Name ', value: 'customerId' },
      { text: 'Ship To Code ', value: 'shipToCode' },
      { text: 'Ship To Name', value: 'shipToName' },
      { text: 'Ship To City', value: 'shipToCityId' },
      { text: 'Ship To State', value: 'shipToStateId' },
      { text: 'UDF Ship Address Type', value: 'udfShipAddressTypeId' },
      { text: 'UDF SLSCR', value: 'udfSlscrId' },
      { text: 'Max. Cost Alloc/Month', value: 'maxCostAlloc' },
      { text: 'Inventory', value: 'inventory', sortable: false },
      { text: 'Delete', value: 'delete', sortable: false },
    ],
    stores: [],
    customers: [],
    cities: [],
    states: [],
    shipAddressTypes: [],
    slscrs: [],
    itemsPerPage: 50,
    page: 1,
    pageCount: 0,
    inventory: [],
    isLoading: true,
    error: {
      status: false,
      message: '',
    },
    storeToDelete: null,
  }),
  computed: {
    selectedItemId: {
      get() {
        const { action, storeId } = this.$route.query || {};
        if (action === 'associateInventory' && storeId) {
          return +storeId;
        }
        return null;
      },
      set(storeId) {
        if (storeId) {
          this.$router.replace({ query: { action: 'associateInventory', storeId } });
        } else {
          this.$router.replace({ query: {} });
        }
      },
    },
    isAssociatingInventory: {
      get() {
        return !!this.selectedItemId;
      },
      set(value) {
        if (!value) {
          this.selectedItemId = null;
          this.selectedStore = null;
        }
      },
    },
    isDeleting: {
      get() {
        return !!this.storeToDelete;
      },
      set(newValue) {
        if (!newValue) {
          this.storeToDelete = null;
        }
      },
    },
  },
  methods: {
    formatPrice,
    printCustomer(store, header) {
      const field = header.text.toLowerCase().includes('name') ? 'name' : 'no';
      const customer = this.customers.find((c) => c.id === store.customerId) || {};
      return customer[field] || '';
    },
    printCity(store) {
      return this.cities.find((city) => city.id === store.shipToCityId)?.name || '';
    },
    printState(store) {
      return this.states.find((state) => state.id === store.shipToStateId)?.name || '';
    },
    printShipAddressType(store) {
      return this.shipAddressTypes.find((shipAddressType) => shipAddressType.id === store.udfShipAddressTypeId)?.name || '';
    },
    printSlscr(store) {
      return this.slscrs.find((slscr) => slscr.id === store.udfSlscrId)?.name || '';
    },
    async deleteStore() {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      try {
        await deleteStore(jwt, this.storeToDelete.id);
        this.stores = this.stores.filter((store) => store.id !== this.storeToDelete.id);
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.storeToDelete = null;
        this.isLoading = false;
      }
    },
    async fetchStores() {
      try {
        this.isLoading = true;
        const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
        const { data = [] } = await getStores(jwt);
        this.stores = data;
      } catch (error) {
        this.error.status = true;
        this.error.message = error;
      } finally {
        this.isLoading = false;
        this.isAssociatingInventory = false;
        this.toAdd = [];
        this.toRemove = [];
      }
    },
    download() {
      downloadCSV({
        elements: this.stores,
        name: 'Stores',
      });
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const { __raw: jwt = '' } = await this.$auth.getIdTokenClaims();
      const [
        storesResponse,
        inventoryResponse,
        customersResponse,
        citiesResponse,
        statesResponse,
        shipAddressTypesResponse,
        slscrsResponse,
      ] = await Promise.all([
        getStores(jwt),
        getInventoryList(jwt),
        getCustomers(jwt),
        getCities(jwt),
        getStates(jwt),
        getShipAddressTypes(jwt),
        getSlscrs(jwt),
      ]);
      this.stores = storesResponse.data;
      this.inventory = inventoryResponse.data;
      this.customers = customersResponse.data;
      this.cities = citiesResponse.data;
      this.states = statesResponse.data;
      this.shipAddressTypes = shipAddressTypesResponse.data;
      this.slscrs = slscrsResponse.data;
    } catch (error) {
      this.error.status = true;
      this.error.message = error;
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
